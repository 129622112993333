/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import {
  AutoComplete,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  InputNumber,
  List,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Table,
} from 'antd';
import styles from './styles.module.scss';
import { formLayout } from 'src/pages/constants';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { AdviseRequestStatus, filterOption, PriorityLevel, disableDateBeforeIncludeToday } from 'src/common/constant';
import { DeleteFilled, DeleteOutlined } from '@ant-design/icons';
import axiosIns, { HOST } from 'src/helpers/request';
import { ModalUpload } from './ModalUpload';
import { ModalProcessHistory } from './ModalProcessHistory';
import { useGetAdmin } from 'src/hooks/useGetAdmins';
import { ADVICE_REQUEST_API, REQUEST_COMPLAINT_INFORMATON_API } from 'src/helpers/api';
import { useQuery } from '@tanstack/react-query';
import { Permission } from 'src/routes/constants';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import { SideBarRoute } from 'src/layouts/constants';

const { Option } = Select;
const { Panel } = Collapse;

type Props = {
  dsCheckList: any;
};
const AdviceManagement: React.FC<Props> = ({ dsCheckList }) => {
  const [stage1, setStage1] = useState<any>({});
  const [stage3, setStage3] = useState<any>({});
  const [stage4, setStage4] = useState<any>({});
  const [fileList1, setFileUpload1] = useState<any>([]);
  const [fileList3, setFileUpload3] = useState<any>([]);
  const [fileList4, setFileUpload4] = useState<any>([]);
  const [updatePermission1, setUpdatePermission1] = useState<any>(false);
  const [updatePermission3, setUpdatePermission3] = useState<any>(false);
  const [updatePermission4, setUpdatePermission4] = useState<any>(false);
  false;
  const [visibleModalImport, setVisibleModalImport] = useState<boolean>(false);
  const [visibleModalTimeHistoryImport, setVisibleModalTimeHistoryImport] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { currentUser } = useCurrentUser();

  const [form] = Form.useForm();
  const param = useParams();

  const { adminOptions } = useGetAdmin();

  const getListClassifyRequest = async () => {
    const res = await axiosIns.get(ADVICE_REQUEST_API.getDetail(param?.id));
    return res?.data?.data;
  };

  const isHasPermissionEdit = currentUser?.permission?.moduleEnforces?.[SideBarRoute.CONSULTANCY_REQUEST]?.includes(
    Permission.editDetail
  );

  //API cập nhật quản lý khiếu nại
  function onUpdateComplaintManagement(params: any) {
    return new Promise((resolve, reject) => {
      setLoading(true);
      axiosIns
        .patch(`${REQUEST_COMPLAINT_INFORMATON_API.UPDATE}/${params?.id}`, { ...params })
        .then((s) => {
          if (s?.data?.statusCode === 200 && s?.data?.data) {
            message.success('Cập nhật nhanh thành công');
            setTimeout(() => {
              // navigate(ConsultationsRoutes.complaintList);
              resolve(s?.data);
            }, 500);
          } else if (s?.data?.statusCode >= 400) {
            message.error(s?.data?.message);
            reject(s?.data);
          } else reject(s?.data);
        })
        .catch((e) => {
          if (e?.response?.data?.statusCode >= 400) {
            message.error(e?.response?.data?.message);
          } else {
            reject(e);
          }
        })
        .finally(() => {
          setLoading(false);
          refetch();
        });
    });
    // .then
  }

  const { data: dsChiTietYCKN, refetch } = useQuery(['advice-request-detail'], () => getListClassifyRequest(), {
    refetchOnWindowFocus: false,
  });

  const [state, _setState] = useState<any>({
    checkListStage3: [],
    checkListStage4: [],
    dsCauHoiSKNN: [],
    newState: {},
    dsNhomSelected: [],
    modalStageVisible: false,
    modalStageType: '',
    modalStageUpload: '',
    statusStage1: null,
  });

  const setState = (data = {}) => {
    _setState((state) => {
      return { ...state, ...data };
    });
  };

  const dsCheckListName = dsCheckList?.map((item) => {
    return {
      value: item?.contentForm,
    };
  });

  useEffect(() => {
    if (dsChiTietYCKN?.stageRequests) {
      let stage1: any = [];
      let stage3: any = [];
      let stage4: any = [];
      stage1 = dsChiTietYCKN?.stageRequests?.find(
        (item) => item?.stageName === 'Giai đoạn 1+2: Tiếp nhận - Phân loại và xác minh thông tin'
      );
      setStage1(stage1);
      stage3 = dsChiTietYCKN?.stageRequests?.find((item) => item?.stageName === 'Giai đoạn 3: Tư vấn - Giải đáp');
      setStage3(stage3);
      stage4 = dsChiTietYCKN?.stageRequests?.find((item) => item?.stageName === 'Giai đoạn 4: Theo dõi sau khi tư vấn');
      setStage4(stage4);
    }
  }, [dsChiTietYCKN]);

  useEffect(() => {
    const checkListStage3: any = [];
    const checkListStage4: any = [];
    form.setFieldsValue({
      managerStage1: stage1?.manager?.map((item) => {
        return item?.id;
      }),
      statusStage1: stage1?.status,
      createdDateStage1: moment(stage1?.createdAt)?.format('DD/MM/YYYY'),
      receptionTimeStage1: stage1?.receptionTime ? moment(stage1?.receptionTime)?.format('DD/MM/YYYY') : undefined,
      noteStage1: stage1?.note,
      internalNoteStage1: stage1?.internalNote,
      // giai doan 3
      managerStage3: stage3?.manager?.map((item) => {
        return item?.id;
      }),
      statusStage3: stage3?.status,
      receptionTimeStage3: stage3?.receptionTime ? moment(stage3?.receptionTime)?.format('DD/MM/YYYY') : undefined,
      priorityLevelStage3: stage3?.priorityLevel,
      processingTimeStage3: stage3?.processingTime ? moment(stage3?.processingTime) : undefined,
      remindStage3: stage3?.remind,
      // giai doan 4
      managerStage4: stage4?.manager?.map((item) => {
        return item?.id;
      }),
      statusStage4: stage4?.status,
      receptionTimeStage4: stage4?.receptionTime ? moment(stage4?.receptionTime)?.format('DD/MM/YYYY') : undefined,
      priorityLevelStage4: stage4?.priorityLevel,
      processingTimeStage4: stage4?.processingTime ? moment(stage4?.processingTime) : undefined,
      remindStage4: stage4?.remind,
    });
    if (stage1?.status === AdviseRequestStatus.DONE) {
      setUpdatePermission1(true);
    }
    if (stage3?.status === AdviseRequestStatus.DONE) {
      setUpdatePermission3(true);
    }
    if (stage4?.status === AdviseRequestStatus.DONE) {
      setUpdatePermission4(true);
    }
    setFileUpload1(stage1?.attachedFile);
    setFileUpload3(stage3?.attachedFile);
    setFileUpload4(stage4?.attachedFile);

    stage3?.checkList?.map((item) => {
      checkListStage3.push({
        key: 'key3' + Math.random(),
        content: item?.content,
        expirationAt: item?.expirationAt ? moment(item?.expirationAt) : undefined,
        note: item.note,
        isDone: item?.isDone,
      });
      // dsNhomSelected.push(item.id);
    });

    stage4?.checkList?.map((item) => {
      checkListStage4.push({
        key: 'key4' + Math.random(),
        content: item?.content,
        expirationAt: item?.expirationAt ? moment(item?.expirationAt) : undefined,
        note: item.note,
        isDone: item?.isDone,
      });
      // dsNhomSelected.push(item.id);
    });

    const newState = {
      checkListStage3,
      checkListStage4,
    };

    setState({
      ...newState,
      // isSubQuestion: dsChiTietYCKN?.hasSubQuestion === 1 ? true : false
    });
  }, [dsChiTietYCKN, form, stage1, stage3, stage4]);

  const handleAdd = (type: string) => () => {
    let newData = {};
    switch (type) {
      case 'checkListStage3':
        newData = {
          key: 'key3' + Math.random(),
          expirationAt: '',
          contentFormId: null,
          content: null,
          note: '',
          isDone: false,
        };
        setState({
          checkListStage3: [...state.checkListStage3, newData],
        });
        break;

      case 'checkListStage4':
        newData = {
          key: 'key4' + Math.random(),
          expirationAt: '',
          contentFormId: null,
          content: null,
          note: '',
          isDone: false,
        };
        setState({
          checkListStage4: [...state.checkListStage4, newData],
        });
        break;
      default:
        break;
    }
  };

  const updateDetail = (field, type, row) => (e) => {
    // eslint-disable-next-line no-prototype-builtins
    const value = e?.hasOwnProperty('target') ? e.target.value : e;
    row[type] = value;
    switch (field) {
      case 'stage3':
        switch (type) {
          case 'content':
            //set name
            row.content = value?.trim();
            row.contentFormId = dsCheckList?.find((item) => item?.contentForm === value?.trim())?.id;
            setState({
              checkListStage3: state.checkListStage3,
            });
            break;

          case 'expirationAt':
            //set note
            row.expirationAt = value;
            setState({
              checkListStage3: state.checkListStage3,
            });
            break;

          case 'isDone':
            //set note
            row.isDone = e.target.checked;
            setState({
              checkListStage3: state.checkListStage3,
            });
            break;

          case 'note':
            //set note
            row.note = value;
            setState({
              checkListStage3: state.checkListStage3,
            });
            break;

          default:
            break;
        }
        break;

      case 'stage4':
        switch (type) {
          case 'content':
            //set name
            row.content = value?.trim();
            row.contentFormId = dsCheckList?.find((item) => item?.contentForm === value?.trim())?.id;
            setState({
              checkListStage4: state.checkListStage4,
            });
            break;

          case 'expirationAt':
            //set note
            row.expirationAt = value;
            setState({
              checkListStage4: state.checkListStage4,
            });
            break;

          case 'isDone':
            //set note
            row.isDone = e.target.checked;
            setState({
              checkListStage4: state.checkListStage4,
              // contentName: contentName
            });
            break;

          case 'note':
            //set note
            row.note = value;
            setState({
              checkListStage4: state.checkListStage4,
              // contentName: contentName
            });
            break;

          default:
            break;
        }
        break;
      default:
        break;
    }
  };

  const onImport = (type: string) => () => {
    switch (type) {
      case 'stage1':
        setState({
          modalStageUpload: 'stage1',
        });
        break;
      case 'stage3':
        setState({
          modalStageUpload: 'stage3',
        });
        break;
      case 'stage4':
        setState({
          modalStageUpload: 'stage4',
        });
        break;
      default:
        break;
    }
    setVisibleModalImport(true);
  };

  const onCancelImport = () => {
    setVisibleModalImport(false);
  };

  const onImportHistoryTime = (type: string) => () => {
    switch (type) {
      case 'stage3':
        setState({
          // modalStageVisible: true,
          modalStageType: 'stage3',
        });
        break;

      case 'stage4':
        setState({
          // modalStageVisible: true,
          modalStageType: 'stage4',
        });
        break;
      default:
        break;
    }
    setVisibleModalTimeHistoryImport(true);
  };

  const onCancelImportHistoryTime = () => {
    setVisibleModalTimeHistoryImport(false);
  };

  const onUpload = (type: string) => (values: any) => {
    switch (type) {
      case 'stage1':
        setFileUpload1([...fileList1, ...values]);
        break;
      case 'stage3':
        setFileUpload3([...fileList3, ...values]);
        break;
      case 'stage4':
        setFileUpload4([...fileList4, ...values]);
        break;
      default:
        break;
    }
  };

  const handleRemove = (type: string, id: any) => {
    switch (type) {
      case 'stage1':
        setFileUpload1((fileList1) => {
          return fileList1.filter((fileList1) => fileList1.id !== id);
        });
        break;
      case 'stage3':
        setFileUpload3((fileList3) => {
          return fileList3.filter((fileList3) => fileList3.id !== id);
        });
        break;
      case 'stage4':
        setFileUpload4((fileList4) => {
          return fileList4.filter((fileList4) => fileList4.id !== id);
        });
        break;
      default:
        break;
    }
  };

  const handleDeleteTable = (type: string, row) => () => {
    Modal.confirm({
      title: 'Xác nhận',
      content: `Bạn muốn bỏ nội dung này?`,
      okText: 'Đồng ý',
      cancelText: 'Huỷ bỏ',
      onOk: () => {
        switch (type) {
          case 'stage3':
            const newState: any = {};
            const checkListStage3 = state.checkListStage3;
            const index = checkListStage3.findIndex((item: any) => item.key === row.key);
            if (index !== -1) {
              checkListStage3.splice(index, 1);
              newState.checkListStage3 = [...checkListStage3];
            }
            setState(newState);
            break;

          case 'stage4':
            const newState4: any = {};
            const checkListStage4 = state.checkListStage4;
            const index4 = checkListStage4.findIndex((item: any) => item.key === row.key);
            if (index4 !== -1) {
              checkListStage4.splice(index4, 1);
              newState4.checkListStage4 = [...checkListStage4];
            }
            setState(newState4);
            break;

          case 'stage5':
            const newState5: any = {};
            const checkListStage5 = state.checkListStage5;
            const index5 = checkListStage5.findIndex((item: any) => item.key === row.key);
            if (index5 !== -1) {
              checkListStage5.splice(index5, 1);
              newState5.checkListStage5 = [...checkListStage5];
            }
            setState(newState5);
            break;

          case 'stage6':
            const newState6: any = {};
            const checkListStage6 = state.checkListStage6;
            const index6 = checkListStage6.findIndex((item: any) => item.key === row.key);
            if (index6 !== -1) {
              checkListStage6.splice(index6, 1);
              newState6.checkListStage6 = [...checkListStage6];
            }
            setState(newState6);
            break;

          default:
            break;
        }
      },
    });
  };

  const onSubmit = (type: string) => (e) => {
    let input = {};
    switch (type) {
      case 'stage1':
        input = {
          id: stage1?.id,
          manager: form.getFieldValue('managerStage1'),
          status: form.getFieldValue('statusStage1'),
          attachedFile: fileList1?.map((item: any) => {
            return item?.id;
          }),
          note: form.getFieldValue('noteStage1'),
          internalNote: form.getFieldValue('internalNoteStage1'),
        };
        onUpdateComplaintManagement(input);
        break;
      case 'stage3':
        input = {
          id: stage3?.id,
          manager: form.getFieldValue('managerStage3'),
          status: form.getFieldValue('statusStage3'),
          priorityLevel: form.getFieldValue('priorityLevelStage3'),
          processingTime: moment(form.getFieldValue('processingTimeStage3')).format('DD/MM/YYYY HH:mm:ss'),
          remind: form.getFieldValue('remindStage3'),
          note: form.getFieldValue('noteStage3'),
          internalNote: form.getFieldValue('internalNoteStage3'),
          attachedFile: fileList3?.map((item: any) => {
            return item?.id;
          }),
          checkList: state.checkListStage3?.map((item: any) => {
            return {
              content: item?.content?.trim(),
              contentFormId: item?.contentFormId,
              note: item?.note,
              isDone: item?.isDone,
              expirationAt: item?.expirationAt && moment(item?.expirationAt)?.format('DD/MM/YYYY'),
            };
          }),
        };
        onUpdateComplaintManagement(input);
        break;
      case 'stage4':
        input = {
          id: stage4?.id,
          manager: form.getFieldValue('managerStage4'),
          status: form.getFieldValue('statusStage4'),
          priorityLevel: form.getFieldValue('priorityLevelStage4'),
          processingTime: moment(form.getFieldValue('processingTimeStage4')).format('DD/MM/YYYY HH:mm:ss'),
          remind: form.getFieldValue('remindStage4'),
          note: form.getFieldValue('noteStage4'),
          internalNote: form.getFieldValue('internalNoteStage4'),
          attachedFile: fileList4?.map((item: any) => {
            return item?.id;
          }),
          checkList: state.checkListStage4?.map((item: any) => {
            return {
              content: item?.content?.trim(),
              contentFormId: item?.contentFormId,
              note: item?.note,
              isDone: item?.isDone,
              expirationAt: item?.expirationAt && moment(item?.expirationAt)?.format('DD/MM/YYYY'),
            };
          }),
        };
        onUpdateComplaintManagement(input);
        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.formWrapper}>
      <Spin spinning={loading}>
        <Form {...formLayout} form={form} labelAlign="left" layout="vertical">
          <div style={{ paddingTop: '20px' }}>
            <Collapse defaultActiveKey={['1']}>
              <Panel
                header={
                  <p>
                    <strong>Giai đoạn 1 + 2: Tiếp nhận, Phân loại và xác minh thông tin</strong>
                    <Button
                      type="primary"
                      style={{ float: 'right' }}
                      disabled={!isHasPermissionEdit}
                      onClick={onSubmit('stage1')}
                    >
                      Lưu
                    </Button>
                  </p>
                }
                key="1"
              >
                <Card>
                  <Row>
                    <Col span={8}>
                      <Form.Item name="managerStage1" labelCol={{ span: 24 }} label="Người phụ trách">
                        <Select
                          disabled={true}
                          showSearch
                          filterOption={filterOption}
                          mode="multiple"
                          placeholder={'Chọn người phụ trách'}
                        >
                          {adminOptions?.map((item: any, index) => {
                            return (
                              <Option value={item.id} key={index}>
                                {item.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name="statusStage1" labelCol={{ span: 24 }} label="Trạng thái">
                        <Select
                          disabled={updatePermission1}
                          showSearch
                          filterOption={filterOption}
                          placeholder="Chọn trạng thái"
                          // onChange={() => { setState({ statusStage1 : value})}}
                        >
                          <Option value={AdviseRequestStatus.UNFULFILLED}>Chưa thực hiện</Option>
                          <Option value={AdviseRequestStatus.PROGRESSING}>Đang thực hiện</Option>
                          <Option value={AdviseRequestStatus.DONE}>Đã hoàn thành </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name="createdDateStage1" label="Ngày tạo">
                        <Input disabled={true} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name="receptionTimeStage1" labelCol={{ span: 24 }} label="Thời gian tiếp nhận">
                        <Input disabled={true} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <p>
                    {' '}
                    Tệp đính kèm
                    <div>
                      <Button
                        onClick={onImport('stage1')}
                        disabled={updatePermission1}
                        // disabled={disableForm() || !user[SideBarRoute.NOTIFICATION]?.includes('edit_detail')}
                      >
                        Tải file
                      </Button>
                      <ModalUpload
                        visibleModalImport={visibleModalImport}
                        onCancelImport={onCancelImport}
                        onUpload={onUpload('stage1')}
                        modalStageUpload={state.modalStageUpload}
                      />
                      <List>
                        {dsChiTietYCKN?.chatConversation?.chatMessage?.map((item) => {
                          return item?.fileNotDownload?.map((subItem, key) => {
                            return (
                              <List.Item
                                key={`chat statge 1 ${key}`}
                                style={{ width: '90%', display: 'flex', justifyContent: 'space-between' }}
                              >
                                <a href={`${HOST}${subItem?.filePath}`} download={subItem?.fileOriginName}>
                                  {subItem?.fileOriginName}
                                </a>
                              </List.Item>
                            );
                          });
                        })}
                      </List>
                      <List>
                        {fileList1?.map((item, key) => {
                          return (
                            <List.Item
                              key={`File statge 1 ${key}`}
                              style={{ width: '90%', display: 'flex', justifyContent: 'space-between' }}
                            >
                              <a href={`${HOST}${item.filePath}`} download={item?.fileOriginName}>
                                {item.fileOriginName}
                              </a>{' '}
                              <Button
                                onClick={() => handleRemove('stage1', item?.id)}
                                style={{ padding: '0', borderColor: '#ffffff' }}
                              >
                                <DeleteFilled />
                              </Button>
                            </List.Item>
                          );
                        })}
                      </List>
                    </div>
                  </p>
                  <Row>
                    <Col span={24}>
                      <Row className="mb-3" align="middle">
                        <Col span={3}>
                          <b>Ghi chú</b>
                        </Col>
                        <Col span={18}>
                          <Form.Item name="noteStage1" className="input-horizontal" rules={[{ max: 100000 }]}>
                            <Input.TextArea placeholder="Nhập mô tả hoặc thêm ghi chú" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row className="mb-3" align="middle">
                        <Col span={3}>
                          <b>Ghi chú nội bộ</b>
                        </Col>
                        <Col span={18}>
                          <Form.Item name="internalNoteStage1" className="input-horizontal">
                            <Input.TextArea disabled={updatePermission1} placeholder="Nhập mô tả hoặc thêm ghi chú" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Panel>
              <Panel
                header={
                  <p>
                    <strong>Giai đoạn 3: Tư vấn - Giải đáp</strong>
                    <Button
                      type="primary"
                      style={{ float: 'right' }}
                      disabled={!isHasPermissionEdit}
                      onClick={onSubmit('stage3')}
                    >
                      Lưu
                    </Button>
                  </p>
                }
                key="2"
              >
                <Card>
                  <Row>
                    <Col span={8}>
                      <Form.Item name="managerStage3" labelCol={{ span: 24 }} label="Người phụ trách">
                        <Select
                          disabled={true}
                          showSearch
                          filterOption={filterOption}
                          mode="multiple"
                          placeholder={'Chọn người phụ trách'}
                        >
                          {adminOptions?.map((item: any, index) => {
                            return (
                              <Option value={item.id} key={index}>
                                {item.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name="statusStage3" labelCol={{ span: 24 }} label="Trạng thái">
                        <Select
                          disabled={updatePermission3}
                          showSearch
                          filterOption={filterOption}
                          placeholder="Chọn trạng thái"
                        >
                          <Option value={AdviseRequestStatus.UNFULFILLED}>Chưa thực hiện</Option>
                          <Option value={AdviseRequestStatus.PROGRESSING}>Đang thực hiện</Option>
                          <Option value={AdviseRequestStatus.DONE}>Đã hoàn thành </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name="receptionTimeStage3" labelCol={{ span: 24 }} label="Ngày tiếp nhận">
                        <Input disabled={true} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name="priorityLevelStage3" labelCol={{ span: 24 }} label="Mức độ ưu tiên">
                        <Select
                          disabled={updatePermission3}
                          showSearch
                          filterOption={filterOption}
                          placeholder="Chọn mức độ"
                        >
                          <Option value={PriorityLevel.EMERGENCY}>Khẩn cấp</Option>
                          <Option value={PriorityLevel.IMPORTANCE}>Quan trọng</Option>
                          <Option value={PriorityLevel.MEDIUM}>Trung bình</Option>
                          <Option value={PriorityLevel.LOW}>Thấp</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <div className="processingTime_History">
                        <Form.Item
                          className="processingTime"
                          name="processingTimeStage3"
                          labelCol={{ span: 24 }}
                          label="Thời hạn xử lý"
                        >
                          <DatePicker
                            disabled={updatePermission3}
                            style={{ width: '159%' }}
                            placeholder="Chọn thời gian"
                            format={'DD/MM/YYYY'}
                          />
                        </Form.Item>
                        <a onClick={onImportHistoryTime('stage3')}>Lịch sử thay đổi</a>
                      </div>
                    </Col>
                    <Col span={8}>
                      <Form.Item name="remindStage3" labelCol={{ span: 24 }} label="Nhắc nhở trước khi hết hạn">
                        <InputNumber disabled={updatePermission3} min={0} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <p>
                    Tệp đính kèm
                    <div>
                      <Button onClick={onImport('stage3')} disabled={updatePermission3}>
                        Tải file
                      </Button>
                      <ModalUpload
                        visibleModalImport={visibleModalImport}
                        onCancelImport={onCancelImport}
                        onUpload={onUpload('stage3')}
                        modalStageUpload={state.modalStageUpload}
                      />
                      <List>
                        {fileList3?.map((item, key) => {
                          return (
                            <List.Item
                              key={`fileList 3 ${key}`}
                              style={{ width: '90%', display: 'flex', justifyContent: 'space-between' }}
                            >
                              <a href={`${HOST}${item.filePath}`} download={item?.fileOriginName}>
                                {item.fileOriginName}
                              </a>{' '}
                              <Button
                                onClick={() => handleRemove('stage3', item?.id)}
                                style={{ padding: '0', borderColor: '#ffffff' }}
                              >
                                <DeleteFilled />
                              </Button>
                            </List.Item>
                          );
                        })}
                      </List>
                    </div>
                  </p>
                  <p>
                    <strong>Danh sách kiểm tra</strong>{' '}
                    <Button disabled={updatePermission3} onClick={handleAdd('checkListStage3')}>
                      Thêm
                    </Button>
                  </p>
                  <Table
                    style={{ paddingTop: 20, paddingBottom: 20 }}
                    pagination={false}
                    locale={{ emptyText: 'Không có kết quả' }}
                    dataSource={state.checkListStage3}
                    columns={[
                      {
                        title: (
                          <div className="custome-header">
                            <div className="title-box">STT</div>
                          </div>
                        ),
                        width: 30,
                        key: 'col00',
                        align: 'center',
                        dataIndex: 'key',
                        render: (value, row, index) => index + 1,
                      },
                      {
                        title: (
                          <div className="custome-header">
                            <div className="title-box">Nội dung</div>
                          </div>
                        ),
                        width: 250,
                        key: 'col1',
                        align: 'center',
                        dataIndex: 'content',
                        render: (value, row: any) => {
                          return (
                            <AutoComplete
                              disabled={updatePermission3}
                              options={dsCheckListName}
                              onChange={updateDetail('stage3', 'content', row)}
                              placeholder="Nhập nội dung"
                              filterOption={(inputValue, option: any) =>
                                option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                              }
                            />
                          );
                        },
                      },
                      {
                        title: (
                          <div className="custome-header">
                            <div className="title-box">Ngày hết hạn</div>
                          </div>
                        ),
                        width: 100,
                        key: 'col02',
                        align: 'center',
                        dataIndex: 'expirationAt',
                        render: (value, row) => {
                          return (
                            <DatePicker
                              disabled={updatePermission3}
                              disabledDate={disableDateBeforeIncludeToday}
                              placeholder="Chọn thời gian"
                              onChange={updateDetail('stage3', 'expirationAt', row)}
                              value={value}
                              format={'DD/MM/YYYY'}
                            />
                          );
                        },
                      },
                      {
                        title: (
                          <div className="custome-header">
                            <div className="title-box">Đã hoàn thành ?</div>
                          </div>
                        ),
                        width: 100,
                        key: 'col02',
                        align: 'center',
                        dataIndex: 'isDone',
                        render: (value, row) => {
                          return (
                            <Checkbox
                              disabled={updatePermission3}
                              onChange={updateDetail('stage3', 'isDone', row)}
                              checked={value}
                            />
                          );
                        },
                      },
                      {
                        title: (
                          <div className="custome-header">
                            <div className="title-box">Ghi chú</div>
                          </div>
                        ),
                        width: 100,
                        key: 'col02',
                        align: 'center',
                        dataIndex: 'note',
                        render: (value, row) => {
                          return (
                            <Input
                              disabled={updatePermission3}
                              onChange={updateDetail('stage3', 'note', row)}
                              value={value}
                            />
                          );
                        },
                      },
                      {
                        title: (
                          <div className="custome-header">
                            <div className="title-box">Xóa</div>
                          </div>
                        ),
                        width: 30,
                        key: 'col03',
                        align: 'center',
                        render: (value, row) => {
                          return (
                            <>
                              <Button
                                disabled={updatePermission3}
                                icon={<DeleteOutlined style={{ fontSize: '18px' }} />}
                                onClick={handleDeleteTable('stage3', row)}
                                type="text"
                                title="Xóa"
                              ></Button>
                            </>
                          );
                        },
                      },
                    ]}
                  />
                  <Row>
                    <Col span={24}>
                      <Row className="mb-3" align="middle">
                        <Col span={3}>
                          <b>Ghi chú</b>
                        </Col>
                        <Col span={18}>
                          <Form.Item name="noteStage3" className="input-horizontal" rules={[{ max: 100000 }]}>
                            <Input.TextArea placeholder="Nhập mô tả hoặc thêm ghi chú" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row className="mb-3" align="middle">
                        <Col span={3}>
                          <b>Ghi chú nội bộ</b>
                        </Col>
                        <Col span={18}>
                          <Form.Item name="internalNoteStage3" className="input-horizontal">
                            <Input.TextArea placeholder="Nhập mô tả hoặc thêm ghi chú" disabled={updatePermission3} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Panel>
              <Panel
                header={
                  <p>
                    <strong>Giai đoạn 4: Theo dõi sau khi tư vấn</strong>
                    <Button
                      type="primary"
                      style={{ float: 'right' }}
                      disabled={!isHasPermissionEdit}
                      onClick={onSubmit('stage4')}
                    >
                      Lưu
                    </Button>
                  </p>
                }
                key="3"
              >
                <Card>
                  <Row>
                    <Col span={8}>
                      <Form.Item name="managerStage4" labelCol={{ span: 24 }} label="Người phụ trách">
                        <Select
                          disabled={true}
                          showSearch
                          filterOption={filterOption}
                          mode="multiple"
                          placeholder={'Chọn người phụ trách'}
                        >
                          {adminOptions?.map((item: any, index) => {
                            return (
                              <Option value={item.id} key={index}>
                                {item.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name="statusStage4" labelCol={{ span: 24 }} label="Trạng thái">
                        <Select
                          disabled={updatePermission4}
                          showSearch
                          filterOption={filterOption}
                          placeholder="Chọn trạng thái"
                        >
                          <Option value={AdviseRequestStatus.UNFULFILLED}>Chưa thực hiện</Option>
                          <Option value={AdviseRequestStatus.PROGRESSING}>Đang thực hiện</Option>
                          <Option value={AdviseRequestStatus.DONE}>Đã hoàn thành </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name="receptionTimeStage4" labelCol={{ span: 24 }} label="Ngày tiếp nhận">
                        <Input disabled={true} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name="priorityLevelStage4" labelCol={{ span: 24 }} label="Mức độ ưu tiên">
                        <Select
                          disabled={updatePermission4}
                          showSearch
                          filterOption={filterOption}
                          placeholder="Chọn mức độ"
                        >
                          <Option value={PriorityLevel.EMERGENCY}>Khẩn cấp</Option>
                          <Option value={PriorityLevel.IMPORTANCE}>Quan trọng</Option>
                          <Option value={PriorityLevel.MEDIUM}>Trung bình</Option>
                          <Option value={PriorityLevel.LOW}>Thấp</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <div className="processingTime_History">
                        <Form.Item
                          className="processingTime"
                          name="processingTimeStage4"
                          labelCol={{ span: 24 }}
                          label="Thời hạn xử lý"
                        >
                          <DatePicker
                            disabled={updatePermission4}
                            placeholder="Chọn thời gian"
                            style={{ width: '159%' }}
                            format={'DD/MM/YYYY'}
                          />
                        </Form.Item>
                        <a onClick={onImportHistoryTime('stage4')}>Lịch sử thay đổi</a>
                      </div>
                    </Col>
                    <Col span={8}>
                      <Form.Item name="remindStage4" labelCol={{ span: 24 }} label="Nhắc nhở trước khi hết hạn">
                        <InputNumber disabled={updatePermission4} min={0} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <p>
                    Tệp đính kèm
                    <div>
                      <Button onClick={onImport('stage4')} disabled={updatePermission4}>
                        Tải file
                      </Button>
                      <ModalUpload
                        visibleModalImport={visibleModalImport}
                        onCancelImport={onCancelImport}
                        onUpload={onUpload('stage4')}
                        modalStageUpload={state.modalStageUpload}
                      />
                      <List>
                        {fileList4?.map((item, key) => {
                          return (
                            <List.Item
                              key={`fileList 4 ${key} `}
                              style={{ width: '90%', display: 'flex', justifyContent: 'space-between' }}
                            >
                              <a href={`${HOST}${item.filePath}`} download={item?.fileOriginName}>
                                {item.fileOriginName}
                              </a>{' '}
                              <Button
                                onClick={() => handleRemove('stage4', item?.id)}
                                style={{ padding: '0', borderColor: '#ffffff' }}
                              >
                                <DeleteFilled />
                              </Button>
                            </List.Item>
                          );
                        })}
                      </List>
                    </div>
                  </p>
                  <p>
                    <strong>Danh sách kiểm tra</strong>{' '}
                    <Button disabled={updatePermission4} onClick={handleAdd('checkListStage4')}>
                      Thêm
                    </Button>
                  </p>
                  <Table
                    style={{ paddingTop: 20, paddingBottom: 20 }}
                    pagination={false}
                    locale={{ emptyText: 'Không có kết quả' }}
                    dataSource={state.checkListStage4}
                    columns={[
                      {
                        title: (
                          <div className="custome-header">
                            <div className="title-box">STT</div>
                          </div>
                        ),
                        width: 30,
                        key: 'col00',
                        align: 'center',
                        dataIndex: 'key',
                        render: (value, row, index) => index + 1,
                      },
                      {
                        title: (
                          <div className="custome-header">
                            <div className="title-box">Nội dung</div>
                          </div>
                        ),
                        width: 250,
                        key: 'col1',
                        align: 'center',
                        dataIndex: 'content',
                        render: (value, row: any) => {
                          return (
                            <AutoComplete
                              disabled={updatePermission4}
                              options={dsCheckListName}
                              onChange={updateDetail('stage4', 'content', row)}
                              placeholder="Nhập nội dung"
                              filterOption={(inputValue, option: any) =>
                                option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                              }
                            />
                          );
                        },
                      },
                      {
                        title: (
                          <div className="custome-header">
                            <div className="title-box">Ngày hết hạn</div>
                          </div>
                        ),
                        width: 100,
                        key: 'col02',
                        align: 'center',
                        dataIndex: 'expirationAt',
                        render: (value, row) => {
                          return (
                            <DatePicker
                              disabled={updatePermission4}
                              disabledDate={disableDateBeforeIncludeToday}
                              placeholder="Chọn thời gian"
                              onChange={updateDetail('stage4', 'expirationAt', row)}
                              value={value}
                              format={'DD/MM/YYYY'}
                            />
                          );
                        },
                      },
                      {
                        title: (
                          <div className="custome-header">
                            <div className="title-box">Đã hoàn thành ?</div>
                          </div>
                        ),
                        width: 100,
                        key: 'col02',
                        align: 'center',
                        dataIndex: 'isDone',
                        render: (value, row) => {
                          return (
                            <Checkbox
                              disabled={updatePermission4}
                              onChange={updateDetail('stage4', 'isDone', row)}
                              checked={value}
                            />
                          );
                        },
                      },
                      {
                        title: (
                          <div className="custome-header">
                            <div className="title-box">Ghi chú</div>
                          </div>
                        ),
                        width: 100,
                        key: 'col02',
                        align: 'center',
                        dataIndex: 'note',
                        render: (value, row) => {
                          return (
                            <Input
                              disabled={updatePermission4}
                              onChange={updateDetail('stage4', 'note', row)}
                              value={value}
                            />
                          );
                        },
                      },
                      {
                        title: (
                          <div className="custome-header">
                            <div className="title-box">Xóa</div>
                          </div>
                        ),
                        width: 30,
                        key: 'col03',
                        align: 'center',
                        render: (value, row) => {
                          return (
                            <>
                              <Button
                                disabled={updatePermission4}
                                icon={<DeleteOutlined style={{ fontSize: '18px' }} />}
                                onClick={handleDeleteTable('stage4', row)}
                                type="text"
                                title="Xóa"
                              ></Button>
                            </>
                          );
                        },
                      },
                    ]}
                  />
                  <Row>
                    <Col span={24}>
                      <Row className="mb-3" align="middle">
                        <Col span={3}>
                          <b>Ghi chú</b>
                        </Col>
                        <Col span={18}>
                          <Form.Item name="noteStage4" className="input-horizontal" rules={[{ max: 100000 }]}>
                            <Input.TextArea placeholder="Nhập mô tả hoặc thêm ghi chú" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row className="mb-3" align="middle">
                        <Col span={3}>
                          <b>Ghi chú nội bộ</b>
                        </Col>
                        <Col span={18}>
                          <Form.Item name="internalNoteStage4" className="input-horizontal">
                            <Input.TextArea placeholder="Nhập mô tả hoặc thêm ghi chú" disabled={updatePermission4} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Panel>
            </Collapse>
          </div>
        </Form>
      </Spin>
      <ModalProcessHistory
        visibleModalTimeHistoryImport={visibleModalTimeHistoryImport}
        onCancelImportHistoryTime={onCancelImportHistoryTime}
        dsChiTietYCKN={dsChiTietYCKN}
        type={state.modalStageType}
        stage3={stage3}
        stage4={stage4}
      />
    </div>
  );
};
export default AdviceManagement;
