import React from 'react';

import { Table } from 'antd';
import styles from './styles.module.scss';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import axiosIns, { HOST } from 'src/helpers/request';
import { ADVICE_REQUEST_API } from 'src/helpers/api';
import { useQuery } from '@tanstack/react-query';

const FileManagement: React.FC = () => {
  const param = useParams();

  const getFile = async () => {
    const res = await axiosIns.get(ADVICE_REQUEST_API.getFile(param?.id));
    return res?.data?.data;
  };
  const { data, isLoading } = useQuery(['advice-request-file'], () => getFile(), {
    refetchOnWindowFocus: false,
  });

  return (
    <div className={styles.formWrapper}>
      <Table
        pagination={false}
        locale={{ emptyText: 'Không có kết quả' }}
        dataSource={data}
        loading={isLoading}
        columns={[
          {
            title: (
              <div className="custome-header">
                <div className="title-box">STT</div>
              </div>
            ),
            width: 30,
            key: 'col00',
            align: 'center',
            dataIndex: 'key',
            render: (value, row, index) => index + 1,
          },
          {
            title: (
              <div className="custome-header">
                <div className="title-box">Tên tệp</div>
              </div>
            ),
            width: 250,
            key: 'col1',
            align: 'center',
            dataIndex: 'messageFile',
            render: (value: any, row: any) => {
              return value?.map((item: any, index: number) => {
                return (
                  <p key={index}>
                    <a href={`${HOST}${item?.filePath}`} download={item?.fileOriginName}>
                      {item?.fileOriginName}
                    </a>
                  </p>
                );
              });
            },
          },
          {
            title: (
              <div className="custome-header">
                <div className="title-box">Người gửi</div>
              </div>
            ),
            width: 100,
            key: 'col02',
            align: 'center',
            dataIndex: 'messageFile',
            render: (value, row: any) => {
              return value?.map((item: any, index: number) => {
                return <p key={`${index} 2`}>{item?.user?.name}</p>;
              });
            },
          },
          {
            title: (
              <div className="custome-header">
                <div className="title-box">Thời gian gửi</div>
              </div>
            ),
            width: 100,
            key: 'col02',
            align: 'center',
            dataIndex: 'createdAt',
            render: (value) => moment(value)?.format('DD/MM/YYYY HH:mm:ss'),
          },
        ]}
      />
    </div>
  );
};

export default FileManagement;
