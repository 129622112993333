import moment from 'moment';
export const EXCEL_FORMAT_FILE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const DOC_FORMAT_FILE = 'application/msword';
export const DOCX_FORMAT_FILE = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const FORMAT_DATE_TIME = 'DD/MM/YYYY';
export const FORMAT_DATE_HOUR_TIME = 'DD/MM/YYYY HH:mm';

export const combineUrlParams = (url = '', params = {}) => {
  const keys = Object.keys(params);
  const paramUrl = keys
    .filter((key) => ![undefined, null, ''].includes(params[key]))
    .map((key) => `${key}=${params[key]}`)
    .join('&');
  return `${url}?${paramUrl}`;
};

 export function urlify(text:string) {
   const withLineBreaks = text.replace(/\n/g, '<br>');
    var urlRegex = /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/g;
    return withLineBreaks.replace(urlRegex, function (url) {
      return '<a href="' + url + '">' + url + '</a>';
    });
  }

export enum positionType {
  WORKER = 'Công nhân sản xuất',
  LEADER = 'Tổ trưởng',
  CAPTAIN = 'Chuyền trưởng',
  FOREMAN = 'Quản đốc',
  TECHNICAL = 'Kỹ thuật viên',
  ENGINEER = 'Kỹ sư',
  OFFICE_STAFF = 'Nhân viên văn phòng',
  PROD_SUP_STAFF = 'Nhân viên hỗ trợ sản xuất',
  MANAGERS = 'Cán bộ quản lý',
  UNION_OFFICE = 'Cán bộ công đoàn',
  OTHERS = 'Khác',
  ALL = 'Tất cả',
}

export enum statusSurveyType {
  ALL = 0,
  DRAFT = 1,
  UPCOMING = 2,
  PROCESSING = 3,
  END = 4,
}

export enum statusSurveyCreateType {
  NON_DRAFT = 'Non-Draft',
  DRAFT = 'Draft',
}

export enum GenderOnly {
  MAN = 0,
  WOMAN = 1,
  BOTH,
  OTHER,
}

export const GENDER = {
  0: 'Nam',
  1: 'Nữ',
  3: 'Khác',
};

export enum QuestionType {
  CHECKBOX = 0,
  RADIOBUTTON = 1,
  INPUT,
  OTHER,
}

export const filterOption = (input: string, option: any) => {
  return option.props.children.toString().toLowerCase().indexOf(input.toLowerCase().trim()) >= 0;
};

export function forceDownload(blob, filename) {
  const a = document.createElement('a');
  a.download = filename;
  a.href = blob;
  document.body.appendChild(a);
  a.click();
  a.remove();
}

export const moduleType = [
  { name: 'Phát triển bản thân', id: 1 },
  { name: 'Câu hỏi thường gặp', id: 2 },
  { name: 'Người dùng App', id: 3 },
  { name: 'Tư vấn & khiếu nại', id: 4 },
  { name: 'Phản hồi ứng dụng', id: 5 },
];

export enum CategoryModule {
  POST_LIST = 1,
  COMMON_QUESTION,
  APP_USER,
  ADVISE_AND_COMPLAIN,
  APP_FEEDBACK
}

export const disableDateBefore = (current: any) => {
  // Can not select days before today and today
  return current < moment().subtract(1, 'day');
};

export const disableDateAfter = (current: any) => {
  // Can not select days before today and today
  return current > moment().endOf('day');
};

export const disableDateAfterIncludeToday = (current: any) => {
  // Can not select days before today and today
  return current > moment().startOf('day');
};

export const disableDateBeforeIncludeToday = (current: any) => {
  // Can not select days before today and today
  return current < moment().endOf('day');
};

export const formatPrice = (number: number, sperator?: string) => {
  return number && typeof number === 'number'
    ? number?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${sperator || ','}`)
    : 0;
};

export const featureType = [
  { name: 'Khảo sát nhanh', id: 'khao_sat_nhanh' },
  { name: 'Khảo sát SKNN', id: 'khao_sat_sknn' },
  { name: 'Khảo sát DKLV', id: 'khao_sat_dklv' },
  { name: 'Tạo yêu cầu tư vấn', id: 'tao_yeu_cau_tu_van' },
  { name: 'Tạo yêu cầu khiếu nại', id: 'tao_yeu_cau_khieu_nai' },
];

export const TypeQuestion = {
  YESNO: 1,
  POINTLADDER: 2,
};

export enum ClassifyQuestion {
  UNCOMPROMISSING = 1,
  REALLY_SERIOUS,
  NOT_SERIOUS,
}

export enum AnswerTypeOfSurvey {
  ALL = 0,
  SKNN,
  DKLV,
}

export enum AnswerStatus {
  INACTIVE = 0,
  ACTIVE,
}

export const INDUSTRY_OPTION = [
  {
    label: 'Dệt may',
    value: 1,
  },
  {
    label: 'Điện tử',
    value: 2,
  },
  {
    label: 'Khai khoáng',
    value: 3,
  },
  {
    label: 'Da giày',
    value: 4,
  },
  {
    label: 'Khác',
    value: 5,
  },
];
export enum TypeOfAnswerSKNN {
  TABLE = 1,
  TEXT,
  CHECKBOX,
  HARD_CODE,
}

export const PROVINCES = [
  {
    code: '89',
    name: 'An Giang',
    nameEn: 'An Giang',
    fullName: 'Tỉnh An Giang',
    fullNameEn: 'An Giang Province',
    codeName: 'an_giang',
  },
  {
    code: '24',
    name: 'Bắc Giang',
    nameEn: 'Bac Giang',
    fullName: 'Tỉnh Bắc Giang',
    fullNameEn: 'Bac Giang Province',
    codeName: 'bac_giang',
  },
  {
    code: '06',
    name: 'Bắc Kạn',
    nameEn: 'Bac Kan',
    fullName: 'Tỉnh Bắc Kạn',
    fullNameEn: 'Bac Kan Province',
    codeName: 'bac_kan',
  },
  {
    code: '95',
    name: 'Bạc Liêu',
    nameEn: 'Bac Lieu',
    fullName: 'Tỉnh Bạc Liêu',
    fullNameEn: 'Bac Lieu Province',
    codeName: 'bac_lieu',
  },
  {
    code: '27',
    name: 'Bắc Ninh',
    nameEn: 'Bac Ninh',
    fullName: 'Tỉnh Bắc Ninh',
    fullNameEn: 'Bac Ninh Province',
    codeName: 'bac_ninh',
  },
  {
    code: '77',
    name: 'Bà Rịa - Vũng Tàu',
    nameEn: 'Ba Ria - Vung Tau',
    fullName: 'Tỉnh Bà Rịa - Vũng Tàu',
    fullNameEn: 'Ba Ria - Vung Tau Province',
    codeName: 'ba_ria_vung_tau',
  },
  {
    code: '83',
    name: 'Bến Tre',
    nameEn: 'Ben Tre',
    fullName: 'Tỉnh Bến Tre',
    fullNameEn: 'Ben Tre Province',
    codeName: 'ben_tre',
  },
  {
    code: '52',
    name: 'Bình Định',
    nameEn: 'Binh Dinh',
    fullName: 'Tỉnh Bình Định',
    fullNameEn: 'Binh Dinh Province',
    codeName: 'binh_dinh',
  },
  {
    code: '74',
    name: 'Bình Dương',
    nameEn: 'Binh Duong',
    fullName: 'Tỉnh Bình Dương',
    fullNameEn: 'Binh Duong Province',
    codeName: 'binh_duong',
  },
  {
    code: '70',
    name: 'Bình Phước',
    nameEn: 'Binh Phuoc',
    fullName: 'Tỉnh Bình Phước',
    fullNameEn: 'Binh Phuoc Province',
    codeName: 'binh_phuoc',
  },
  {
    code: '60',
    name: 'Bình Thuận',
    nameEn: 'Binh Thuan',
    fullName: 'Tỉnh Bình Thuận',
    fullNameEn: 'Binh Thuan Province',
    codeName: 'binh_thuan',
  },
  {
    code: '96',
    name: 'Cà Mau',
    nameEn: 'Ca Mau',
    fullName: 'Tỉnh Cà Mau',
    fullNameEn: 'Ca Mau Province',
    codeName: 'ca_mau',
  },
  {
    code: '92',
    name: 'Cần Thơ',
    nameEn: 'Can Tho',
    fullName: 'Thành phố Cần Thơ',
    fullNameEn: 'Can Tho City',
    codeName: 'can_tho',
  },
  {
    code: '04',
    name: 'Cao Bằng',
    nameEn: 'Cao Bang',
    fullName: 'Tỉnh Cao Bằng',
    fullNameEn: 'Cao Bang Province',
    codeName: 'cao_bang',
  },
  {
    code: '66',
    name: 'Đắk Lắk',
    nameEn: 'Dak Lak',
    fullName: 'Tỉnh Đắk Lắk',
    fullNameEn: 'Dak Lak Province',
    codeName: 'dak_lak',
  },
  {
    code: '67',
    name: 'Đắk Nông',
    nameEn: 'Dak Nong',
    fullName: 'Tỉnh Đắk Nông',
    fullNameEn: 'Dak Nong Province',
    codeName: 'dak_nong',
  },
  {
    code: '48',
    name: 'Đà Nẵng',
    nameEn: 'Da Nang',
    fullName: 'Thành phố Đà Nẵng',
    fullNameEn: 'Da Nang City',
    codeName: 'da_nang',
  },
  {
    code: '11',
    name: 'Điện Biên',
    nameEn: 'Dien Bien',
    fullName: 'Tỉnh Điện Biên',
    fullNameEn: 'Dien Bien Province',
    codeName: 'dien_bien',
  },
  {
    code: '75',
    name: 'Đồng Nai',
    nameEn: 'Dong Nai',
    fullName: 'Tỉnh Đồng Nai',
    fullNameEn: 'Dong Nai Province',
    codeName: 'dong_nai',
  },
  {
    code: '87',
    name: 'Đồng Tháp',
    nameEn: 'Dong Thap',
    fullName: 'Tỉnh Đồng Tháp',
    fullNameEn: 'Dong Thap Province',
    codeName: 'dong_thap',
  },
  {
    code: '64',
    name: 'Gia Lai',
    nameEn: 'Gia Lai',
    fullName: 'Tỉnh Gia Lai',
    fullNameEn: 'Gia Lai Province',
    codeName: 'gia_lai',
  },
  {
    code: '02',
    name: 'Hà Giang',
    nameEn: 'Ha Giang',
    fullName: 'Tỉnh Hà Giang',
    fullNameEn: 'Ha Giang Province',
    codeName: 'ha_giang',
  },
  {
    code: '30',
    name: 'Hải Dương',
    nameEn: 'Hai Duong',
    fullName: 'Tỉnh Hải Dương',
    fullNameEn: 'Hai Duong Province',
    codeName: 'hai_duong',
  },
  {
    code: '31',
    name: 'Hải Phòng',
    nameEn: 'Hai Phong',
    fullName: 'Thành phố Hải Phòng',
    fullNameEn: 'Hai Phong City',
    codeName: 'hai_phong',
  },
  {
    code: '35',
    name: 'Hà Nam',
    nameEn: 'Ha Nam',
    fullName: 'Tỉnh Hà Nam',
    fullNameEn: 'Ha Nam Province',
    codeName: 'ha_nam',
  },
  {
    code: '01',
    name: 'Hà Nội',
    nameEn: 'Ha Noi',
    fullName: 'Thành phố Hà Nội',
    fullNameEn: 'Ha Noi City',
    codeName: 'ha_noi',
  },
  {
    code: '42',
    name: 'Hà Tĩnh',
    nameEn: 'Ha Tinh',
    fullName: 'Tỉnh Hà Tĩnh',
    fullNameEn: 'Ha Tinh Province',
    codeName: 'ha_tinh',
  },
  {
    code: '93',
    name: 'Hậu Giang',
    nameEn: 'Hau Giang',
    fullName: 'Tỉnh Hậu Giang',
    fullNameEn: 'Hau Giang Province',
    codeName: 'hau_giang',
  },
  {
    code: '17',
    name: 'Hòa Bình',
    nameEn: 'Hoa Binh',
    fullName: 'Tỉnh Hòa Bình',
    fullNameEn: 'Hoa Binh Province',
    codeName: 'hoa_binh',
  },
  {
    code: '79',
    name: 'Hồ Chí Minh',
    nameEn: 'Ho Chi Minh',
    fullName: 'Thành phố Hồ Chí Minh',
    fullNameEn: 'Ho Chi Minh City',
    codeName: 'ho_chi_minh',
  },
  {
    code: '33',
    name: 'Hưng Yên',
    nameEn: 'Hung Yen',
    fullName: 'Tỉnh Hưng Yên',
    fullNameEn: 'Hung Yen Province',
    codeName: 'hung_yen',
  },
  {
    code: '56',
    name: 'Khánh Hòa',
    nameEn: 'Khanh Hoa',
    fullName: 'Tỉnh Khánh Hòa',
    fullNameEn: 'Khanh Hoa Province',
    codeName: 'khanh_hoa',
  },
  {
    code: '91',
    name: 'Kiên Giang',
    nameEn: 'Kien Giang',
    fullName: 'Tỉnh Kiên Giang',
    fullNameEn: 'Kien Giang Province',
    codeName: 'kien_giang',
  },
  {
    code: '62',
    name: 'Kon Tum',
    nameEn: 'Kon Tum',
    fullName: 'Tỉnh Kon Tum',
    fullNameEn: 'Kon Tum Province',
    codeName: 'kon_tum',
  },
  {
    code: '12',
    name: 'Lai Châu',
    nameEn: 'Lai Chau',
    fullName: 'Tỉnh Lai Châu',
    fullNameEn: 'Lai Chau Province',
    codeName: 'lai_chau',
  },
  {
    code: '68',
    name: 'Lâm Đồng',
    nameEn: 'Lam Dong',
    fullName: 'Tỉnh Lâm Đồng',
    fullNameEn: 'Lam Dong Province',
    codeName: 'lam_dong',
  },
  {
    code: '20',
    name: 'Lạng Sơn',
    nameEn: 'Lang Son',
    fullName: 'Tỉnh Lạng Sơn',
    fullNameEn: 'Lang Son Province',
    codeName: 'lang_son',
  },
  {
    code: '10',
    name: 'Lào Cai',
    nameEn: 'Lao Cai',
    fullName: 'Tỉnh Lào Cai',
    fullNameEn: 'Lao Cai Province',
    codeName: 'lao_cai',
  },
  {
    code: '80',
    name: 'Long An',
    nameEn: 'Long An',
    fullName: 'Tỉnh Long An',
    fullNameEn: 'Long An Province',
    codeName: 'long_an',
  },
  {
    code: '36',
    name: 'Nam Định',
    nameEn: 'Nam Dinh',
    fullName: 'Tỉnh Nam Định',
    fullNameEn: 'Nam Dinh Province',
    codeName: 'nam_dinh',
  },
  {
    code: '40',
    name: 'Nghệ An',
    nameEn: 'Nghe An',
    fullName: 'Tỉnh Nghệ An',
    fullNameEn: 'Nghe An Province',
    codeName: 'nghe_an',
  },
  {
    code: '37',
    name: 'Ninh Bình',
    nameEn: 'Ninh Binh',
    fullName: 'Tỉnh Ninh Bình',
    fullNameEn: 'Ninh Binh Province',
    codeName: 'ninh_binh',
  },
  {
    code: '58',
    name: 'Ninh Thuận',
    nameEn: 'Ninh Thuan',
    fullName: 'Tỉnh Ninh Thuận',
    fullNameEn: 'Ninh Thuan Province',
    codeName: 'ninh_thuan',
  },
  {
    code: '25',
    name: 'Phú Thọ',
    nameEn: 'Phu Tho',
    fullName: 'Tỉnh Phú Thọ',
    fullNameEn: 'Phu Tho Province',
    codeName: 'phu_tho',
  },
  {
    code: '54',
    name: 'Phú Yên',
    nameEn: 'Phu Yen',
    fullName: 'Tỉnh Phú Yên',
    fullNameEn: 'Phu Yen Province',
    codeName: 'phu_yen',
  },
  {
    code: '44',
    name: 'Quảng Bình',
    nameEn: 'Quang Binh',
    fullName: 'Tỉnh Quảng Bình',
    fullNameEn: 'Quang Binh Province',
    codeName: 'quang_binh',
  },
  {
    code: '49',
    name: 'Quảng Nam',
    nameEn: 'Quang Nam',
    fullName: 'Tỉnh Quảng Nam',
    fullNameEn: 'Quang Nam Province',
    codeName: 'quang_nam',
  },
  {
    code: '51',
    name: 'Quảng Ngãi',
    nameEn: 'Quang Ngai',
    fullName: 'Tỉnh Quảng Ngãi',
    fullNameEn: 'Quang Ngai Province',
    codeName: 'quang_ngai',
  },
  {
    code: '22',
    name: 'Quảng Ninh',
    nameEn: 'Quang Ninh',
    fullName: 'Tỉnh Quảng Ninh',
    fullNameEn: 'Quang Ninh Province',
    codeName: 'quang_ninh',
  },
  {
    code: '45',
    name: 'Quảng Trị',
    nameEn: 'Quang Tri',
    fullName: 'Tỉnh Quảng Trị',
    fullNameEn: 'Quang Tri Province',
    codeName: 'quang_tri',
  },
  {
    code: '94',
    name: 'Sóc Trăng',
    nameEn: 'Soc Trang',
    fullName: 'Tỉnh Sóc Trăng',
    fullNameEn: 'Soc Trang Province',
    codeName: 'soc_trang',
  },
  {
    code: '14',
    name: 'Sơn La',
    nameEn: 'Son La',
    fullName: 'Tỉnh Sơn La',
    fullNameEn: 'Son La Province',
    codeName: 'son_la',
  },
  {
    code: '72',
    name: 'Tây Ninh',
    nameEn: 'Tay Ninh',
    fullName: 'Tỉnh Tây Ninh',
    fullNameEn: 'Tay Ninh Province',
    codeName: 'tay_ninh',
  },
  {
    code: '34',
    name: 'Thái Bình',
    nameEn: 'Thai Binh',
    fullName: 'Tỉnh Thái Bình',
    fullNameEn: 'Thai Binh Province',
    codeName: 'thai_binh',
  },
  {
    code: '19',
    name: 'Thái Nguyên',
    nameEn: 'Thai Nguyen',
    fullName: 'Tỉnh Thái Nguyên',
    fullNameEn: 'Thai Nguyen Province',
    codeName: 'thai_nguyen',
  },
  {
    code: '38',
    name: 'Thanh Hóa',
    nameEn: 'Thanh Hoa',
    fullName: 'Tỉnh Thanh Hóa',
    fullNameEn: 'Thanh Hoa Province',
    codeName: 'thanh_hoa',
  },
  {
    code: '46',
    name: 'Thừa Thiên Huế',
    nameEn: 'Thua Thien Hue',
    fullName: 'Tỉnh Thừa Thiên Huế',
    fullNameEn: 'Thua Thien Hue Province',
    codeName: 'thua_thien_hue',
  },
  {
    code: '82',
    name: 'Tiền Giang',
    nameEn: 'Tien Giang',
    fullName: 'Tỉnh Tiền Giang',
    fullNameEn: 'Tien Giang Province',
    codeName: 'tien_giang',
  },
  {
    code: '84',
    name: 'Trà Vinh',
    nameEn: 'Tra Vinh',
    fullName: 'Tỉnh Trà Vinh',
    fullNameEn: 'Tra Vinh Province',
    codeName: 'tra_vinh',
  },
  {
    code: '08',
    name: 'Tuyên Quang',
    nameEn: 'Tuyen Quang',
    fullName: 'Tỉnh Tuyên Quang',
    fullNameEn: 'Tuyen Quang Province',
    codeName: 'tuyen_quang',
  },
  {
    code: '86',
    name: 'Vĩnh Long',
    nameEn: 'Vinh Long',
    fullName: 'Tỉnh Vĩnh Long',
    fullNameEn: 'Vinh Long Province',
    codeName: 'vinh_long',
  },
  {
    code: '26',
    name: 'Vĩnh Phúc',
    nameEn: 'Vinh Phuc',
    fullName: 'Tỉnh Vĩnh Phúc',
    fullNameEn: 'Vinh Phuc Province',
    codeName: 'vinh_phuc',
  },
  {
    code: '15',
    name: 'Yên Bái',
    nameEn: 'Yen Bai',
    fullName: 'Tỉnh Yên Bái',
    fullNameEn: 'Yen Bai Province',
    codeName: 'yen_bai',
  },
];

export const PROVINCE_OPTIONS = PROVINCES?.map((item: any) => {
  return {
    label: item?.name,
    value: item?.code,
  };
});
// Loại khiếu nại
export enum AdviseTopicType {
  LABOR_LAW = 1, //Pháp luật lao động
  HEALTHY_OCCUPATIONAL, //sức khỏe nghề nghiệp
  FEEDBACK_APP, //phản hồi về ứng dụng
}

// trạng thái tcủa yêu cầu khiếu nại
export enum AdviseRequestStatus {
  WAITING_FOR_PROGRESSING = 1, // chờ xử lý
  PROGRESSING, // đang tiến hành
  PAUSING, // tạm dừng
  DONE, // hoàn thành
  NEW, // mới
  CONFIRMING, //đang xác nhận
  UNFULFILLED, // chưa thực hiện
  REJECT, //từ chối
  CLOSED,
}

// đã từng  trễ hạn
export enum AdviseRequestLateStatus {
  NOT_LATE = 0,
  LATE,
}

// Mức độ ưu tiên
export enum PriorityLevel {
  EMERGENCY = 1,
  IMPORTANCE,
  MEDIUM,
  LOW,
}

// Nguồn tạo
export enum AdviseRequests {
  APP_WECHECK = 1,
  FACEBOOK,
  ZALO,
  HOTLINE,
  DIRECT,
  LETTERS,
  OTHER,
}

export enum typeComlaint {
  INDIVIDUAL = 1, // CÁ NHÂN
  GROUP, // TẬP THỂ NGƯỜI LAO ĐỘNG
  UNKNOW, // KHÔNG BIẾT
}

// GIai đoạn xử lý khiếu nại
export enum StageAdviseNumber {
  STAGE1AND2 = 1,
  STAGE3 = 3,
  STAGE4,
  STAGE5,
  STAGE6,
}

// đánh giấu khiếu nại
export enum MarkingComplaint {
  COMPLAINT = 1, // KHIẾU NẠI
  REQUEST, // KIẾN NGHỊ
  REFLECT, // PHẢN ÁNH
  FEEDBACK, // GÓP Ý
  ADVISORY, // TƯ VẤN
  OTHER, // KHÁC
}

// vấn đề giới tính
export enum relatedToGenderComplaint {
  NO,
  YES,
  UNKNOW,
}

export enum RemoveExceptionType {
  ALL = 0,
  REMOVE_EXCEPTION,
  REMOVE_NORMAL,
}

export enum TypeFile {
  UNCATEGORIZED = 0,
  STAGE1AND2 = 1,
  STAGE3 = 3,
  STAGE4,
  STAGE5,
  STAGE6,
}

export const PROPERTY = [
  {
    label: 'Khẩn cấp',
    value: 1,
    color: '#EC808D',
  },
  {
    label: 'Quan trọng',
    value: 2,
    color: '#FACD91',
  },
  {
    label: 'Trung bình',
    value: 3,
    color: '#FFFF80',
  },
  {
    label: 'Thấp',
    value: 4,
    color: '#80FFFF',
  },
];

export enum FeedbackStatus {
  NEW = 1,
  WAITING_FOR_PROGRESSING,
  DONE,
  CLOSED,
}

export enum ChatEvent {
  SENDING_ANSWER = 1,
  MEET_EXPERTS,
  CALL_FOR_ADVISE,
  DOCTOR_APPOINTMENT,
}

export enum SurveyTargetUserEnum {
  SAMPLE_USER = 1, // Người dùng mẫu
  NORMAL_USER, // Người dùng thông thường
}