import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { Suspense, memo } from 'react';
import { Provider } from 'react-redux';
import { RootRouter } from 'src/routes/index';
import { SpinLoading } from './pages/loading';
import { store } from './stores/config-store';
import './styles/app.scss';

const queryClient = new QueryClient();
const App = memo(() => {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <Suspense fallback={<SpinLoading />}>
          <RootRouter />
        </Suspense>
      </Provider>
    </QueryClientProvider>
  );
});

export default App;
