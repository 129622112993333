export const formLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

export const formValidateMessages = {
  required: 'Thông tin bắt buộc',
  types: {
    email: 'Email không đúng định dạng!',
    number: 'Vui lòng nhập đúng định dạng số!',
  },
  number: {
    range: '${label} phải nằm trong khoảng ${min} và ${max}',
  },
  string: {
    min: '${name} phải ít nhất ${min} kí tự',
    max: 'Không được phép nhập nhiều hơn ${max} kí tự',
  },
  whitespace: 'Thông tin bắt buộc',
};
