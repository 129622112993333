import { io } from 'socket.io-client';
import { SOCKETURL } from 'src/helpers/request';

export let socketClient: any;

export function initSocket(token: string) {
  if (!socketClient) {
    socketClient = io(SOCKETURL, {
      extraHeaders: {
        authorization: `${token}`,
      },
    });
  }
}

// export function disconnect() {
//   if (socket) {
//     socket.disconnect();
//     socket.close();
//   }
//   socketClient = null;
// }
