import axiosIns from 'src/helpers/request';
import type { IMessage } from 'src/models/chat';
import { combineUrlParams } from 'src/common/constant';
import { CONVERSATION_API, CREATE_NEW_MESSAGE } from 'src/helpers/api';

export const sendNewMessage = async (request: any) => {
  try {
    const response = await axiosIns.post(CREATE_NEW_MESSAGE.CREATE, request);
    // console.log('response', response);
    if (response?.data?.data) {
      return response?.data?.data;
    }
  } catch (error: any) {
    console.log('error', error);
  }
};
// async getConversationForUser(type: number) {
//   try {
//     const response = await axiosIns.get(combineUrlParams(ChatAPI.GET_CONVERSATION_FOR_USER, { type, limit: 10 }));
//     if (response.data?.data) {
//       // console.log('response conver', response.data?.data);

//       return response.data?.data;
//     }
//   } catch (error: any) {
//     console.log('error', error);
//     return [];
//   }
// },


// export const getAllMessage = async (conversationId: number) => {
//   const response: any = await axiosIns.get(`${ChatAPI.GET_MESSAGE}/${conversationId}?page=1`);
//   // console.log('response all msg', response);
//   return response.data?.data?.message || [];
// };

export const getMessageByConversation = async (conversationId: any) => {
  const response: any = await axiosIns.get(`${CONVERSATION_API.GET_BY_ID_CONVERSATION}/${conversationId}?limit=20`);
  return response.data?.data;
};
