import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  message: [],
  dataConversationMessage: [],
  listLastViewer: [],
  currentConversationId: [],
};

const messageSlice: any = createSlice({
  name: 'message',
  initialState: initialState,
  reducers: {
    //@ts-ignore
    addMessage(state, action: PayloadAction<ListComment>) {
      const { conversationId, message } = action.payload;

      if (conversationId === state.currentConversationId) {
        let _data = state.dataConversationMessage;
        _data.push(message);
        state.dataConversationMessage = [..._data];
      }
    },

    updateCurrentConversationId: (state, action) => {
      state.currentConversationId = action.payload;
    },

    addDataMessage(state, action) {
      state.dataConversationMessage = action.payload;
    },

    setListLastViewer(state, action) {
      // const { conversationId, userId, lastMessage } = action.payload;

      // const listLastViewOld = state.setListLastViewer;
      // state.listLastViewer = listLastViewOld.push({ lastMessage, userId });

      state.listLastViewer = action.payload;
    },

  },
});

export const { addMessage, addDataMessage, setListLastViewer, updateCurrentConversationId } = messageSlice.actions;

export default messageSlice.reducer;
