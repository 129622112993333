import { DatePicker, Form, Input, Modal, Select } from 'antd';
import moment from 'moment';
import React from 'react';
import { ChatEvent, disableDateBeforeIncludeToday } from 'src/common/constant';
import { formLayout } from 'src/pages/constants';

const { Option } = Select;
export const ModalEvent = ({ visibleModalImport, onCancelImport, onCreateEvent }) => {
  const [form] = Form.useForm();

  const onOK = () => {
    let input = {
      type: form.getFieldValue('type'),
      takePlaceAt: moment(form.getFieldValue('takePlaceAt')).format('DD/MM/YYYY HH:mm:ss'),
      explanation: form.getFieldValue('explanation')
    }
    onCreateEvent(input)
  };

  const onRefreshFilter = () => {
    form.resetFields();
  };

  return (
    <Modal
      okText="Lưu"
      title='Tạo sự kiện'
      cancelText="Hủy"
      visible={visibleModalImport}
      centered
      onCancel={onCancelImport}
      onOk={onOK}
      afterClose={onRefreshFilter}
    >
      <div className="modal-des">
        <Form
          {...formLayout}
          form={form}
          labelAlign="left"
        >
          <Form.Item name='type' label='Loại sự kiện'>
            <Select placeholder='Chọn loại sự kiện'>
              <Option value={ChatEvent.SENDING_ANSWER}>Gửi câu trả lời, nội dung tư vấn </Option>
              <Option value={ChatEvent.MEET_EXPERTS}>Gặp mặt chuyên gia/luật sư </Option>
              <Option value={ChatEvent.CALL_FOR_ADVISE}>Gọi điện tư vấn/trao đổi </Option>
              <Option value={ChatEvent.DOCTOR_APPOINTMENT}>Đặt lịch khám bệnh </Option>
            </Select>
          </Form.Item>
          <Form.Item name='takePlaceAt' label='Thời gian'>
            <DatePicker
              style={{ width: '100%' }}
              showTime
              format={'DD/MM/YYYY HH:mm:ss'}
              disabledDate={disableDateBeforeIncludeToday}
              placeholder='Chọn thời gian'
            />

          </Form.Item>
          <Form.Item name='explanation' label='Diễn giải'>
            <Input.TextArea placeholder='Nhập diễn giải' autoSize={{ minRows: 2, maxRows: 6 }} />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};
