import { DatePicker, Form, Input, Modal, Select } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { ChatEvent, combineUrlParams, disableDateBeforeIncludeToday, TypeFile } from 'src/common/constant';
import { FORM_MANAGEMENT, UPLOAD_FILE_API } from 'src/helpers/api';
import axiosIns, { HOST } from 'src/helpers/request';
import { formLayout } from 'src/pages/constants';

const { Option } = Select;
export const ModalSheet = ({ visibleModalImport, onCancelImport, onCreateForm, dsBieuMau }) => {
  const [form] = Form.useForm();
  const [file, setfile] = useState<any>({});
  const [formId, setFormId] = useState<any>(null);
  //   Gọi API ds Biểu mẫu
  function fetchSearchForm(params = {}) {
    return new Promise((resolve, reject) => {
      axiosIns
        .get(combineUrlParams(`${FORM_MANAGEMENT.SEARCH}`, { ...params }))
        .then((s) => {
          s?.data?.data?.classification?.map((item) => {
            setfile(item?.attachment);
            setFormId(item?.id);
          });
          resolve(s?.data?.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
    // .then
  }

  const onOK = () => {
    onCreateForm(formId);
  };

  const onFilterAttachFile = (value: any) => {
    console.log('value', value);

    let input = {
      title: value,
    };
    fetchSearchForm(input);
  };

  const onRefreshFilter = () => {
    form.resetFields();
    setFormId(null);
    setfile({});
  };

  return (
    <Modal
      okText="Lưu"
      title="Tạo biểu mẫu"
      cancelText="Hủy"
      visible={visibleModalImport}
      centered
      onCancel={onCancelImport}
      onOk={onOK}
      afterClose={onRefreshFilter}
    >
      <div className="modal-des">
        <Form {...formLayout} form={form} labelAlign="left">
          <Form.Item name="type" label="Loại biểu mẫu">
            <Select onChange={onFilterAttachFile} placeholder="Chọn loại biểu mẫu">
              {dsBieuMau?.map((item: any, index: number) => {
                return <Option value={item?.title}>{item?.title}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item name="takePlaceAt" label="File đính kèm">
            <a href={`${HOST}${file?.filePath}`} download={file?.fileOriginName}>
              {file?.fileOriginName}
            </a>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};
