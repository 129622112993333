/* eslint-disable react/prop-types */
import { Modal, Table } from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment';

export const ModalProcessHistory = ({
  visibleModalTimeHistoryImport,
  onCancelImportHistoryTime,
  dsChiTietYCKN,
  type,
  stage3,
  stage4,
}) => {
  const [historyTimeList, setHistoryTimeList] = useState<any[]>([]);

  useEffect(() => {
    if (dsChiTietYCKN?.stageRequests) {
      switch (type) {
        case 'stage3':
          setHistoryTimeList(stage3?.processingDateHistories)
          break;
        case 'stage4':
          setHistoryTimeList(stage4?.processingDateHistories)
          break;
        default:
          break;
      }
    }
  }, [type, dsChiTietYCKN, stage3, stage4])

  return (
    <Modal
      width='1000px'
      cancelText="Hủy"
      open={visibleModalTimeHistoryImport}
      centered
      onCancel={onCancelImportHistoryTime}
      onOk={onCancelImportHistoryTime}
    >
      <div className="modal-des">
        <Table
          // scroll={{ x: 500 }}
          pagination={false}
          locale={{ emptyText: 'Không có kết quả' }}
          dataSource={historyTimeList}
          columns={[
            {
              title: (
                <div className="custome-header">
                  <div className="title-box">Ngày cũ</div>
                </div>
              ),
              width: 30,
              key: "col00",
              align: "center",
              dataIndex: 'oldDateTimestamp',
              render: (value, row, index) => moment(value).format("DD/MM/YYYY")
            },
            {
              title: (
                <div className="custome-header">
                  <div className="title-box">Ngày mới</div>
                </div>
              ),
              width: 250,
              key: "col1",
              align: "center",
              dataIndex: "newDateTimestamp",
              render: (value, row, index) => moment(value).format("DD/MM/YYYY")
            },
            {
              title: (
                <div className="custome-header">
                  <div className="title-box">Người thực hiện</div>
                </div>
              ),
              width: 100,
              key: "col02",
              align: "center",
              dataIndex: "createdByName",
            },
            {
              title: (
                <div className="custome-header">
                  <div className="title-box">Ngày thực hiện</div>
                </div>
              ),
              width: 100,
              key: "col02",
              align: "center",
              dataIndex: "createdAtTimestamp",
              render: (value) => moment(value).format("DD/MM/YYYY")
            },
          ]}
        />

      </div>
    </Modal>
  );
};
