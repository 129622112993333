import React from 'react';
import {
  AppRoutes,
  CompanyRoutes,
  InformationSystemRoutes,
  QuizzRoutes,
  SystemRoutes,
  UserRoutes,
  GuideRoutes,
  AccumulatePointRoutes,
  RoleRoutes,
  NotificationRoutes,
  QuesListSKNNRoutes,
  HealthQuizRoutes,
  ConsultationsRoutes,
  WorkConditionRoutes,
  HeadingListSKNNRoutes,
  SKNNListSKNNRoutes,
  ConclusionListSKNNRoutes,
  ClassifyListSKNNRoutes,
  HistoryRoutes,
} from 'src/helpers/app.routes';
import {
  BookFilled,
  DashboardFilled,
  DatabaseFilled,
  SnippetsFilled,
  HeartFilled,
  HomeFilled,
  MessageFilled,
  NotificationFilled,
  PhoneFilled,
  SafetyCertificateFilled,
  SettingFilled,
  SketchCircleFilled,
  UnorderedListOutlined,
  SkinFilled,
} from '@ant-design/icons';

export const SideBarRoute = {
  DASHBOARD: 'dashboard',
  ACCOUNT_SYSTEM: 'tai_khoan_he_thong',
  ROLE: 'phan_quyen',
  APP_USERS: 'quan_ly_nguoi_dung_app',
  COMPANY: 'danh_sach_cong_ty',
  REQUEST_COMPANY: 'yeu_cau_tao_moi_cong_ty',
  SURVEY: 'bo_cau_hoi_khao_sat',
  SURVEY_HISTORY: 'lich_su_khao_sat',
  FAST_SURVEY: 'khao_sat_nhanh',
  FAST_SURVEY_RESULT: 'ket_qua_khao_sat_nhanh',
  FAST_SURVEY_HISTORY: 'lich_su_khao_sat_nhanh',
  ACCUMULATE_POINT: 'quan_ly_rule_tich_diem',
  ACCUMULATE_POINT_HISTORY: 'lich_su_tich_diem',
  GIFT_VOUCHER_HISTORY: 'lich_su_doi_qua',
  FORUM: 'quan_ly_dien_dan',
  COMMON_QUESTION: 'cau_hoi_thuong_gap',
  NOTIFICATION: 'danh_sach_thong_bao',
  NOTIFICATION_HISTORY: 'lich_su_thong_bao',
  TERM: 'dieu_khoan_su_dung',
  TRAINNING_GUIDE: 'huong_dan_tap_luyen',
  ADVISE: 'tu_van_hoi_dap',
  INTRODUCTION: 'quan_ly_thong_tin_he_thong',
  HEALTH_SURVEY: 'bo-cau-hoi-suc-khoe-nghe-nghiep',
  APP_INTRODUCTION: 'gioi_thieu_ung_dung',
  INTRODUCTION_VIDEO: 'video_huong_dan',
  PRIVACY_POLICY: 'chinh_sach_quyen_rieng_tu',
  CATEGORY: 'quan_ly_hang_muc',
  DASHBOARD_ADMIN: 'dashboard_admin',
  BANNER_MANAGEMENT: 'quan_ly_banner',
  TIME_TRANSFER: 'cai_dat_thoi_gian',
  ANSWER_MANAGEMENT: 'quan_ly_cau_tra_loi',
  CHOICE_MANAGEMENT: 'quan_ly_dap_an',
  TITLE_MANAGEMENT: 'quan_ly_tieu_de',
  HEADING_MANAGEMENT: 'quan_ly_heading',
  QUESTION_MANAGEMENT: 'quan_ly_cau_hoi',
  SKNN_SHEET_MANAGEMRNT: 'quan_ly_phieu_sknn',
  CONCLUSION_MANAGEMENT: 'quan_ly_ket_luan',
  CLASSIFY_MANAGEMENT: 'quan_ly_phan_loai',
  ORIGINAL_QUESTION: 'bo_cau_hoi_goc',
  EVALUATE_FORM: 'phieu_danh_gia',
  CONSULTANCY_REQUEST: 'yeu_cau_tu_van',
  COMPLAIN_REQUEST: 'yeu_cau_khieu_nai',
  REQUEST_CLASSIFICATION: 'phan_loai_yeu_cau',
  SATISFACTION_REVIEW: 'danh_gia_muc_do_hai_long',
  FORM_MANAGEMENT: 'quan_ly_bieu_mau',
  APP_FEEDBACK: 'phan_hoi_ung_dung',
  HEALTH_SURVEY_HISTORY: 'lich_su_sknn',
  WORKING_CONDITION_HISTORY: 'lich_su_dklv',
  COMPANY_RANKING: 'xep_hang_cong_ty',
};

export const menus = [
  {
    key: [
      SideBarRoute.ACCOUNT_SYSTEM,
      SideBarRoute.ROLE,
      SideBarRoute.APP_USERS,
      SideBarRoute.COMPANY,
      SideBarRoute.REQUEST_COMPANY,
      SideBarRoute.FAST_SURVEY,
      SideBarRoute.FAST_SURVEY_HISTORY,
      SideBarRoute.TRAINNING_GUIDE,
      SideBarRoute.SURVEY_HISTORY,
      SideBarRoute.SURVEY,
      SideBarRoute.ACCUMULATE_POINT,
      SideBarRoute.ACCUMULATE_POINT_HISTORY,
      SideBarRoute.GIFT_VOUCHER_HISTORY,
      SideBarRoute.NOTIFICATION,
      SideBarRoute.NOTIFICATION_HISTORY,
      SideBarRoute.TERM,
      SideBarRoute.COMMON_QUESTION,
      SideBarRoute.PRIVACY_POLICY,
      SideBarRoute.APP_INTRODUCTION,
      SideBarRoute.INTRODUCTION_VIDEO,
      SideBarRoute.CATEGORY,
      SideBarRoute.HEALTH_SURVEY,
      SideBarRoute.DASHBOARD_ADMIN,
      SideBarRoute.BANNER_MANAGEMENT,
      SideBarRoute.CONSULTANCY_REQUEST,
      SideBarRoute.COMPLAIN_REQUEST,
      SideBarRoute.REQUEST_CLASSIFICATION,
      SideBarRoute.SATISFACTION_REVIEW,
      SideBarRoute.FORM_MANAGEMENT,
      SideBarRoute.APP_FEEDBACK,
    ],
    // key: SideBarRoute.DASHBOARD,
    title: 'Dashboard',
    to: AppRoutes.home,
    icon: <DashboardFilled />,
  },
  {
    key: [SideBarRoute.ACCOUNT_SYSTEM, SideBarRoute.ROLE],
    // key: `${SideBarRoute.ACCOUNT_SYSTEM},${SideBarRoute.ROLE}`,
    title: 'Quản lý Hệ thống',
    to: SystemRoutes.accountSystem,
    submenu: true,
    icon: <SettingFilled />,
    submenuItems: [
      {
        title: 'Tài khoản hệ thống',
        to: SystemRoutes.accountSystem,
        key: SideBarRoute.ACCOUNT_SYSTEM,
        icon: <SkinFilled />,
        role: ['tai_khoan_he_thong'],
      },
      {
        title: 'Phân quyền',
        to: RoleRoutes.roleList,
        key: SideBarRoute.ROLE,
        icon: <SafetyCertificateFilled />,
        role: ['phan_quyen'],
      },
    ],
  },
  {
    key: [SideBarRoute.APP_USERS],
    // key: SideBarRoute.APP_USERS,
    title: 'Quản lý Người dùng App',
    to: UserRoutes.userList,
    icon: <SkinFilled />,
    role: ['quan_ly_nguoi_dung_app'],
  },
  {
    key: [SideBarRoute.COMPANY, SideBarRoute.REQUEST_COMPANY],
    // key: `${SideBarRoute.COMPANY},${SideBarRoute.REQUEST_COMPANY}`,
    title: 'Quản lý Công ty',
    to: 'cong-ty/danh-sach-cong-ty',
    icon: <HomeFilled />,
    submenu: true,
    submenuItems: [
      {
        title: 'Danh sách công ty',
        to: CompanyRoutes.companyManagement,
        icon: <UnorderedListOutlined />,
        key: SideBarRoute.COMPANY,
      },
      {
        key: SideBarRoute.REQUEST_COMPANY,
        title: 'Yêu cầu tạo mới',
        to: CompanyRoutes.companyRequestManagement,
        icon: <UnorderedListOutlined />,
      },
    ],
  },
  {
    key: [SideBarRoute.FAST_SURVEY, SideBarRoute.FAST_SURVEY_HISTORY, SideBarRoute.FAST_SURVEY_RESULT],
    // key: `${SideBarRoute.FAST_SURVEY},${SideBarRoute.FAST_SURVEY_HISTORY}`,
    title: 'Quản lý Khảo sát nhanh',
    to: 'quizz',
    submenu: true,
    icon: <SnippetsFilled />,
    submenuItems: [
      {
        title: 'Danh sách khảo sát nhanh',
        to: QuizzRoutes.quizzList,
        icon: <UnorderedListOutlined />,
        key: SideBarRoute.FAST_SURVEY,
        role: ['khao_sat_nhanh'],
      },
      {
        title: 'Lịch sử khảo sát nhanh',
        to: QuizzRoutes.quizzHistory,
        icon: <UnorderedListOutlined />,
        key: SideBarRoute.FAST_SURVEY_HISTORY,
      },
      {
        title: 'Kết quả khảo sát nhanh',
        to: QuizzRoutes.quizzResultList,
        icon: <UnorderedListOutlined />,
        key: SideBarRoute.FAST_SURVEY_RESULT,
      },
    ],
  },
  {
    key: [SideBarRoute.TRAINNING_GUIDE],
    //  key: SideBarRoute.TRAINNING_GUIDE,
    title: 'Phát triển bản thân',
    to: GuideRoutes.guideList,
    icon: <BookFilled />,
  },
  {
    key: [SideBarRoute.ACCUMULATE_POINT, SideBarRoute.ACCUMULATE_POINT_HISTORY, SideBarRoute.GIFT_VOUCHER_HISTORY],
    // key: `${SideBarRoute.ACCUMULATE_POINT_HISTORY},${SideBarRoute.ACCUMULATE_POINT},${SideBarRoute.GIFT_VOUCHER_HISTORY}`,
    title: 'Quản lý Tích điểm',
    to: AccumulatePointRoutes.historyAccumulatePoint,
    icon: <SketchCircleFilled />,
    submenu: true,
    role: [],
    submenuItems: [
      {
        title: 'Lịch sử tích điểm',
        to: AccumulatePointRoutes.historyAccumulatePoint,
        icon: <UnorderedListOutlined />,
        key: SideBarRoute.ACCUMULATE_POINT_HISTORY,
      },
      {
        title: 'Quản lý Rule tích điểm',
        to: AccumulatePointRoutes.ruleAccumulatePointList,
        icon: <UnorderedListOutlined />,
        key: SideBarRoute.ACCUMULATE_POINT,
      },
      {
        title: 'Lịch sử đổi quà',
        to: AccumulatePointRoutes.historyExchange,
        icon: <UnorderedListOutlined />,
        key: SideBarRoute.GIFT_VOUCHER_HISTORY,
      },
    ],
  },
  {
    key: [SideBarRoute.NOTIFICATION, SideBarRoute.NOTIFICATION_HISTORY],
    // key: `${SideBarRoute.NOTIFICATION},${SideBarRoute.NOTIFICATION_HISTORY}`,
    title: 'Quản lý Thông báo',
    to: NotificationRoutes.notificationList,
    icon: <NotificationFilled />,
    submenu: true,
    role: [],
    submenuItems: [
      {
        title: 'Danh sách thông báo',
        to: NotificationRoutes.notificationList,
        icon: <UnorderedListOutlined />,
        key: SideBarRoute.NOTIFICATION,
      },
      {
        title: 'Lịch sử thông báo',
        to: NotificationRoutes.notificationHistory,
        icon: <UnorderedListOutlined />,
        key: SideBarRoute.NOTIFICATION_HISTORY,
      },
    ],
  },
  {
    key: [
      SideBarRoute.TERM,
      SideBarRoute.COMMON_QUESTION,
      SideBarRoute.PRIVACY_POLICY,
      SideBarRoute.APP_INTRODUCTION,
      SideBarRoute.INTRODUCTION_VIDEO,
      SideBarRoute.CATEGORY,
      SideBarRoute.TIME_TRANSFER,
    ],
    title: 'Quản lý Thông tin hệ thống',
    to: 'quizs',
    submenu: true,
    icon: <PhoneFilled />,
    role: [],
    submenuItems: [
      {
        title: 'Câu hỏi thường gặp',
        to: InformationSystemRoutes.usualQuestionList,
        icon: <UnorderedListOutlined />,
        key: SideBarRoute.COMMON_QUESTION,
      },
      {
        title: 'Điều khoản sử dụng',
        to: InformationSystemRoutes.termService,
        icon: <UnorderedListOutlined />,
        key: SideBarRoute.TERM,
      },
      {
        title: 'Chính sách dữ liệu cá nhân',
        to: InformationSystemRoutes.PrivacyPolicy,
        icon: <UnorderedListOutlined />,
        key: SideBarRoute.PRIVACY_POLICY,
      },
      {
        title: 'Giới thiệu ứng dụng',
        to: InformationSystemRoutes.IntroApp,
        icon: <UnorderedListOutlined />,
        key: SideBarRoute.APP_INTRODUCTION,
      },
      {
        title: 'Video hướng dẫn',
        to: InformationSystemRoutes.IntroVideo,
        icon: <UnorderedListOutlined />,
        key: SideBarRoute.INTRODUCTION_VIDEO,
      },
      {
        title: 'Quản lý Hạng mục',
        to: InformationSystemRoutes.ItemManager,
        icon: <UnorderedListOutlined />,
        key: SideBarRoute.CATEGORY,
      },
      {
        title: 'Dashboard Admin',
        to: InformationSystemRoutes.DashBroadAdmin,
        icon: <UnorderedListOutlined />,
        // key: SideBarRoute.APP_INTRODUCTION,
        key: SideBarRoute.DASHBOARD_ADMIN,
      },
      {
        title: 'Quản lý Banner',
        to: InformationSystemRoutes.bannerManagement,
        icon: <UnorderedListOutlined />,
        key: SideBarRoute.BANNER_MANAGEMENT,
      },
      {
        title: 'Cài đặt thời gian',
        to: InformationSystemRoutes.TimeTransfer,
        icon: <UnorderedListOutlined />,
        key: SideBarRoute.TIME_TRANSFER,
      },
    ],
  },
  {
    key: [
      SideBarRoute.ANSWER_MANAGEMENT,
      SideBarRoute.CHOICE_MANAGEMENT,
      SideBarRoute.TITLE_MANAGEMENT,
      SideBarRoute.HEADING_MANAGEMENT,
      SideBarRoute.QUESTION_MANAGEMENT,
      SideBarRoute.SKNN_SHEET_MANAGEMRNT,
      SideBarRoute.CONCLUSION_MANAGEMENT,
      SideBarRoute.CLASSIFY_MANAGEMENT,
      SideBarRoute.HEALTH_SURVEY_HISTORY,
    ],
    title: 'Bộ câu hỏi SKNN',
    to: 'quizs',
    submenu: true,
    icon: <HeartFilled />,
    role: [],
    submenuItems: [
      {
        title: ' Quản lý Heading',
        to: HeadingListSKNNRoutes.headingList,
        icon: <UnorderedListOutlined />,
        key: SideBarRoute.HEADING_MANAGEMENT,
      },
      {
        title: 'Quản lý Hợp phần', //Quản lý Tiêu đề
        to: HealthQuizRoutes.headerList,
        icon: <UnorderedListOutlined />,
        key: SideBarRoute.TITLE_MANAGEMENT,
      },
      {
        title: 'Quản lý Tiêu đề', //Quản lý Câu trả lời
        to: HealthQuizRoutes.replyList,
        icon: <UnorderedListOutlined />,
        key: SideBarRoute.ANSWER_MANAGEMENT,
      },
      {
        title: 'Quản lý Đáp án',
        to: HealthQuizRoutes.answerList,
        icon: <UnorderedListOutlined />,
        key: SideBarRoute.CHOICE_MANAGEMENT,
      },
      {
        title: 'Quản lý Câu hỏi',
        to: QuesListSKNNRoutes.questionList,
        icon: <UnorderedListOutlined />,
        key: SideBarRoute.QUESTION_MANAGEMENT,
      },
      {
        title: 'Quản lý Phiếu SKNN',
        to: SKNNListSKNNRoutes.SKNNList,
        icon: <UnorderedListOutlined />,
        key: SideBarRoute.SKNN_SHEET_MANAGEMRNT,
      },
      {
        title: 'Quản lý TVSK',
        to: ConclusionListSKNNRoutes.conclusionList,
        icon: <UnorderedListOutlined />,
        key: SideBarRoute.CONCLUSION_MANAGEMENT,
      },
      {
        title: 'Quản lý Kết luận',
        to: ClassifyListSKNNRoutes.classifyList,
        icon: <UnorderedListOutlined />,
        key: SideBarRoute.CLASSIFY_MANAGEMENT,
      },
      {
        title: 'Lịch sử SKNN',
        to: HistoryRoutes.occupationalHealthList,
        icon: <UnorderedListOutlined />,
        key: SideBarRoute.HEALTH_SURVEY_HISTORY,
      },
    ],
  },
  {
    key: [
      SideBarRoute.ORIGINAL_QUESTION,
      SideBarRoute.EVALUATE_FORM,
      SideBarRoute.COMPANY_RANKING,
      SideBarRoute.WORKING_CONDITION_HISTORY,
    ],
    title: 'Bộ câu hỏi ĐKLV',
    to: WorkConditionRoutes.originalQuestionList,
    icon: <NotificationFilled />,
    submenu: true,
    role: [],
    submenuItems: [
      {
        title: 'Bộ câu hỏi gốc',
        to: WorkConditionRoutes.originalQuestionList,
        icon: <UnorderedListOutlined />,
        key: SideBarRoute.ORIGINAL_QUESTION,
      },
      {
        title: 'Phiếu đánh giá',
        to: WorkConditionRoutes.evaluateSheetList,
        icon: <UnorderedListOutlined />,
        key: SideBarRoute.EVALUATE_FORM,
      },
      {
        title: 'Xếp hạng công ty',
        to: WorkConditionRoutes.ratingCompany,
        icon: <UnorderedListOutlined />,
        key: SideBarRoute.COMPANY_RANKING,
      },
      {
        title: 'Lịch sử ĐKLV',
        to: HistoryRoutes.workingConditionList,
        icon: <UnorderedListOutlined />,
        key: SideBarRoute.WORKING_CONDITION_HISTORY,
      },
    ],
  },
  {
    key: [
      SideBarRoute.CONSULTANCY_REQUEST,
      SideBarRoute.COMPLAIN_REQUEST,
      SideBarRoute.REQUEST_CLASSIFICATION,
      SideBarRoute.SATISFACTION_REVIEW,
      SideBarRoute.FORM_MANAGEMENT,
      SideBarRoute.APP_FEEDBACK,
    ],
    title: 'Tư vấn khiếu nại',
    to: ConsultationsRoutes.requestClassificationList,
    icon: <NotificationFilled />,
    submenu: true,
    role: [],
    submenuItems: [
      {
        title: 'Phân loại yêu cầu',
        to: ConsultationsRoutes.requestClassificationList,
        icon: <UnorderedListOutlined />,
        key: SideBarRoute.REQUEST_CLASSIFICATION,
      },
      {
        title: 'Yêu cầu tư vấn',
        to: ConsultationsRoutes.requestCounsellingList,
        icon: <UnorderedListOutlined />,
        key: SideBarRoute.CONSULTANCY_REQUEST,
      },
      {
        title: 'Yêu cầu khiếu nại',
        to: ConsultationsRoutes.complaintList,
        icon: <UnorderedListOutlined />,
        key: SideBarRoute.COMPLAIN_REQUEST,
      },
      {
        title: 'Phản hồi về ứng dụng',
        to: ConsultationsRoutes.appFeedbackList,
        icon: <UnorderedListOutlined />,
        key: SideBarRoute.APP_FEEDBACK,
      },
      {
        title: 'Đánh giá mức độ hài lòng',
        to: ConsultationsRoutes.satisfactionRatingList,
        icon: <UnorderedListOutlined />,
        key: SideBarRoute.SATISFACTION_REVIEW,
      },
      {
        title: 'Quản lý biểu mẫu',
        to: ConsultationsRoutes.formManagementList,
        icon: <UnorderedListOutlined />,
        key: SideBarRoute.FORM_MANAGEMENT,
      },
    ],
  },
];
