/* eslint-disable react/display-name */
import React, { useEffect, useMemo, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AppRoutes } from 'src/helpers/app.routes';
import MainLayout from 'src/layouts/Main';
import { Page403 } from 'src/pages/ErrorPage/403';
import { Page404 } from 'src/pages/ErrorPage/404';
import { routes } from './constants';
import { localStore } from 'src/common/localStorage';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import axiosIns from 'src/helpers/request';
import { AUTH } from 'src/helpers/api';
import PublicTermsAndPolicies from 'src/pages/public-dieu-khoan-va-chinh-sach';
import { initSocket, socketClient } from 'src/components/Socket';
import { useMessageState } from 'src/hooks/useMessageState';

const HomePage = React.lazy(() => import('src/pages/dashboard'));
const LoginPage = React.lazy(() => import('src/pages/login'));

export const RootRouter = React.memo(() => {
  const [currentUser, setCurrentUser] = useState<any>({});
  const onHandleCurrentUser = (value: any) => {
    setCurrentUser(value);
  };
  const { onAddDataMessage, onSetLastListViewer } = useMessageState()
  //API chi tiết QUYỀN
  const fetchCurrentUser = () => {
    return new Promise((resolve, reject) => {
      axiosIns
        .get(`${AUTH.CURRENT_USER}`)
        .then((s) => {
          setCurrentUser(s?.data?.data.permission?.moduleEnforces);
          localStorage.setItem(localStore.ALLOW_MODULES, JSON.stringify(s?.data?.data.permission?.moduleEnforces));
          resolve(s?.data?.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
    // .then
  };

  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      fetchCurrentUser();
      // const getToken = async () => {
      //   // await getAccessToken();
      //   await initSocket('Bearer ' + localStorage.getItem('accessToken'));
      //   if (socketClient) {
      //     socketClient.on('create-conversation', (conversationId: any) => {
      //       socketClient?.emit('join-conversation', conversationId);
      //       // handleEnterChat(conversationId);
      //     });

      //     socketClient.on('user-last-view', (lastView: any) => {
      //       console.log('lastView', lastView);
      //       onSetLastListViewer(lastView);
      //     });

      //     socketClient.on('new-mess', (conversationId: any, message: any) => {
      //       console.log('mess', message);

      //       onAddDataMessage(message, conversationId);
      //     });
      //   }
      // };
      // getToken();
    }
  }, []);


  const allowModules: any = useMemo(() => {
    // let results: any = {}
    const results = localStorage.getItem(localStore.ALLOW_MODULES) || currentUser;
    if (results === localStorage.getItem(localStore.ALLOW_MODULES) && results !== 'undefined' && results !== undefined) {
      return JSON.parse(results);
    }
    // ae tự xử lý logout
    return {};
  }, [localStorage.getItem(localStore.ALLOW_MODULES)]);

  return (
    <Routes>
      <Route path={AppRoutes.login} element={<LoginPage onHandleCurrentUser={onHandleCurrentUser} />} />
      <Route path={AppRoutes.home} element={<MainLayout allowModules={allowModules} currentUser={currentUser} />}>
        <Route index element={<HomePage />} />
        {routes.map((route, index) => {
          const isExistFromRole = allowModules[route.sidebar]?.includes(route.permission);
          return (
            <Route key={`route${index}`} path={route.path} element={isExistFromRole ? route.element : <Page403 />} />
          );

        })}
      </Route>
      <Route path={AppRoutes.publicTemrmPolicy} element={<PublicTermsAndPolicies />} />
      <Route path={AppRoutes.page404} element={<Page404 />} />
      <Route path={AppRoutes.page403} element={<Page403 />} />
    </Routes>
  );
});


