import { message } from 'antd';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import React, { useState } from 'react';
import { AUTH } from './api';
import { AppRoutes } from './app.routes';

// import dotenv from 'dotenv'
// dotenv.config()

let configNetwork: any = {
  _retry: false,
};

export const HOST = 'https://apiwecheck.click/';
export const SOCKETURL = 'https://uat.socket.apiwecheck.click/';

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  if (config.url !== AUTH.REFRESH_TOKEN) {
    const accessToken = `Bearer ${localStorage.getItem('accessToken')}`;
    config.headers = {
      Authorization: accessToken,
    };
  }
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  // console.error(`[request error] [${JSON.stringify(error)}]`);
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = async (error) => {
  // console.error(`[response error] [${JSON.stringify(error)}]`);
  if (
    error.response?.status === 401 &&
    error.config.url !== AUTH.LOGOUT &&
    error.config.url !== AUTH.REFRESH_TOKEN &&
    !configNetwork._retry
  ) {
    const res = await fetchRefeshToken(error);
    return res;
  } else if (error.response?.status === 403) {
    window.location.href = AppRoutes.page403;
    return Promise.reject(error);
  } else {
    return Promise.reject(error);
  }
};

const headers = {};

const axiosIns = axios.create({
  baseURL: HOST, //process.env.REACT_APP_BACKEND_DEV_URL,
  headers,
  timeout: 30 * 60 * 1000,
  withCredentials: true,
});

export const syncAuthToDevice = (data: any) => {
  if (data) {
    // await asyncStorageService.syncToDevice(AsyncStorageKey.USER, data?.user);
    localStorage.setItem('accessToken', data?.data?.data?.accessToken);
    localStorage.setItem('refreshToken', data?.data?.data?.refreshToken);
    // console.log(AsyncStorageKey.ACCESS_TOKEN, `Bearer ${data?.accessToken}`);
  }
};

const fetchRefeshToken = async (error) => {
  const refreshToken = localStorage.getItem('refreshToken');
  try {
    const response = await axiosIns.post(`${AUTH.REFRESH_TOKEN}`, { refreshToken });
    if (response !== undefined) {
      syncAuthToDevice(response);
      configNetwork = error.response.config;
      configNetwork.Authorization = `Bearer ${response?.data?.data?.accessToken}`;
      const res = await axiosIns(configNetwork);
      console.log(res);
      return res;
    } else {
      localStorage.clear();
      message.error('Phiên đăng nhập hết hạn, vui lòng đăng nhập lại ');
      setTimeout((window.location.href = AppRoutes.login), 1000);
    }
  } catch (err) {
    localStorage.clear();
    message.error('Phiên đăng nhập hết hạn, vui lòng đăng nhập lại ');
    setTimeout((window.location.href = AppRoutes.login), 1000);
  }
};
// return new Promise((resolve, reject) => {
//   axiosIns
//     .post(`${AUTH.REFRESH_TOKEN}`, { refreshToken })
//     .then((data) => {
//       if (data !== undefined) {
//         syncAuthToDevice(data);
//         configNetwork = error.response.config;
//         configNetwork.Authorization = `Bearer ${data?.data?.data?.accessToken}`;
//         const res = axiosIns(configNetwork);
//         return res;
//       } else {
//         localStorage.clear();
//         message.error('Phiên đăng nhập hết hạn, vui lòng đăng nhập lại ');
//         setTimeout((window.location.href = AppRoutes.login), 1000);
//       }
//     })
//     .catch((e) => {
//       reject(e);
//       localStorage.clear();
//       message.error('Phiên đăng nhập hết hạn, vui lòng đăng nhập lại ');
//       setTimeout((window.location.href = AppRoutes.login), 1000);
//     });
// });

axiosIns.interceptors.request.use(onRequest, onRequestError);
axiosIns.interceptors.response.use(onResponse, onResponseError);

export default axiosIns;
