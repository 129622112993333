import React from 'react';
import { PageHeader as Header, Typography } from 'antd';
import styles from './styles.module.scss';
import { NavLink } from 'react-router-dom';
import { Else, If, Then } from 'react-if';

interface Props {
  title?: string;
  routes?: { path: string; breadcrumbName: string | any }[];
}

export const PageHeader: React.FC<Props> = ({ title, routes }) => {
  const itemRender = (route: any, params: any, routes: any, paths: any) => {
    const last = routes.indexOf(route) === routes.length - 1;
    const first = routes.indexOf(route) === 0;

    if (last) {
      return <Typography.Text className={styles.textBlack}>{route.breadcrumbName}</Typography.Text>;
    }

    return (
      <NavLink to={`/${paths.join('/')}`}>
        <If condition={!!first}>
          <Then>
            <Typography.Text type="secondary" className={styles.textBlack}>
              Trang chủ
            </Typography.Text>
          </Then>
          <Else>
            <Typography.Text type="secondary" className={styles.textBlack}>
              {route.breadcrumbName}
            </Typography.Text>
          </Else>
        </If>
      </NavLink>
    );
  };

  return <Header className={styles.pageHeader} title={title} breadcrumb={{ routes, separator: '>', itemRender }} />;
};
