export const AUTH = {
  LOGIN: '/auth/login',
  LOGOUT: '/auth/log-out',
  REGISTER: '/auth/register',
  FORGOT_PASSWORD: '/auth/forgot-password',
  VERIFY_EMAIL_CODE: '/auth/verify-email',
  REFRESH_TOKEN: '/auth/refresh',
  CURRENT_USER: '/user/currentUserInfo',
};

export const USER_API = {
  detail: '/user/app/',
  resetPassword: '/user/resetAdminPassword',
  search: '/user/app',
  updateNote: '/user/app/',
  historyWork: '/user-company-history/app/',
  FILTER_USER: '/user/filter',
};

export const SYSTEM_API = {
  create: '/user/add',
  list: '/user/admin',
  detail: '/user/admin/',
  update: '/user/updateUser',
  import: '/user/add/import',
  listRole: '/permission/get/active',
};

export const HISTORY_POINT_API = {
  getAllPoints: 'user-accumulate/point',
  list: 'user-accumulate/search',
};

export const HISTORY_EXCHANGE_API = {
  list: 'gift-exchange-history/search',
};

export const ACCUMULATE_RULE_API = {
  create: 'accumulate-rule/create',
  update: 'accumulate-rule/update',
  list: 'accumulate-rule/search',
  getAllPoints: 'accumulate-rule/point',
};

// CÔNG TY
export const COMPANY_API = {
  GET_ALL: '/company',
  SEARCH: '/company/search',
  CREATE: '/company/create/admin?requestCodes=',
  UPDATE: '/company/update/',
  UPDATE_REQUEST: 'company/update-note-request/',
  GET_DETAIL: '/company/get/',
  GET_REQUEST_DETAIL: '/company/get-request/',
  SEARCH_REQUEST: '/company/search-request',
  APPROVE_COMPANY_REQUEST: '/company/approve-request/',
  NUMBER_PENDING_REQUEST: '/company/get-request/pending-request',
  SEARCH_NO_COUNT: '/company/search-no-count',
  APPROVE_REQUEST_AND_UPDATE_COMPANY: '/company/approve-request-and-update-company',
};

// NGÀNH NGHỀ
export const INDUSTRY_API = {
  GET_ALL: '/survey/industry/titleCDI',
};

// TỈNH/THÀNH PHỐ
export const PROVINCES_API = {
  GET_ALL: '/provinces/',
};

// KHẢO SÁT NHANH
export const SURVEY_API = {
  SEARCH: '/survey',
  CREATE: '/survey/createQuick',
  GET_DETAIL: '/survey/',
  UPDATE: '/survey/updateQuick',
  DELETE: '/survey/deleteQuick',
  HISTORY: '/survey-draft/search-complete',
  EXPORT: '/survey/export',
  SEARCH_BY_STATUS: '/survey/getSurveyProcessingAndEnding',
};

//KẾT QUẢ KHẢO SÁT NHANH
export const FAST_SURVEY_RESULT_API = {
  SEARCH: '/survey-appreciation/search',
  CREATE: '/survey-appreciation/create',
  GET_DETAIL: '/survey-appreciation/get',
  UPDATE: '/survey-appreciation/update',
};

// CHỨC DANH
export const POSITION_API = {
  GET_ALL: '/position',
};

// CKeditor upload image
export const CKEDITOR_IMAGE_API = {
  API_URL: 'http://34.101.145.91:5679',
  UPLOAD_ENDPOINT: 'media/uploads',
};

//HƯỚNG DẪN TẬP LUYỆN
export const GUIDE_API = {
  SEARCH: '/post-list',
  CREATE: '/post-list/createPost',
  GET_DETAIL: '/post-list',
  UPDATE: '/post-list/updatePost/',
};

//HƯỚNG DẪN HẠNG MỤC
export const CATEGORY_API = {
  SEARCH: '/category/search',
  CREATE: '/category/create',
  UPDATE: '/category/update',
  GET_BY_ID: '/category/get',
};

//CÂU HỎI THƯỜNG GẶP
export const COMMON_QUESTION_API = {
  SEARCH: '/common-question/search',
  CREATE: '/common-question/create',
  UPDATE: '/common-question/update',
  GET_ALL: '/common-question',
  GET_BY_ID: '/common-question/get',
};

//ĐIỀU KHOẢN_CHÍNH SÁCH_GIỚI THIỆU
export const INTRO_APP_API = {
  UPDATE: '/introduction/update',
  GET_ALL: '/introduction',
};

//THÔNG BÁO
export const NOTI_API = {
  SEARCH: '/notification',
  CREATE: '/notification/createNotification',
  DETAIL: '/notification/get/',
  UPDATE: '/notification/updateNoti/',
  HISTORY_SEARCH: '/notification/search-history',
  UPLOAD: 'media/attachedFile',
};

//VAI TRÒ
export const ROLE_API = {
  SEARCH: '/permission',
  DELETE: '/permission/',
  CREATE: '/permission/create',
  GET_DETAIL: '/permission',
  UPDATE: '/permission',
};

// VIDEO HƯỚNG DẪN
export const VIDEO_API = {
  SEARCH: '/introduction-video',
  CREATE: '/introduction-video/create',
  GET_DETAIL: '/introduction-video',
  UPDATE: '/introduction-video',
};

// Admin Dashboard
export const DASHBOARD_ADMIN_API = {
  UPDATE: '/dashboard-admin/update',
  GET_ALL: '/dashboard-admin',
};

// Banner
export const BANNER_API = {
  SEARCH: '/banner',
  UPDATE: '/banner/updateBanner',
  CREATE: '/banner/createBanner',
  GET_DETAIL: '/banner/get',
  UPLOAD: 'media/attachedFile',
};

// TIÊU CHUẨN
export const EMPLOYMENT_STANDARD_API = {
  GET_ALL: '/working-conditions-survey/get/employment-standards',
};

// Câu hỏi gốc
export const ORGINAL_QUESTION_API = {
  SEARCH: '/base-of-question/dklv/filter',
  UPDATE: '/base-of-question/dklv/update',
  CREATE: '/base-of-question/dklv/create',
  GET_DETAIL: '/base-of-question/dklv/get',
};

// Câu hỏi gốc
export const EVALUATE_SHEET_API = {
  SEARCH: '/working-condition-survey/survey/filter',
  UPDATE: '/working-condition-survey/survey/update',
  CREATE: '/working-condition-survey/survey/create',
  GET_DETAIL: '/working-condition-survey/survey/get',
};

//tiêu đề
export const TITLE_API = {
  SEARCH: '/sknn-title/get',
  UPDATE: '/sknn-title/update',
  CREATE: '/sknn-title/create',
  GET_DETAIL: '/sknn-title/get',
};

// đáp án
export const ANSWER_API = {
  SEARCH: '/base-of-choice/search',
  UPDATE: '/base-of-choice/update',
  CREATE: '/base-of-choice/create',
  GET_DETAIL: '/base-of-choice/get-by-id',
};

// phân loại
export const CLASSIFY_API = {
  SEARCH: '/sknn-classification/get',
  UPDATE: '/sknn-classification/update',
  CREATE: '/sknn-classification/create',
  GET_DETAIL: '/sknn-classification/get',
};

// Heading
export const HEADING_API = {
  SEARCH: '/heading/get',
  UPDATE: '/heading/update',
  CREATE: '/heading/create',
  GET_DETAIL: '/heading/get',
};

// bộ tiêu chuẩn
export const WORKING_STANDARD_API = {
  GET_ALL: '/working-condition-survey/standard',
};

// bộ loại câu hỏi
export const QUESTION_TYPE_WORKING_API = {
  GET_ALL: '/base-of-choice/dklv/search',
};

// câu trả lời
export const REPLY_API = {
  SEARCH: '/base-of-answer/search',
  UPDATE: '/base-of-answer/update',
  CREATE: '/base-of-answer/create',
  GET_DETAIL: '/base-of-answer/get-by-id',
};

// bộ câu hỏi SKNN
export const QUESTION_SKNN_API = {
  SEARCH: '/base-of-question/sknn/search',
  UPDATE: '/base-of-question/sknn/update',
  CREATE: '/base-of-question/sknn/create',
  GET_DETAIL: '/base-of-question/sknn/get-by-id',
};

//Tư vấn phân loại
export const CLASSIFY_REQUEST_API = {
  list: 'classification-requests/get',
  getAdviceTopic: '/advise-requests/get-topic',
  getDetail: (id: string | undefined) => `/classification-requests/get/${id}`,
  acceptAdviseRequest: (id: string | undefined) => `/classification-requests/accept-advise-request/${id}`,
  reject: (id: string | undefined) => `/classification-requests/reject/${id}`,
};
export const ADVICE_REQUEST_API = {
  list: 'advise-requests/get',
  getAdviceTopic: '/advise-requests/get-topic',
  getDetail: (id: string | undefined) => `/advise-requests/get/${id}`,
  updateAdviceRequest: (id: string | undefined) => `/advise-requests/update/${id}`,
  createAdviceRequest: '/advise-requests/create',
  getFile: (id: string | undefined) => `/advise-requests/get-file/${id}`,
};

export const CHAT_API = {
  newMessage: '/chat/new-message-test',
  newConversation: '/chat/create-conversation',
  createEvent: '/chat/new-message-test',
};

//kết luận
export const CONCLUSION_API = {
  SEARCH: '/sknn-conclusion/get',
  UPDATE: '/sknn-conclusion/update',
  CREATE: '/sknn-conclusion/create',
  GET_DETAIL: '/sknn-conclusion/get',
};

//bộ sknn
export const SURVEY_SKNN_API = {
  SEARCH: '/sknn-survey/search',
  UPDATE: '/sknn-survey/update',
  CREATE: '/sknn-survey/create',
  GET_DETAIL: '/sknn-survey/get-by-id',
};

//mức độ hài lòng
export const SATISFACTION_RATING = {
  SEARCH: '/satisfaction-rating/get',
};

//quản lý biểu mẫu
export const FORM_MANAGEMENT = {
  SEARCH: '/form-management/get',
  CREATE: '/form-management/create',
  UPDATE: '/form-management/update',
  GET_DETAIL: '/form-management/get',
};

//yêu câu khiếu nại
export const REQUEST_COMPLAINT_API = {
  SEARCH: '/complaint/get',
  CREATE: '/complaint/create',
  UPDATE: '/complaint/update',
  GET_DETAIL: '/complaint/get',
  getAdviceTopic: '/advise-requests/get-topic',
  EXPORT: '/complaint/get',
};

//phản hồi ứng dụng
export const APP_FEEDBACK_API = {
  SEARCH: '/app-feedback/get',
  CREATE: '/app-feedback/create',
  GET_DETAIL: '/app-feedback/get',
  UPDATE: '/app-feedback/update',
};

//lịch sử đánh giá
export const EVALUATE_HISTORY = {
  SEARCH: '/survey-draft/search-complete',
  GET_DETAIL_SKNN: (surveyId: string | undefined, userId: string | undefined) =>
    `/sknn-survey-result/history/user/${userId}/survey/${surveyId}`,
  GET_DETAIL_DKLV: (userId: string | undefined) => `/working-condition-survey-result/history/user/${userId}`,
  EXPORT_DKLV: '/working-condition-survey-result/export-excel',
  EXPORT_SKNN: '/sknn-survey-result/export-excel'
};

// tạo tin nhắn mới
export const CREATE_NEW_MESSAGE = {
  CREATE: '/chat/new-message-test',
  DELETE: '/chat/delete-message',
};

// tạo cuộc hội thoại mới mới
export const CREATE_NEW_CONVERSATION = {
  CREATE: '/chat/create-conversation',
  GET_NUMBER_NEW_CONSVERVATION: '/classification-requests/get-new-advise',
};

// chat, cuộc hội thoại
export const CONVERSATION_API = {
  GET_BY_ID_CONVERSATION: '/chat/get-message',
};

//  việc cần làm checklist
export const CHECKLIST_API = {
  GET_ALL: '/advise-requests/get-checklist-content',
};
//  quản lý tệp YCKN
export const MANAGE_FILE_API = {
  GET_BY_ID: '/advise-requests/get-file',
};

//  quản lý YCKN
export const REQUEST_COMPLAINT_INFORMATON_API = {
  UPDATE: '/advise-requests/update-stage',
};

//  upload file, anh trong yctv, yckn
export const UPLOAD_FILE_API = {
  UPLOAD: 'media/common/upload',
};

// xếp hạng công ty
export const COMPANY_RANK_API = {
  list: '/company-rank/search',
  calculator: '/company-rank/calculate',
};

// Cài đặt thời gian
export const TIME_TRANSFER_API = {
  LIST: '/status-switch-time',
  CREATE: '/status-switch-time',
};
