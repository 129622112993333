import React from 'react'
import styles from './styles.module.scss';

export default function PublicTermsAndPolicies() {

  return (
    <div className={styles.main} dangerouslySetInnerHTML={{
      __html: `<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: center; line-height: normal; background: white;" align="center"><strong><span style="font-size: 16.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">ĐIỀU KHOẢN SỬ DỤNG VÀ CHÍNH SÁCH QUYỀN RIÊNG TƯ<br>ỨNG DỤNG WE CHECK</span></strong></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: center; line-height: normal; background: white; mso-background-themecolor: background1;" align="center"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black; mso-themecolor: text1;">Cập nhật ngày: 14/11/2022</span></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><strong><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">I. LỜI NÓI ĐẦU</span></strong></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; line-height: normal; background: white; mso-background-themecolor: background1;"><strong><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black; mso-themecolor: text1;">Trung tâm Phát triển và Hội nhập </span></strong><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black; mso-themecolor: text1;">(Sau đây gọi tắt là Chúng tôi/CDI)</span><br>Trụ sở: 176 Thái Hà, Đống Đa, Hà Nội</p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; line-height: normal; background: white; mso-background-themecolor: background1;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black; mso-themecolor: text1;">Văn phòng: Tầng 16, số 169 Nguyễn Ngọc Vũ, Cầu Giấy, Hà Nội</span><!-- [if !supportAnnotations]--><br><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black; mso-themecolor: text1;">Số điện thoại liên hệ: 024 3538 0100</span></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; line-height: normal; background: white; mso-background-themecolor: background1;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black; mso-themecolor: text1;">Email: </span><a href="mailto:wecheck@cdivietnam.org"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">wecheck@cdivietnam.org</span></a><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black; mso-themecolor: text1;"> </span></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white; mso-background-themecolor: background1;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black; mso-themecolor: text1;">CDI phát triển và cung cấp ứng dụng <strong>WE CHECK_Better Work - Better Health</strong> (sau đây gọi tắt là Ứng dụng) như một công cụ để giúp người lao động nói chung, đặc biệt là người lao động trong hai ngành điện tử và may mặc chủ động đánh giá điều kiện làm việc và theo dõi sức khỏe nghề nghiệp của bản thân. Ứng dụng đồng thời giúp người dùng (sau đây gọi tắt là Bạn) <span style="mso-spacerun: yes;"> </span>tìm kiếm và cập nhật các thông tin liên quan đến các chủ đề về lao động, việc làm, sức khỏe... và gửi các câu hỏi thắc mắc, khiếu nại đến các bên liên quan để được tư vấn, hỗ trợ giải quyết. <span style="mso-spacerun: yes;"> </span></span></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white; mso-background-themecolor: background1;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black; mso-themecolor: text1;">Vui lòng đọc kỹ <strong>Điều khoản sử dụng</strong> và <strong>Chính sách quyền riêng tư</strong> (Sau đây gọi tắt là Điều khoản và Chính sách) trước khi Bạn tiến hành tải, cài đặt và sử dụng một phần hoặc toàn bộ tính năng của ứng dụng (bao gồm nhưng không giới hạn phần mềm, các file và các tài liệu liên quan). Khi bạn thực hiện một hoặc toàn bộ các thao tác trên đồng nghĩa với việc bạn đã đồng ý với các nội dung của Điều khoản sử dụng và Chính sách của ứng dụng đã được cung cấp. </span></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">Trường hợp bạn không đồng ý với bất kỳ nội dung nào trong bản Điều khoản và Chính sách của ứng dụng (phiên bản này và các phiên bản cập nhật), Bạn vui lòng không tải, cài đặt, sử dụng bất kỳ tính năng nào của ứng dụng và tháo gỡ ứng dụng ra khỏi thiết bị di động của bạn.</span></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><strong><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">II. ĐIỀU KHOẢN SỬ DỤNG</span></strong></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><strong><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">1. Quyền sở hữu ứng dụng</span></strong></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white; mso-background-themecolor: background1;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black; mso-themecolor: text1;">Ứng dụng này được phát triển bởi CDI. Tất cả các quyền sở hữu, quyền tài sản liên quan đến ứng dụng (bao gồm nhưng không giới hạn bởi quyền sở hữu trí tuệ, mã nguồn, hình ảnh, dữ liệu, thông tin, nội dung chứa đựng trong ứng dụng; các sửa đổi, bổ sung, cập nhật của ứng dụng...) và các tài liệu hướng dẫn liên quan (nếu có) sẽ thuộc quyền sở hữu duy nhất của CDI. Không có cá nhân, tổ chức nào được phép sao chép, tái tạo, phân phối hoặc hình thức khác xâm phạm đến quyền sở hữu nếu không có sự đồng ý và cho phép bằng văn bản của CDI.</span></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><strong><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">2. Nội dung của ứng dụng</span></strong></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><strong><em><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">2.1. Các loại thông tin ứng dụng cung cấp</span></em></strong></p>
<ul type="disc">
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l1 level1 lfo3; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Các phiếu tự đánh giá về điều kiện làm việc và sức khỏe nghề nghiệp</span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l1 level1 lfo3; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Các báo cáo kết quả tự đánh giá điều kiện làm việc của nhà máy, của ngành</span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l1 level1 lfo3; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Các bài viết cập nhật các thông tin chính thống liên quan đến điều kiện làm việc, sức khỏe lao động và các vấn đề khác liên quan đến người lao động</span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l1 level1 lfo3; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Các bài tập hỗ trợ người lao động nâng cao kỹ năng, sức khỏe</span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l1 level1 lfo3; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Các tài liệu bổ ích cho công việc, sức khỏe của người lao động</span></li>
</ul>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><strong><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">2.2.</span></strong><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;"> <strong><em>Các tính năng của ứng dụng</em></strong></span></p>
<ul type="disc">
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l6 level1 lfo4; tab-stops: list .5in; background: white; mso-background-themecolor: background1;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black; mso-themecolor: text1;">Đánh giá sức khỏe nghề nghiệp </span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l6 level1 lfo4; tab-stops: list .5in; background: white; mso-background-themecolor: background1;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black; mso-themecolor: text1;">Đánh giá điều kiện làm việc</span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l6 level1 lfo4; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Tư vấn – Giải đáp</span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l6 level1 lfo4; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Phát triển bản thân</span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l6 level1 lfo4; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Khảo sát nhanh</span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l6 level1 lfo4; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Tích điểm – Đổi quà</span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l6 level1 lfo4; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Xếp hạng công ty</span></li>
</ul>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><strong><em><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">2.3. Quảng cáo và phí</span></em></strong></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white; mso-background-themecolor: background1;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black; mso-themecolor: text1;">Ứng dụng không chứa nội dung quảng cáo. Việc sử dụng ứng dụng làhoàn toàn miễn phí đối với người dùng.</span></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><strong><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">3. Quyền và trách nhiệm của người dùng</span></strong></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">Bạn có quyền sử dụng ứng dụng và các dịch vụ khác mà chúng tôi cung cấp, tuy nhiên việc sử dụng đó sẽ không bao gồm các hành vi sau đây nếu không có sự đồng ý bằng văn bản của chúng tôi:</span></p>
<ul type="disc">
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l3 level1 lfo5; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Sao chép, chỉnh sửa, tái tạo, tạo ra sản phẩm mới hoặc phiên bản tái sinh trên cơ sở ứng dụng này;</span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l3 level1 lfo5; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Bán, chuyển giao, cấp quyền lại, tiết lộ hoặc hình thức chuyển giao khác hoặc đưa một phần hoặc toàn bộ ứng dụng cho bên thứ ba;</span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l3 level1 lfo5; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Sử dụng ứng dụng để cung cấp dịch vụ cho bất kỳ bên thứ ba (tổ chức, cá nhân);</span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l3 level1 lfo5; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Di chuyển, xóa bỏ, thay đổi bất kỳ thông báo chính đáng hoặc dấu hiệu nào của ứng dụng (bao gồm nhưng không giới hạn các tuyên bố về bản quyền);</span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l3 level1 lfo5; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Thiết kế lại, biên dịch, tháo gỡ, chỉnh sửa, đảo lộn thiết kế của ứng dụng hoặc nội dung ứng dụng.</span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l3 level1 lfo5; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Thay đổi hoặc hủy bỏ trạng thái ban đầu của ứng dụng;</span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l3 level1 lfo5; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Sử dụng ứng dụng để thực hiện bất kỳ hành động gây hại cho hệ thống an ninh mạng của chúng tôi, bao gồm nhưng không giới hạn sử dụng dữ liệu hoặc truy cập vào máy chủ hệ thống hoặc tài khoản không được phép, truy cập vào hệ thống mạng để xóa bỏ, chỉnh sửa và thêm các thông tin, phát tán các chương trình độc hại, virus hoặc thực hiện bất kỳ hành động nào khác nhằm phá hủy hệ thống mạng;</span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l3 level1 lfo5; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Đăng nhập và sử dụng ứng dụng bằng một phần mềm tương thích của bên thứ ba hoặc hệ thống không được phát triển, cấp quyền hoặc chấp thuận của chúng tôi;</span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l3 level1 lfo5; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Sử dụng, bán, cho mượn, sao chép, chỉnh sửa, kết nối tới, phiên dịch, phát hành, công bố các thông tin liên quan đến Ứng dụng, xây dựng mirror website để công bố các thông tin này để phát triển các sản phẩm phái sinh, công việc hoặc dịch vụ;</span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l3 level1 lfo5; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Sử dụng ứng dụng để đăng tải, chuyển, truyền hoặc lưu trữ các thông tin vi phạm pháp luật, vi phạm thuần phong mỹ tục của dân tộc, phân biệt và gây chia rẽ chủng tộc, xúc phạm, làm tổn hại quyền lợi hợp pháp của các cá nhân hoặc tổ chức;</span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l3 level1 lfo5; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Sử dụng ứng dụng để lưu trữ bất kỳ nội dung vi phạm bản quyền sở hữu trí tuệ, bí mật kinh doanh hoặc quyền pháp lý của bên thứ ba;</span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l3 level1 lfo5; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Sử dụng ứng dụng hoặc các dịch vụ khác được cung cấp bởi chúng tôi trong bất kỳ hình thức vi phạm pháp luật nào, cho bất kỳ mục đích hợp pháp nào;</span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l3 level1 lfo5; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Các hình thức vi phạm khác.</span></li>
</ul>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><strong><em><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">Tài khoản</span></em></strong></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white; mso-background-themecolor: background1;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black; mso-themecolor: text1;">Để sử dụng ứng dụng, bạn phải tạo một tài khoản theo yêu cầu của chúng tôi. Bạn cam kết rằng việc sử dụng tài khoản phải tuân thủ các quy định của chúng tôi, đồng thời tất cả các thông tin Bạn cung cấp cho chúng tôi là đúng, chính xác, đầy đủ tại thời điểm được yêu cầu.</span></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white; mso-background-themecolor: background1;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black; mso-themecolor: text1;">Đối với các thông tin dưới dạng ảnh chụp, video, tệp tin âm thanh, tin nhắn do người dùng tải lên ứng dụng phải đảm bảo tính hợp pháp, không vi phạm thuần phong mỹ tục của Việt Nam. Người dùng tự chịu trách nhiệm pháp lý về tất cả các nội dung mà mình thể hiện thông qua Tài khoản trên ứng dụng (bao gồm và không giới hạn bởi các hình ảnh, ký tự, âm thanh, chữ viết ....).</span></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">Mọi quyền lợi và nghĩa vụ của bạn sẽ căn cứ trên thông tin tài khoản bạn đã đăng ký, do đó nếu có bất kỳ thông tin sai lệch nào, chúng tôi sẽ không chịu trách nhiệm trong trường hợp thông tin đó làm ảnh hưởng hoặc hạn chế quyền lợi của bạn.</span></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><strong><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">4. Quyền và trách nhiệm của CDI</span></strong></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><strong><em><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">4.1. Quyền truy cập và thu thập thông tin</span></em></strong></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">Khi sử dụng ứng dụng, bạn thừa nhận và cho phép chúng tôi có quyền sử dụng những API hệ thống sau để truy cập và dữ liệu trên điện thoại của bạn:</span></p>
<ul type="disc">
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l2 level1 lfo6; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Đọc và ghi vào danh bạ điện thoại;</span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l2 level1 lfo6; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Ghi dữ liệu của ứng dụng lên bộ nhớ điện thoại;</span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l2 level1 lfo6; tab-stops: list .5in; background: white; mso-background-themecolor: background1;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black; mso-themecolor: text1;">Truy cập vào Internet từ thiết bị của bạn. Tất cả các truy cập này đều được chúng tôi thực hiện sau khi có sự đồng ý của bạn,. Vì vậy bạn cam kết và thừa nhận rằng, khi bạn đã cấp quyền cho chúng tôi, bạn sẽ không có bất kỳ khiếu nại nào về việc truy cập này.</span></li>
</ul>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">Cùng với quyền truy cập, chúng tôi sẽ thu thập các thông tin sau của bạn:</span></p>
<ul type="disc">
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l8 level1 lfo7; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Thông tin cá nhân bao gồm các thông tin bạn cung cấp cho chúng tôi để xác nhận tài khoản như: tên, ngày tháng năm sinh, giới tính, dân tộc, số điện thoại, lĩnh vực làm việc, công việc hiện tại, tên công ty, khu công nghiệp, địa chỉ công ty, phân xưởng làm việc, vị trí làm việc, thời gian bắt đầu làm việc....</span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l8 level1 lfo7; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Thông tin chung: như các thông tin về cấu hình điện thoại của bạn, thông tin phiên bản ứng dụng mà bạn đang sử dụng cho điện thoại của mình</span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l8 level1 lfo7; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Thông tin vị trí của bạn: Dữ liệu về vị trí địa lý của Bạn sẽ được lưu trữ trên máy chủ nhằm giúp bạn sử dụng chức năng tìm kiếm của ứng dụng</span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l8 level1 lfo7; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Chúng tôi không sử dụng bất kỳ biện pháp nào để theo dõi người dùng khi sử dụng ứng dụng này hoặc thương mại hóa các thông tin đã thu thập từ ứng dụng</span></li>
</ul>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><strong><em><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">4.2. Xử lý vi phạm</span></em></strong></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white; mso-background-themecolor: background1;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black; mso-themecolor: text1;">Trường hợp bạn vi phạm bất kỳ quy định nào trong Thỏa Thuận này, chúng tôi có quyền ngay lập tức khóa tài khoản của bạn và/hoặc xóa bỏ toàn bộ thông tin, nội dung vi phạm mà không cần sự đồng ý/cho phép từ bạn. Đồng thời, tùy thuộc vào tính chất, mức độ vi phạm bạn sẽ phải chịu trách nhiệm trước cơ quan có thẩm quyền cũng như chúng tôi và các bên có liên quan về mọi thiệt hại gây ra bởi hoặc xuất phát từ hành vi vi phạm của bạn. Mọi hành vi vi phạm đều được xem là hành vi cố ý thực hiện và chúng tôi không có bất cứ nghĩa vụ về việc báo cáo, giải thích hoặc bồi thường cho các thiệt hại, ảnh hưởng phát sinh khi áp dụng các chế tài xử lý vi phạm.</span></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><strong><em><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">4.3. Cam kết bảo mật thông tin</span></em></strong></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">Ứng dụng sử dụng các phương thức truyền tin an toàn và mã hóa để truyền tải và lưu trữ các dữ liệu cá nhân và hồ sơ của bạn. Chúng tôi cam kết giữ bí mật tất cả các thông tin mà bạn cung cấp tại ứng dụng hoặc chúng tôi thu thập từ bạn và không tiết lộ với bất kỳ bên thứ ba nào trừ khi có yêu cầu từ cơ quan Nhà nước có thẩm quyền theo các trường hợp luật định.</span></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><strong><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">5. Cập nhật</span></strong></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">Điều khoản và Chính sách này có thể được cập nhật thường xuyên bởi CDI, phiên bản cập nhật sẽ được chúng tôi cung cấp tại phần Điều khoản sử dụng của ứng dụng này. Ứng dụng có thể sẽ gửi thông báo tới người dùng đối với những nội dung thay đổi quan trọng. Phiên bản cập nhật của Điều khoản và Chính sách này có hiệu lực kể từ thời điểm được cập nhật trên ứng dụng và sẽ thay thế cho các quy định và điều kiện trong thỏa thuận ban đầu. Bạn có thể truy cập vào ứng dụng để xem nội dung chi tiết của phiên bản cập nhật.</span></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;"> </span></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><strong><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">II. CHÍNH SÁCH QUYỀN RIÊNG TƯ</span></strong></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><strong><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">1.</span></strong><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;"> <strong>Loại dữ liệu cá nhân được thu thập</strong></span></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">Tùy vào từng thời điểm, các thông tin được thu thập sẽ bao gồm nhưng không giới hạn ở:</span></p>
<ul type="disc">
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l5 level1 lfo8; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Những thông tin cá nhân của bạn bao gồm: Ảnh đại diện, Tên tài khoản, Ngày, tháng năm sinh, Giới tính, Dân tộc, Lĩnh vực làm việc, Công việc hiện tại và Số điện thoại.</span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l5 level1 lfo8; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Những thông tin nơi làm việc của bạn bao gồm: Tên công ty, Khu công nghiệp, Tỉnh/Thành phố, Quận/Huyện, Phường/Xã/Thị trấn, Phân xưởng làm việc, Vị trí làm việc, Thời gian bắt đầu làm việc.</span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l5 level1 lfo8; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Các thông tin khác phục vụ cho việc sử dụng các tính năng trong Ứng dụng áp dụng cho cả những người dùng không đăng ký tài khoản trên Ứng dụng.</span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l5 level1 lfo8; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Các thông tin khác (nếu có) sẽ được thông báo công khai sau mỗi lần Ứng dụng được cập nhật.</span></li>
</ul>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><strong><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">2. Mục đích thu thập dữ liệu</span></strong></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">Các thông tin của bạn được chúng tôi thu thập nhằm các mục đích sau:</span></p>
<ul type="disc">
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l9 level1 lfo9; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Liên hệ xác nhận khi bạn đăng ký tài khoản, quên mật khẩu, sử dụng các tính năng khác của ứng dụng hoặc trong các trường hợp đặc biệt</span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l9 level1 lfo9; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Thông báo cho bạn về những thay đổi của ứng dụng</span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l9 level1 lfo9; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Bảo đảm quyền lợi của người dùng khi phát hiện các hành động giả mạo, phá hoại tài khoản, lừa đảo.</span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l9 level1 lfo9; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Nhằm đảm bảo sự an toàn và an ninh bao gồm việc xem xét nội dung các dữ liệu tải lên có phù hợp với nội dung Điều khoản và Chính sách của Ứng dụng hay có các nội dung khác không phù hợp hay không.</span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l9 level1 lfo9; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Nhằm thực thi các điều khoản, điều kiện và chính sách của chúng tôi và quản lý ứng dụng bao gồm việc khắc phục sự cố.</span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l9 level1 lfo9; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Các thông tin dữ liệu của người dùng dưới dạng đã được mã hóa (đảm bảo không còn nhận dạng được chủ thể dữ liệu) được sử dụng làm cơ sở dữ liệu thực hiện các nghiên cứu, báo cáo liên quan đến Điều kiện làm việc với phạm vi nhà máy/ngành điện tử và may mặc.</span></li>
</ul>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><strong><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">3. Quy trình thu thập, cập nhật, lưu trữ và xóa dữ liệu cá nhân</span></strong></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><strong><em><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">3.1. Chúng tôi sẽ/có thể sẽ thu thập thông tin dữ liệu cá nhân của Bạn khi:</span></em></strong></p>
<ul type="disc">
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l4 level1 lfo10; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Bạn đồng ý với bất kỳ thỏa thuận nào hoặc cung cấp cho chúng tôi dữ liệu hoặc thông tin liên quan đến tương tác giữa Bạn với chúng tôi hoặc khi Bạn sử dụng Ứng dụng này.</span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l4 level1 lfo10; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Bạn cung cấp cho chúng tôi thông tin phản hồi, góp ý, khảo sát hoặc khiếu nại về dịch vụ của Ứng dụng</span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l4 level1 lfo10; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Bạn tự nguyện khai báo để đăng ký tài khoản trên Ứng dụng và đăng tải nội dung lên Ứng dụng.</span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l4 level1 lfo10; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Chúng tôi cũng có thể tự động thu thập thông tin của Bạn khi Bạn sử dụng các tính năng của Ứng dụng.</span></li>
<li class="MsoNormal" style="color: black; mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; text-align: justify; line-height: normal; mso-list: l4 level1 lfo10; tab-stops: list .5in; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman';">Với việc sử dụng và tiếp tục sử dụng Ứng dụng, Bạn thừa nhận đã biết về các khả năng thu thập Dữ liệu cá nhân và đồng ý các Dữ liệu cá nhân của mình có thể được thu thập theo bất cứ cách nào hợp pháp được đề cập trong bản Điều khoản và Chính sách này.</span></li>
</ul>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><strong><em><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">3.2. Cập nhật dữ liệu</span></em></strong></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">Bạn có thể tự chỉnh sửa, cập nhật các thông tin cá nhân của mình bất cứ khi nào Bạn muốn.</span></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><strong><em><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">3.3. Lưu trữ và xóa dữ liệu </span></em></strong></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">Các dữ liệu được thu thập hợp pháp sẽ được lưu trữ và bảo mật trên máy chủ của Ứng dụng We Check. Các dữ liệu này sẽ được lưu trữ đến khi có yêu cầu hủy bỏ từ phía Bạn.</span></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><strong><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">4. Phạm vi sử dụng dữ liệu</span></strong></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">Ứng dụng chỉ sử dụng thông tin của Bạn cho các mục đích nêu tại mục II.2 hoặc mục đích khác (nếu có) sau khi đã thông báo và nhận được sự đồng ý của Bạn;</span></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">Ứng dụng cam kết không sử dụng thông tin cá nhân của Bạn cho các mục đích thương mại hay các mục đích khác khi chưa được Bạn cho phép;</span></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><strong><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">5. Trách nhiệm bảo mật thông tin</span></strong></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">Mọi dữ liệu của Bạn sẽ được chúng tôi bảo mật tối đa và không được tiết lộ cho bên thứ ba nào nếu chưa có sự đồng ý của Bạn. Nếu có bên thứ ba nào có được thông tin của Bạn, họ cũng sẽ phải đảm bảo tuân thủ các cam kết về bảo mật thông tin của Bạn.</span></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">Bạn hiểu và đồng ý rằng, trong một số trường hợp dữ liệu sẽ được tiết lộ mà không cần sự đồng ý của chủ thể dữ liệu. Dưới đây là các trường hợp CDI được miễn trừ trách nhiệm pháp lý khi tiết lộ thông tin của Bạn:</span></p>
<p class="MsoListParagraphCxSpFirst" style="margin-bottom: 7.5pt; mso-add-space: auto; text-align: justify; text-indent: -.25in; line-height: normal; mso-list: l0 level1 lfo2; background: white; mso-background-themecolor: background1;"><!-- [if !supportLists]--><span style="font-size: 12.0pt; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;"><span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">         </span></span></span><!--[endif]--><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black; mso-themecolor: text1;">Trong trường hợp khẩn cấp, vì lý do an ninh quốc phòng, trật tự an toàn xã hội, sức khỏe cộng đồng;</span></p>
<p class="MsoListParagraphCxSpMiddle" style="margin-bottom: 7.5pt; mso-add-space: auto; text-align: justify; text-indent: -.25in; line-height: normal; mso-list: l0 level1 lfo2; background: white; mso-background-themecolor: background1;"><!-- [if !supportLists]--><span style="font-size: 12.0pt; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;"><span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">         </span></span></span><!--[endif]--><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black; mso-themecolor: text1;">Khi có sự yêu cầu của cơ quan có thẩm quyền theo quy định của pháp luật hiện hành;</span></p>
<p class="MsoListParagraphCxSpLast" style="margin-bottom: 7.5pt; mso-add-space: auto; text-align: justify; text-indent: -.25in; line-height: normal; mso-list: l0 level1 lfo2; background: white; mso-background-themecolor: background1;"><!-- [if !supportLists]--><span style="font-size: 12.0pt; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;"><span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">         </span></span></span><!--[endif]--><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black; mso-themecolor: text1;">Các trường hợp tiết lộ hợp pháp khác theo quy định pháp luật Việt Nam.</span></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><strong><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">6. Các biện pháp kĩ thuật cần thiết đảm bảo an toàn dữ liệu cá nhân </span></strong></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">CDI đảm bảo việc thực hiện các biện pháp kỹ thuật cần thiết nhằm đảm bảo an toàn thông tin cho người dùng.</span></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">Trường hợp hệ thống thông tin, dữ liệu và quyền kiểm soát đối với Ứng dụng bị xâm nhập, thay đổi trái phép, chúng tôi có nghĩa vụ thực hiện tất cả các biện pháp tốt nhất để khôi phục sự kiểm soát an toàn của hệ thống và các dữ liệu đã thu thập.</span></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">Trong trường hợp phát sinh sự cố quy định tại điểm này, chúng tôi được miễn trừ tất cả các trách nhiệm pháp lý khi đã áp dụng các biện pháp kỹ thuật cần thiết để bảo vệ hệ thống và thông tin, dữ liệu cá nhân của người dùng.</span></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><strong><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">7. Cơ chế khiếu nại, phản hồi</span></strong></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white; mso-background-themecolor: background1;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black; mso-themecolor: text1;">Bất kỳ khiếu nại nào phát sinh trong quá trình sử dụng Ứng dụng phải được gửi về CDI ngay sau khi xảy ra sự kiện phát sinh khiếu nại. CDI sẽ giải quyết tranh chấp của Bạn theo quy định của pháp luật hiện hành của nước Cộng Hòa Xã Hội Chủ Nghĩa Việt Nam. CDI sẽ căn cứ từng trường hợp cụ thể để có phương án giải quyết cho phù hợp. Khi thực hiện quyền khiếu nại, người khiếu nại có nghĩa vụ cung cấp các giấy tờ, bằng chứng, căn cứ có liên quan đến việc khiếu nại và phải chịu trách nhiệm về nội dung khiếu nại, giấy tờ, bằng chứng, căn cứ do mình cung cấp theo quy định Pháp luật.</span></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">CDI chỉ hỗ trợ, giải quyết khiếu nại, tố cáo của Bạn trong trường hợp Bạn cung cấp đầy đủ, trung thực và chính xác thông tin.</span></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black; mso-themecolor: text1;">Bạn đồng ý bảo vệ, bồi hoàn và loại trừ CDI khỏi những nghĩa vụ pháp lý, tố tụng, tổn thất, chi phí bao gồm nhưng không giới hạn án phí, chi phí luật sư, chuyên gia tư vấn có liên quan đến việc giải quyết hoặc phát sinh từ sự vi phạm của Bạn trong quá trình sử dụng Ứng dụng WE CHECK.</span></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><strong><em><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">Các hình thức và địa chỉ tiếp nhận khiếu nại:</span></em></strong></p>
<p class="MsoListParagraphCxSpFirst" style="margin-bottom: 7.5pt; mso-add-space: auto; text-align: justify; text-indent: -.25in; line-height: normal; mso-list: l7 level1 lfo1; background: white; mso-background-themecolor: background1;"><!-- [if !supportLists]--><span style="font-size: 12.0pt; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;"><span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">         </span></span></span><!--[endif]--><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black; mso-themecolor: text1;">Gọi điện thoại hoặc gửi email trực tiếp cho các cán bộ của CDI để phản ánh.</span></p>
<p class="MsoListParagraphCxSpMiddle" style="margin-bottom: 7.5pt; mso-add-space: auto; text-align: justify; text-indent: -.25in; line-height: normal; mso-list: l7 level1 lfo1; background: white; mso-background-themecolor: background1;"><!-- [if !supportLists]--><span style="font-size: 12.0pt; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;"><span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">         </span></span></span><!--[endif]--><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black; mso-themecolor: text1;">Gọi điện trực tiếp theo số điện thoại: 024 3538 0100</span></p>
<p class="MsoListParagraphCxSpMiddle" style="margin-bottom: 7.5pt; mso-add-space: auto; text-align: justify; text-indent: -.25in; line-height: normal; mso-list: l7 level1 lfo1; background: white; mso-background-themecolor: background1;"><!-- [if !supportLists]--><span style="font-size: 12.0pt; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;"><span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">         </span></span></span><!--[endif]--><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black; mso-themecolor: text1;">Gửi email theo địa chỉ: </span><a href="mailto:phanhoi@cdivietnam.org"><em><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: #0181b9; text-decoration: none; text-underline: none;">phanhoi@cdivietnam.org</span></em></a></p>
<p class="MsoListParagraphCxSpMiddle" style="margin-bottom: 7.5pt; mso-add-space: auto; text-align: justify; text-indent: -.25in; line-height: normal; mso-list: l7 level1 lfo1; background: white; mso-background-themecolor: background1;"><!-- [if !supportLists]--><span style="font-size: 12.0pt; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;"><span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">         </span></span></span><!--[endif]--><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black; mso-themecolor: text1;">Nhắn tin qua Fan page: </span><a href="http://www.facebook.com/cdivietnam.ngo"><em><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: #0181b9; text-decoration: none; text-underline: none;">http://www.facebook.com/cdivietnam.ngo</span></em></a><em><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black; mso-themecolor: text1;"> </span></em></p>
<p class="MsoListParagraphCxSpLast" style="margin-bottom: 7.5pt; mso-add-space: auto; text-align: justify; text-indent: -.25in; line-height: normal; mso-list: l7 level1 lfo1; background: white; mso-background-themecolor: background1;"><!-- [if !supportLists]--><span style="font-size: 12.0pt; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;"><span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">         </span></span></span><!--[endif]--><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black; mso-themecolor: text1;">Gửi thư trực tiếp cho tổ chức theo địa chỉ: Văn phòng Trung tâm Phát triển và Hội nhập, tầng 16 tòa nhà 169 Nguyễn Ngọc Vũ, phường Trung Hòa, quận Cầu Giấy, Hà Nội.</span></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><strong><em><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">Quy trình giải quyết tranh chấp và khiếu nại:</span></em></strong></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">Bước 1: Tiếp nhận: Nhận yêu cầu giải quyết khiếu nại của người dùng (gọi là “người khiếu nại”), kiểm tra thông tin khiếu nại để thực hiện tiếp nhận hay từ chối.</span></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">Bước 2: Thông báo giải quyết</span></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">Bước 3: Xử lý khiếu nại: Thu thập thông tin, hình ảnh, chứng cứ liên quan để tiến hành giải quyết lần 1 và thông báo kết quả giải quyết lần 1</span></p>
<p class="MsoNormal" style="margin-bottom: 7.5pt; text-align: justify; line-height: normal; background: white;"><span style="font-size: 12.0pt; font-family: 'Times New Roman',serif; mso-fareast-font-family: 'Times New Roman'; color: black;">Bước 4: Hoàn tất khiếu nại: Xác nhận kết quả, đưa ra các hình thức xử lý đối với từng vi phạm và tiến hành đóng khiếu nại.</span></p>
<div style="mso-element: comment-list;"><!-- [if !supportAnnotations]-->
<div style="mso-element: comment;">
<div id="_com_1" class="msocomtxt"><span style="mso-comment-author: 'Trang Ngo Thi'; mso-comment-providerid: AD; mso-comment-userid: 'S::trang\.ngothi\@cdivietnam\.org::d53cbe04-f9e7-46de-b8dd-cb039238c4b5';"><br></span><span style="mso-special-character: comment;"><!--[endif]--></span><!-- [if !supportAnnotations]--></div>
<!--[endif]--></div>
</div>
<div style="mso-element: comment-list;">
<div style="mso-element: comment;">
<div id="_com_1" class="msocomtxt"><!-- [if !supportAnnotations]--></div>
<!--[endif]--></div>
</div>
<div style="mso-element: comment-list;">
<div style="mso-element: comment;">
<div id="_com_1" class="msocomtxt"><!-- [if !supportAnnotations]--></div>
<!--[endif]--></div>
</div>`
    }} />
  )
}
