import { Button, DatePicker, Form, Input, Modal, Select, Upload } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { disableDateAfterIncludeToday } from 'src/common/constant';
import { formLayout } from 'src/pages/constants';
import { useGetAdmin } from 'src/hooks/useGetAdmins';

const { Option } = Select;
export const ModalContent = ({ visibleModalImport, onCancelImport, onCreateContent, message }) => {
  const [form] = Form.useForm();
  const [fileUploadList, setFileUpload] = useState<any[]>([]);
  const [sender, setSender] = useState<any>('');
  const { adminOptions } = useGetAdmin();
  const onOK = () => {
    let input = {
      messageText: form.getFieldValue('messageText') || '',
      senderId:
        form.getFieldValue('sender') === 'root'
          ? form.getFieldValue('senderId')
          : form.getFieldValue('sender') === 'user' && message[0]?.userId,
      messageFile: fileUploadList,
      createdAt: moment(form.getFieldValue('createdAt')).format('DD/MM/YYYY HH:mm:ss'),
      type: 5,
    };

    console.log(input);

    onCreateContent(input);
  };

  const onChangeUser = (value: any) => {
    setSender(value);
  };

  const onUploadFile = (data: any) => {
    console.log(data);
    let fileList = [...data?.fileList];
    fileList = fileList.slice(-1);
    setFileUpload([...fileList]);
  };

  const onRefreshFilter = () => {
    form.resetFields();
    setFileUpload([]);
  };

  return (
    <Modal
      okText="Lưu"
      title="Tạo nội dung"
      cancelText="Hủy"
      visible={visibleModalImport}
      centered
      onCancel={onCancelImport}
      onOk={onOK}
      afterClose={onRefreshFilter}
    >
      <div className="modal-des">
        <Form {...formLayout} form={form} labelAlign="left">
          <Form.Item name="sender" label="Người gửi">
            <Select onChange={onChangeUser} placeholder="Chọn người gửi">
              <Option value="user">Người gửi</Option>
              <Option value="root">Quản trị viên</Option>
            </Select>
          </Form.Item>
          {form.getFieldValue('sender') === 'root' && (
            <Form.Item style={{ marginLeft: '33%', marginRight: '-33%' }} name="senderId">
              <Select
                options={adminOptions}
                // onChange={onChangeUser}
                placeholder="Chọn quản trị viên"
              ></Select>
            </Form.Item>
          )}
          <Form.Item name="createdAt" label="Thời gian">
            <DatePicker
              style={{ width: '100%' }}
              showTime
              format={'DD/MM/YYYY HH:mm:ss'}
              disabledDate={disableDateAfterIncludeToday}
              placeholder="Chọn thời gian"
            />
          </Form.Item>
          <Form.Item name="messageText" label="Nội dung">
            <Input.TextArea placeholder="Nhập nội dung" />
          </Form.Item>
          <Form.Item name="messageFile" label="File đính kèm">
            <Upload
              accept=".doc ,.dot,.docx ,.dotx ,.docm ,.dotm  ,.xls,.xlt,.xla,.xlsx,.xltx,.xlsm,.xltm,.xlam ,.xlsb,.ppt ,.pot,.pps,
              .ppa ,.pptx ,.potx,.ppsx,.ppam,.pptm,.potm,.ppsm,.mdb,image/*, .pdf"
              multiple={false}
              onChange={onUploadFile}
              fileList={fileUploadList}
            >
              <Button>Tải file</Button>
            </Upload>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};
