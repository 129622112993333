import { useQuery } from '@tanstack/react-query';
import { AUTH } from 'src/helpers/api';
import axiosIns from 'src/helpers/request';

const getCurrentUser = async () => {
  try {
    const res = await axiosIns.get(`${AUTH.CURRENT_USER}`);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
  }
};

export const useCurrentUser = () => {
  const { data } = useQuery(['currentUser'], () => getCurrentUser(), { refetchOnWindowFocus: false });

  return {
    currentUser: data,
    isSupperAdmin: data?.role === 'root',
  };
};
