import React from 'react';

type State = {
  hasError: boolean;
};

type Props = {
  fallback?: React.ReactNode;
  children?: React.ReactNode;
};

export default class ErrorBoundary extends React.Component<Props, State> {
  state = { hasError: false };

  static defaultProps: Props = {
    fallback: <h1>Something went wrong. F5 to fix it!</h1>,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { fallback, children } = this.props;
    if (this.state.hasError) {
      return fallback;
    }

    return children;
  }
}
