export const periodSelects = [
  {
    label: 'Tất cả',
    value: -1,
  },
  {
    label: 'Mỗi lần',
    value: 1,
  },
  {
    label: 'Lần đầu',
    value: 2,
  },
  {
    label: 'Hàng ngày',
    value: 3,
  },
  {
    label: 'Hàng tuần',
    value: 4,
  },
  {
    label: 'Hàng tháng',
    value: 5,
  },
  {
    label: 'Mỗi 6 tháng',
    value: 7,
  },
];

export const filterOption = (input, option) =>
  (option?.label as unknown as string)?.toLowerCase()?.includes(input.toLowerCase());

export const actionSelects = [
  {
    label: 'Tất cả',
    value: -1,
  },
  {
    label: 'Đăng ký tài khoản',
    value: 0,
  },
  {
    label: 'Cập nhật thông tin tài khoản',
    value: 1,
  },
  {
    label: 'Tiến hành khảo sát nhanh',
    value: 2,
  },
  {
    label: 'Tiến hành khảo sát SKNN',
    value: 3,
  },
  {
    label: 'Tiến hành khảo sát ĐKLV',
    value: 4,
  },
  {
    label: 'Gửi yêu cầu tư vấn',
    value: 5,
  },
  {
    label: 'Gửi yêu cầu khiếu nại',
    value: 6,
  },
  {
    label: 'Đọc bài "Phát triển bản thân"',
    value: 7,
  },
  {
    label: 'Lần đầu cập nhật thông tin cá nhân',
    value: 8,
  },
  {
    label: 'Giới thiệu ứng dụng đến bạn bè',
    value: 9,
  },
];

export const statusSelects = [
  {
    label: 'Tất cả',
    value: -1,
  },
  {
    label: 'Đang hoạt động',
    value: 1,
  },
  {
    label: 'Không hoạt động',
    value: 0,
  },
];
