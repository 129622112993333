import { Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { SideBar } from '../SideBar';
import { Header } from 'src/layouts/Header';
import { Outlet, useNavigate } from 'react-router-dom';
import { AppRoutes } from 'src/helpers/app.routes';
import classNames from 'classnames';
import { useMessageState } from 'src/hooks/useMessageState';
import { initSocket, socketClient } from 'src/components/Socket';

interface Props {
  allowModules?: any;
  currentUser?: any;
}

const MainLayout = ({ allowModules, currentUser }: Props) => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState<boolean>(false);

  useEffect(() => {
    if (!localStorage.getItem('accessToken')) {
      navigate(AppRoutes.login);
    }
  }, []);

  const { onAddDataMessage, onSetLastListViewer } = useMessageState()
  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      const getToken = () => {
        // await getAccessToken();
        initSocket('Bearer ' + localStorage.getItem('accessToken'));
        if (socketClient) {
          socketClient.on('create-conversation', (conversationId: any) => {
            socketClient?.emit('join-conversation', conversationId);
            // handleEnterChat(conversationId);
          });
          socketClient.on('user-last-view', (lastView: any) => {
            console.log('lastView', lastView);
            onSetLastListViewer(lastView);
          });

          socketClient.on('new-mess', (conversationId: any, message: any) => {
            console.log('mess', message);

            onAddDataMessage(message, conversationId);
          });

          socketClient.on('delete-mess', (conversationId: any, message: any) => {
            console.log('delete-mess', message);

          });

        }
      };
      getToken();
    }
  }, []);


  return (
    <Layout>
      <SideBar
        allowModules={allowModules}
        setCollapsed={setCollapsed}
        collapsed={collapsed}
        currentUser={currentUser}
      />
      <Layout>
        <div className={classNames(styles.section, { [styles.collapsed]: collapsed, [styles.expanded]: !collapsed })}>
          <Header />
          <div className={styles.content}>
            <Outlet context={allowModules} />
          </div>
        </div>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
