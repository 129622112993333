export const STATUS_OPTIONS = [
  { label: 'Tất cả', value: '' },
  { label: 'Mới', value: 5 },
  { label: 'Đang xác nhận', value: 6 },
  { label: 'Chờ xử lý', value: 1 },
  { label: 'Từ chối', value: 8 },
];

export const ADVICE_TYPE_OPTIONS = [
  { label: 'Tất cả', value: '' },
  { label: 'Pháp luật lao động', value: 1 },
  { label: 'Sức khoẻ nghề nghiệp', value: 2 },
  { label: 'Phản hồi về ứng dụng', value: 3 },
];

export const LABOR_LAW_OPTIONS = [
  { label: 'Hợp đồng lao động', value: '' },
  { label: 'Tiền lương và chế độ trợ cấp', value: 'user' },
  { label: 'Thời giờ làm việc,thời giờ nghỉ ngơi', value: 'admin' },
  { label: 'An toàn ,vệ sinh lao động', value: 'admin' },
  { label: 'Đối thoại tại nơi làm việc', value: 'admin' },
  { label: 'Bảo hiểm xã hội,Bảo hiểm y tế,Bảo hiểm thất nghiệp', value: 'admin' },
  { label: 'Tranh chấp lao động', value: 'admin' },
  { label: 'Chính sách dành cho lao động nữ', value: 'admin' },
  { label: 'Chủ đề khác', value: 'admin' },
];
export const STRENGTH_OPTIONS = [
  { label: 'Da liễu', value: '' },
  { label: 'Tai - Mũi - Họng', value: 'user' },
  { label: 'Mắt', value: 'admin' },
  { label: 'Hô hấp', value: 'admin' },
  { label: 'Cơ - Xương khớp', value: 'admin' },
  { label: 'Ung thư nghề nghiệp', value: 'admin' },
  { label: 'Bệnh khác', value: 'admin' },
];

export const TOPIC_OPTIONS = [
  { label: 'Tất cả', value: '' },
  { label: 'Người dùng app', value: 'user' },
  { label: 'Quản trị viên', value: 'admin' },
];

export const SOURCE_OPTIONS = [
  { label: 'Tất cả', value: '' },
  { label: 'App WECHECK', value: 1 },
  { label: 'Facebook', value: 2 },
  { label: 'Zalo', value: 3 },
  { label: 'Hotline', value: 4 },
  { label: 'Trực tiếp', value: 5 },
  { label: 'Đơn/thư', value: 6 },
  { label: 'Khác', value: 7 },
];

export const GENDER_OPTIONS = [
  { label: 'Tất cả', value: '' },
  { label: 'Nam', value: 0 },
  { label: 'Nữ', value: 1 },
  { label: 'Khác', value: 3 },
];
