import { useSelector } from 'react-redux';
import { addDataMessage, addMessage, setListLastViewer, updateCurrentConversationId } from 'src/features/message/message';
import type { IMessage } from 'src/models/chat';
import { useAppDispatch } from 'src/stores/hook';
import { appDataLastView, appDataMessages } from 'src/features/message/selectors';


export const useMessageState = () => {
  const dispatch = useAppDispatch();
  const dataMessage = useSelector(appDataMessages);
  const dataLastView = useSelector(appDataLastView);

  const onAddDataMessage = (message: IMessage, conversationId: number) => {
    dispatch(addMessage({ message, conversationId }));
  };

  const onAddInitDataMessage = (data: any) => {
    dispatch(addDataMessage(data));
  };

  const onSetLastListViewer = async (data: any) => {
    await dispatch(setListLastViewer(data));
  };

  const updateConversationId = async (conversationId: any) => {
    await dispatch(updateCurrentConversationId(conversationId));
  };

  return {
    dataMessage,
    dataLastView,
    onAddDataMessage,
    onAddInitDataMessage,
    onSetLastListViewer,
    updateConversationId
  };
};

// export const addNewMessage = (message: IMessage) => {
//   store.dispatch(addMessage(message));
// };

// export const addQueryDataMessage = (data: any) => {
//   store.dispatch(addDataMessage(data));
// };
