export const STATUS_OPTIONS = [
  { label: 'Tất cả', value: '' },
  { label: 'Chờ xử lý', value: 1 },
  { label: 'Đang tiến hành', value: 2 },
  { label: 'Tạm dừng', value: 3 },
  { label: 'Đã hoàn thành', value: 4 },
  { label: 'Đã đóng', value: 9 },
];

export const ALL_STATUS_OPTIONS = [
  { label: 'Tất cả', value: '' },
  { label: 'Chờ xử lý', value: 1 },
  { label: 'Đang tiến hành', value: 2 },
  { label: 'Tạm dừng', value: 3 },
  { label: 'Đã hoàn thành', value: 4 },
  { label: 'Mới', value: 5 },
  { label: 'Đang xác nhận', value: 6 },
  { label: 'Chưa thực hiện', value: 7 },
  { label: 'Từ chối', value: 8 },
  { label: 'Đã đóng', value: 9 },
];
export const TICK_OPTIONS = [
  { label: 'Khiếu nại', value: 1 },
  { label: 'Kiến nghị', value: 2 },
  { label: 'Phản ánh', value: 3 },
  { label: 'Góp ý', value: 4 },
  { label: 'Tư vấn', value: 5 },
  { label: 'Khác', value: 6 },
];

export const STAGE_OPTIONS = [
  { label: 'Tất cả', value: '' },
  { label: 'Giai đoạn 1-2', value: 1 },
  { label: 'Giai đoạn 3', value: 3 },
  { label: 'Giai đoạn 4', value: 4 },
  { label: 'Giai đoạn 5', value: 5 },
  { label: 'Giai đoạn 6', value: 6 },
];

export const MARKING = {
  1: 'Khiếu nại',
  2: 'Kiến nghị',
  3: 'Phản ánh',
  4: 'Góp ý',
  5: 'Tư vấn',
  6: 'Khác',
};
