import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { combineUrlParams } from 'src/common/constant';
import { PageHeader } from 'src/components/PageHeader';
import { CHECKLIST_API } from 'src/helpers/api';
import { ConsultationsRoutes } from 'src/helpers/app.routes';
import axiosIns from 'src/helpers/request';
import AdviceManagement from './components/ComplaintManagement';
import FileManagement from './components/FileManagement';
import { InformationAdvice } from './components/InformationAdvice';
import styles from './styles.module.scss';

const DetailAdviceRequest: React.FC = () => {
  const [checkList, setCheckList] = useState<any>();

  function fetchCheckList(params = {}) {
    return new Promise((resolve, reject) => {
      axiosIns
        .get(
          combineUrlParams(`${CHECKLIST_API.GET_ALL}`, {
            ...params,
          })
        )
        .then((s) => {
          setCheckList({ dsCheckList: s?.data?.data?.checkList });
          resolve(s?.data?.data?.checkList);
        })
        .catch((e) => {
          reject(e);
        });
    });
    // .then
  }


  useEffect(() => {
    fetchCheckList();
  }, []);

  return (
    <>
      <PageHeader
        routes={[
          { path: '/', breadcrumbName: '' },
          { path: '', breadcrumbName: 'Tư vấn và khiêu nại' },
          { path: ConsultationsRoutes.requestCounsellingList, breadcrumbName: 'Yêu cầu tư vấn' },
          { path: '', breadcrumbName: 'Chi tiết yêu cầu' },
        ]}
      />
      <div className={styles.wrapper}>

          <Tabs>
            <Tabs.TabPane tab="Thông tin tư vấn" key="information-advice">
              <InformationAdvice />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Quản lý tư vấn" key="advice-management">
              <AdviceManagement
                dsCheckList={checkList?.dsCheckList}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Quản lý file" key="file-management">
              <FileManagement />
            </Tabs.TabPane>
          </Tabs>

      </div>
    </>
  );
};

export default DetailAdviceRequest;
