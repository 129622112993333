import { Button, Dropdown } from 'antd';
import React, { useState } from 'react';
import { ArrowDownIcon, AvatarIcon, LogOutIcon } from 'src/components/Icon';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'src/helpers/app.routes';
import axiosIns from 'src/helpers/request';
import { AUTH } from 'src/helpers/api';

export const ProfileUser: React.FC = () => {
  const [hovered, setHovered] = useState(false);
  const userDataString: any = localStorage.getItem('userInfor');
  const userData = JSON.parse(userDataString);
  const navigate = useNavigate();

  function handleLogout() {
    return new Promise((resolve, reject) => {
      axiosIns
        .post(AUTH.LOGOUT)
        .then((s) => {
          resolve(s);
          localStorage.clear();
        })
        .catch((e) => {
          localStorage.clear();
        });
      navigate(AppRoutes.login);
    });
  }

  return (
    <Dropdown
      visible={hovered}
      onVisibleChange={setHovered}
      placement="bottomRight"
      trigger={['click']}
      className={styles.wrapInfo}
      overlay={
        <div className={styles.dropDown}>
          <LogOutIcon />
          <span className={styles.textModal}>
            <Button onClick={handleLogout}>Đăng xuất</Button>
          </span>
        </div>
      }
    >
      <div>
        <AvatarIcon />
        <div className={styles.content}>
          <span className={styles.textGrey}>Xin chào</span>
          <p className={styles.textProfileName}>{userData?.username}</p>
        </div>
        <div
          className={classNames(styles.arrowIcon, {
            [styles.active]: hovered,
          })}
        >
          <ArrowDownIcon />
        </div>
      </div>
    </Dropdown>
  );
};
