import { Badge, Layout, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { WeCheckLogoIcon } from 'src/components/Icon';
import { COMPANY_API, CREATE_NEW_CONVERSATION } from 'src/helpers/api';
import { AppRoutes } from 'src/helpers/app.routes';
import axiosIns from 'src/helpers/request';
import { menus } from '../constants';
import type { IMenuItem, MenuItem } from '../types';
import styles from './styles.module.scss';

interface Props {
  allowModules?: any;
  currentUser?: any;
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const { Sider } = Layout;

export const SideBar: React.FC<Props> = ({ allowModules, currentUser, collapsed, setCollapsed }) => {
  const [number, setNumber] = useState(0);
  const [numberCOns, setNumberCons] = useState<any>({});
  const [numberPLYC, setNumberPLYC] = useState<any>(0);
  const [numberPHUD, setNumberPHUD] = useState<any>(0);

  function fetchNumber() {
    return new Promise((resolve, reject) => {
      axiosIns
        .get(`${COMPANY_API.NUMBER_PENDING_REQUEST}`)
        .then((s) => {
          setNumber(s?.data?.data);
          resolve(s?.data?.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
    // .then
  }

  function fetchNumberConsvervation() {
    return new Promise((resolve, reject) => {
      axiosIns
        .get(`${CREATE_NEW_CONVERSATION.GET_NUMBER_NEW_CONSVERVATION}`)
        .then((s) => {
          setNumberCons(s?.data?.data);
          const newRes = s?.data?.data?.numberNewRequest;
          const res = s?.data?.data?.numberRequest;
          const newFeed = s?.data?.data?.numberNewFeedback;
          const appFeed = s?.data?.data?.numberAppFeedback;
          setNumberPLYC(newRes + res);
          setNumberPHUD(newFeed + appFeed);
          resolve(s?.data?.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
    // .then
  }

  useEffect(() => {
    fetchNumber();
    fetchNumberConsvervation();
  }, []);

  function getMenuItems(menus: IMenuItem[]) {
    return menus
      .filter((item) => Object.keys(currentUser)?.some((it) => item.key?.includes(it)))
      .map((menu: IMenuItem) => {
        if (menu.submenu && menu.submenuItems) {
          return getItem(
            <>
              {menu.title === 'Quản lý Công ty' ? (
                <>
                  {menu.title}
                  <Badge style={{ marginLeft: '5px' }} count={number} />
                </>
              ) : menu.title === 'Tư vấn khiếu nại' ? (
                <>
                  {menu.title}
                  <Badge style={{ marginLeft: '5px' }} count={numberCOns?.total} />
                </>
              ) : (
                menu.title
              )}
            </>,
            // menu.title,
            menu.key,
            menu.icon,
            menu.submenuItems
              .filter((subItem) => Object.keys(currentUser)?.includes(subItem.key))
              .map((subMenuItem) =>
                getItem(
                  <NavLink className={styles.bold} to={subMenuItem.to} key={subMenuItem.key}>
                    {subMenuItem.title === 'Yêu cầu tạo mới' ? (
                      <>
                        {subMenuItem.title}
                        <Badge style={{ marginLeft: '5px' }} count={number} />
                      </>
                    ) : subMenuItem.title === 'Yêu cầu tư vấn' ? (
                      <>
                        {subMenuItem.title}
                        <Badge style={{ marginLeft: '5px' }} count={numberCOns?.numberAdvise} />
                      </>
                    ) : subMenuItem.title === 'Yêu cầu khiếu nại' ? (
                      <>
                        {subMenuItem.title}
                        <Badge style={{ marginLeft: '5px' }} count={numberCOns?.numberComplaint} />
                      </>
                    ) : subMenuItem.title === 'Phân loại yêu cầu' ? (
                      <>
                        {subMenuItem.title}
                        <Badge style={{ marginLeft: '5px' }} count={numberPLYC} />
                      </>
                    ) : subMenuItem.title === 'Phản hồi về ứng dụng' ? (
                      <>
                        {subMenuItem.title}
                        <Badge style={{ marginLeft: '5px' }} count={numberPHUD} />
                      </>
                    ) : (
                      subMenuItem.title
                    )}
                    {/* {subMenuItem.title === 'Yêu cầu tư vấn' ? (
                      <>
                        {subMenuItem.title}
                        <Badge style={{ marginLeft: '5px' }} count={numberCOns?.numberAdvise} />
                      </>
                    ) : (
                      subMenuItem.title
                    )} */}
                  </NavLink>,
                  subMenuItem.key,
                  subMenuItem.icon
                )
              )
          );
        }

        return getItem(
          <NavLink className={styles.bold} to={menu.to} key={menu.key}>
            {menu.title}
          </NavLink>,
          menu.key,
          menu.icon
        );
      });
  }

  const items: MenuItem[] = getMenuItems(menus);

  return (
    <Sider
      collapsible
      onCollapse={(value) => setCollapsed(value)}
      collapsed={collapsed}
      width={255}
      className={styles.slider}
    >
      <NavLink to={AppRoutes.home} className={styles.logo}>
        <div className={styles.logoWrapper}>
          <WeCheckLogoIcon />
        </div>
      </NavLink>
      <div className={styles.sidebar}>
        <Menu mode="inline" style={{ width: collapsed ? 80 : 255 }} items={items} />
      </div>
    </Sider>
  );
};
