import { useQuery } from '@tanstack/react-query';
import { SYSTEM_API } from 'src/helpers/api';
import axiosIns from 'src/helpers/request';

const getAdmin = async () => {
  const res = await axiosIns.get(SYSTEM_API.list, { params: { limit: 9999, offset: 1, status: 'active' } });
  return res?.data?.data;
};

export const useGetAdmin = () => {
  const { data } = useQuery(['allAdmin'], () => getAdmin(), { refetchOnWindowFocus: false });

  return {
    admins: data?.result,
    adminOptions: data?.result?.map((item: any) => ({
      ...item,
      value: item.id,
      label: item.name,
    })),
  };
};
