import { useQuery } from '@tanstack/react-query';
import { Button, Col, DatePicker, Divider, Form, Input, message, Modal, Row, Select, Space, Spin } from 'antd';
import { useWatch } from 'antd/lib/form/Form';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PROVINCES, PROPERTY, disableDateBeforeIncludeToday, forceDownload } from 'src/common/constant';
import { ADVICE_REQUEST_API } from 'src/helpers/api';
import axiosIns from 'src/helpers/request';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import { useGetAdmin } from 'src/hooks/useGetAdmins';
import { SideBarRoute } from 'src/layouts/constants';
import { formLayout } from 'src/pages/constants';
import { filterOption } from 'src/pages/quan-ly-tich-diem/quan-ly-rule-tich-diem/constants';
import { GENDER_OPTIONS } from 'src/pages/tu-van-khieu-nai/phan-loai-yeu-cau/danh-sach/constants';
import { Permission } from 'src/routes/constants';
import { STATUS_OPTIONS, TICK_OPTIONS } from '../../danh-sach/constants';
import styles from '../styles.module.scss';
import ChatScreen from './ChatScreen';

export const InformationAdvice: React.FC = () => {
  const param = useParams();
  const [form] = Form.useForm();
  const { adminOptions } = useGetAdmin();
  const { isSupperAdmin, currentUser } = useCurrentUser();
  const [loading, setLoading] = useState<boolean>(false);
  const requestTypeWatch = useWatch('requestType', form);
  const [title, setTitle] = useState<any>('');
  const [note, setNote] = useState<any>('');
  const [internalNoteComplaint, setInternalNoteComplaint] = useState<any>('');

  const getListClassifyRequest = async () => {
    const res = await axiosIns.get(ADVICE_REQUEST_API.getDetail(param?.id));
    return res?.data?.data;
  };

  const isHasPermissionEdit = currentUser?.permission?.moduleEnforces?.[SideBarRoute.CONSULTANCY_REQUEST]?.includes(
    Permission.editDetail
  );
  const isHasPermissionExport = currentUser?.permission?.moduleEnforces?.[SideBarRoute.CONSULTANCY_REQUEST]?.includes(
    Permission.export
  );

  const { data, isLoading, refetch } = useQuery(['advice-request-detail'], () => getListClassifyRequest(), {
    refetchOnWindowFocus: false,
  });

  const onExport = async () => {
    const res = await axiosIns.get(ADVICE_REQUEST_API.getDetail(param?.id), {
      params: { isExported: true },
      responseType: 'blob',
    });
    const blob = new Blob([res.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const blobUrl = window.URL.createObjectURL(blob);
    const date = `${moment(Date.now()).format('DD/MM/YYYY')}_Chi_tiet_yeu_cau_tu_van`;
    forceDownload(blobUrl, date);
  };

  useEffect(() => {
    form.setFieldsValue({
      requestType: data?.typeRequests,
      manager: data?.manager?.map((item) => item?.id),
      status: data?.status,
      processingTime: data?.processingTime ? moment(data?.processingTime) : '',
      industry: data?.company?.industryCDI?.titleCdi,
      priorityLevel: data?.priorityLevel,
      marking: data?.marking,
      acceptingTime: moment(data?.acceptingTime).format('DD/MM/YYYY'),
    });
    setNote(data?.note);
    setInternalNoteComplaint(data?.internalNoteComplaint);
    setTitle(data?.title);
  }, [data, form]);

  const onUpdate = async (value: any) => {
    try {
      setLoading(true);
      const res = await axiosIns.patch(ADVICE_REQUEST_API.updateAdviceRequest(param?.id), {
        requestType: value?.requestType,
        manager: value?.manager,
        status: value?.status,
        processingTime: moment(value?.processingTime).format('DD/MM/YYYY HH:mm'),
        industry: value?.industry,
        priorityLevel: value?.priorityLevel,
        marking: value?.marking,
        acceptingTime: moment(value?.acceptingTime).format('DD/MM/YYYY HH:mm'),
        note: note,
        internalNoteComplaint: internalNoteComplaint,
        title: title,
      });
      if (res?.data?.statusCode === 200) {
        message.success(res?.data?.message);
        refetch();
      } else {
        message.error(res?.data?.message);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const onChangeTypeRequest = (value: any) => {
    if (value === 2) {
      Modal.confirm({
        title:
          'Bạn có chắc chắn muốn chuyển loại yêu cầu thành Khiếu nại? Nếu chuyển, bạn không thể sửa lại yêu cầu thành Tư vấn',
        okText: 'Xác nhận',
        okType: 'danger',
        cancelText: 'Huỷ',
        centered: true,
        async onOk() {
          await axiosIns.patch(ADVICE_REQUEST_API.updateAdviceRequest(param?.id), {
            requestType: 2,
          });
        },
        onCancel() {
          form.setFieldsValue({
            requestType: 1,
          });
        },
      });
    }
  };

  const onChangeNote = (type: string) => (value: any) => {
    switch (type) {
      case 'title':
        setTitle(value.target.value);
        break;
      case 'note':
        setNote(value.target.value);
        break;
      case 'internalNoteComplaint':
        setInternalNoteComplaint(value.target.value);
        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.formWrapper}>
      <Spin spinning={isLoading}>
        <Form {...formLayout} form={form} labelAlign="left" layout="vertical" onFinish={onUpdate}>
          <div className={styles.wrapperButton}>
            <Space size="large" className={styles.button}>
              <Button type="primary" disabled={!isHasPermissionExport} onClick={onExport}>
                Xuất nội dung
              </Button>
              <Button type="primary" disabled={!isHasPermissionEdit} htmlType="submit" loading={loading}>
                Lưu
              </Button>
              <Button type="primary">Quay lại</Button>
            </Space>
          </div>

          <Divider />
          <Row gutter={{ xs: 24, sm: 24, md: 24, lg: 32 }}>
            <Col xs={{ span: 24 }} sm={{ span: 8 }}>
              <Form.Item label="ID">
                <Input disabled={true} value={data?.id} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 8 }}>
              <Form.Item label="Người gửi">
                <Input disabled={true} value={data?.userSend ? data?.userSend?.name : data?.userName} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 8 }}>
              <Form.Item label="Giới tính">
                <Select
                  options={GENDER_OPTIONS.filter((item, index) => index !== 0)}
                  placeholder="Chọn giới tính"
                  value={data?.userSend ? data?.userSend?.gender : data?.userGender}
                  disabled
                />
              </Form.Item>
            </Col>

            {isSupperAdmin && (
              <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                <Form.Item label="Số điện thoại">
                  <Input disabled={true} value={data?.userSend ? data?.userSend?.phone : data?.userPhone} />
                </Form.Item>
              </Col>
            )}

            {isSupperAdmin && (
              <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                <Form.Item label="Quê quán">
                  <Input disabled={true} value={data?.userProvince?.name} />
                </Form.Item>
              </Col>
            )}
            {isSupperAdmin && (
              <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                <Form.Item label="Công ty">
                  <Input disabled value={data?.company ? data?.company?.name : data?.companyName} />
                </Form.Item>
              </Col>
            )}

            <Col xs={{ span: 24 }} sm={{ span: 8 }}>
              <Form.Item label="Phân loại">
                <Input disabled value={data?.adviseTopicId?.adviseTypeName} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 8 }}>
              <Form.Item label="Chủ đề">
                <Input disabled value={data?.adviseTopicId?.topic} />
              </Form.Item>
            </Col>

            {isSupperAdmin && (
              <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                <Form.Item className={styles.province} label="Địa chỉ công ty">
                  <Input
                    disabled={true}
                    value={
                      data?.companyDistrict?.name
                        ? `${data?.companyDistrict?.name} ${PROVINCES?.find((item) => item.code === data?.companyProvinceCode)?.name
                        }`
                        : `${PROVINCES?.find((item) => item.code === data?.companyProvinceCode)?.name}`
                    }
                  />
                </Form.Item>
              </Col>
            )}
            <Col xs={{ span: 24 }} sm={{ span: 8 }}>
              <Form.Item name="requestType" label="Loại yêu cầu" initialValue={data?.typeRequests}>
                <Select
                  value={data?.typeRequests}
                  disabled={requestTypeWatch === 2 || !isHasPermissionEdit}
                  onChange={onChangeTypeRequest}
                >
                  <Select.Option value={1}>Tư vấn</Select.Option>
                  <Select.Option value={2}>Khiếu nại</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 8 }}>
              <Form.Item label="Ngày tạo">
                <Input disabled={true} value={moment(data?.createdAt).format('DD/MM/YYYY HH:mm')} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 8 }}>
              <Form.Item label="Ngày phân giao">
                <Input disabled={true} value={moment(data?.acceptingTime).format('DD/MM/YYYY HH:mm')} />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 8 }}>
              <Form.Item label="Nguồn tạo">
                <Input disabled={true} value={data?.sourceRequestName} />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 8 }}>
              <Form.Item name="status" label="Trạng thái">
                <Select
                  showSearch
                  options={STATUS_OPTIONS?.filter((item, index) => index !== 0)}
                  filterOption={filterOption}
                  placeholder="Chọn trạng thái"
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 8 }}>
              <Form.Item name="processingTime" label="Thời hạn xử lý">
                <DatePicker
                  showTime
                  disabledDate={disableDateBeforeIncludeToday}
                  format={'DD/MM/YYYY HH:mm'}
                  style={{ width: '90%' }}
                  disabled={!isSupperAdmin}
                  placeholder={'Chọn thời gian xử lý'}
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 8 }}>
              <Form.Item name="manager" label="Người phụ trách">
                <Select
                  options={adminOptions}
                  mode="multiple"
                  disabled={!isSupperAdmin}
                  placeholder="Chọn người phụ trách"
                  className={styles.placeholder}
                  filterOption={filterOption}
                  showSearch
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 8 }}>
              <Form.Item label="Đã từng trễ hạn ?">
                <Input disabled={true} value={data?.isLate == 0 ? 'Không' : 'Có'} />
              </Form.Item>
            </Col>
            {/* <Col xs={{ span: 24 }} sm={{ span: 8 }}>
              <Form.Item label="Số ngày trễ hạn">
                <Input
                  disabled={true}
                  value={
                    data?.isLate == 1
                      ? data?.updatedStatusAt
                        ? moment().diff(moment(data?.updatedStatusAt), 'days') + 1
                        : 0
                      : 0
                  }
                />
              </Form.Item>
            </Col> */}

            <Col xs={{ span: 24 }} sm={{ span: 8 }}>
              <Form.Item name="priorityLevel" label="Mức độ ưu tiên">
                <Select showSearch options={PROPERTY} filterOption={filterOption} placeholder="Chọn mức độ" />
              </Form.Item>
            </Col>
            {isSupperAdmin && (
              <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                <Form.Item name="marking" label="Đánh dấu">
                  <Select
                    // onChange={onChangeFilter}
                    options={TICK_OPTIONS}
                    showSearch
                    filterOption={filterOption}
                  />
                </Form.Item>
              </Col>
            )}
            {isSupperAdmin && (
              <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                <Form.Item name="industry" label="Ngành nghề">
                  <Select
                    // onChange={onChangeFilter}
                    disabled
                    defaultValue={data?.userSend?.industry?.titleCdi}
                    showSearch
                    filterOption={filterOption}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>

          <Row className="mb-3" align="middle">
            <Col span={3}>
              <b>Tiêu đề</b>
            </Col>
            <Col span={18}>
              <Input.TextArea
                className="note_input"
                style={{ width: '100%' }}
                onChange={onChangeNote('title')}
                value={title}
                placeholder="Nhập tiêu đề"
              />
            </Col>
          </Row>
          <Row className="mb-3" align="middle">
            <Col span={3}>
              <b>Ghi chú</b>
            </Col>
            <Col span={18}>
              <Input.TextArea
                className="note_input"
                style={{ width: '100%' }}
                onChange={onChangeNote('note')}
                value={note}
                placeholder="Nhập mô tả hoặc thêm ghi chú"
              />
            </Col>
          </Row>
          <Row className="mb-3" align="middle">
            <Col span={3}>
              <b> Ghi chú nội bộ</b>
            </Col>
            <Col span={18}>
              <Input.TextArea
                className="note_input"
                style={{ width: '100%' }}
                onChange={onChangeNote('internalNoteComplaint')}
                value={internalNoteComplaint}
                placeholder="Nhập mô tả hoặc thêm ghi chú"
              />
            </Col>
          </Row>
          <strong>Nội dung trao đổi</strong>
          <ChatScreen dsChiTietYCTV={data} currentUser={currentUser} />
        </Form>
      </Spin>
    </div>
  );
};
