import React from 'react';
import {
  AccumulatePointRoutes,
  AppRoutes,
  ClassifyListSKNNRoutes,
  CompanyRoutes,
  ConclusionListSKNNRoutes,
  ConsultationsRoutes,
  GuideRoutes,
  HeadingListSKNNRoutes,
  HealthQuizRoutes,
  HistoryRoutes,
  InformationSystemRoutes,
  NotificationRoutes,
  QuesListSKNNRoutes,
  QuizzRoutes,
  RoleRoutes,
  SKNNListSKNNRoutes,
  SystemRoutes,
  UserRoutes,
  WorkConditionRoutes,
} from 'src/helpers/app.routes';
import { SideBarRoute } from 'src/layouts/constants';
import DetailAdviceRequest from 'src/pages/tu-van-khieu-nai/yeu-cau-tu-van/chi-tiet';
const RolePage = React.lazy(() => import('src/pages/quan-ly-he-thong/phan-quyen/danh-sach'));
const RoleCreatePage = React.lazy(() => import('src/pages/quan-ly-he-thong/phan-quyen/tao-moi'));
const RoleDetailPage = React.lazy(() => import('src/pages/quan-ly-he-thong/phan-quyen/chi-tiet'));
const QuizzPage = React.lazy(() => import('src/pages/khao-sat-nhanh/danh-sach'));
const QuizzCreatePage = React.lazy(() => import('src/pages/khao-sat-nhanh/tao-moi'));
const QuizzDetailPage = React.lazy(() => import('src/pages/khao-sat-nhanh/chi-tiet'));
const QuizzHistoryPage = React.lazy(() => import('src/pages/khao-sat-nhanh/lich-su'));
const QuizzResultPage = React.lazy(() => import('src/pages/khao-sat-nhanh/ket-qua-khao-sat-nhanh/danh-sach'));
const QuizzResultCreatePage = React.lazy(() => import('src/pages/khao-sat-nhanh/ket-qua-khao-sat-nhanh/tao-moi'));
const QuizzResultDetailPage = React.lazy(() => import('src/pages/khao-sat-nhanh/ket-qua-khao-sat-nhanh/chi-tiet'));
const CompanyPage = React.lazy(() => import('src/pages/quan-li-danh-sach-cong-ty/danh-sach-cong-ty/danh-sach-cong-ty'));
const RequestCompanyPage = React.lazy(
  () => import('src/pages/quan-li-danh-sach-cong-ty/danh-sach-cong-ty/yeu-cau-tao-moi')
);
const CompanyDetailPage = React.lazy(
  () => import('src/pages/quan-li-danh-sach-cong-ty/danh-sach-cong-ty/chi-tiet-cong-ty')
);
const RequestCompanyDetailPage = React.lazy(
  () => import('src/pages/quan-li-danh-sach-cong-ty/danh-sach-cong-ty/chi-tiet-yeu-cau')
);
const AccountSystemPage = React.lazy(() => import('src/pages/quan-ly-he-thong/tai-khoan-he-thong'));
const DetailAccountSystemPage = React.lazy(() => import('src/pages/quan-ly-he-thong/tai-khoan-he-thong/chi-tiet'));
const CreateAccountSystemPage = React.lazy(() => import('src/pages/quan-ly-he-thong/tai-khoan-he-thong/tao-moi'));
const GuidePage = React.lazy(() => import('src/pages/huong-dan-tap-luyen/danh-sach'));
const GuideCreatePage = React.lazy(() => import('src/pages/huong-dan-tap-luyen/tao-moi'));
const GuideDetailPage = React.lazy(() => import('src/pages/huong-dan-tap-luyen/chi-tiet'));
const UserPage = React.lazy(() => import('src/pages/quan-ly-nguoi-dung/danh-sach'));
const DetailUserPage = React.lazy(() => import('src/pages/quan-ly-nguoi-dung/chi-tiet'));
const UsualQuestionPage = React.lazy(() => import('src/pages/quan-ly-thong-tin-he-thong/cau-hoi-thuong-gap/danh-sach'));
const UsualQuestionCreatePage = React.lazy(
  () => import('src/pages/quan-ly-thong-tin-he-thong/cau-hoi-thuong-gap/tao-moi')
);
const UsualQuestionDetailPage = React.lazy(
  () => import('src/pages/quan-ly-thong-tin-he-thong/cau-hoi-thuong-gap/chi-tiet-cau-hoi')
);
const TermServicePage = React.lazy(() => import('src/pages/quan-ly-thong-tin-he-thong/dieu-khoan-su-dung/cap-nhat'));
const PolicyPage = React.lazy(() => import('src/pages/quan-ly-thong-tin-he-thong/chinh-sach-quyen-rieng-tu/cap-nhat'));
const IntroAppPage = React.lazy(() => import('src/pages/quan-ly-thong-tin-he-thong/gioi-thieu-ung-dung/cap-nhat'));
const ItemManagerPage = React.lazy(() => import('src/pages/quan-ly-thong-tin-he-thong/quan-ly-hang-muc/danh-sach'));
const ItemManagerDetail = React.lazy(() => import('src/pages/quan-ly-thong-tin-he-thong/quan-ly-hang-muc/chi-tiet'));
const IntroVideoPage = React.lazy(() => import('src/pages/quan-ly-thong-tin-he-thong/video-huong-dan/danh-sach'));
const IntroVideoDetailPage = React.lazy(() => import('src/pages/quan-ly-thong-tin-he-thong/video-huong-dan/chi-tiet'));
const DashboardAdminPage = React.lazy(() => import('src/pages/quan-ly-thong-tin-he-thong/dashbroad-admin/cap-nhat'));
const BannerPage = React.lazy(() => import('src/pages/quan-ly-thong-tin-he-thong/quan-ly-banner/danh-sach'));
const BannerDetailPage = React.lazy(() => import('src/pages/quan-ly-thong-tin-he-thong/quan-ly-banner/chi-tiet'));

const RulePointsManagement = React.lazy(() => import('src/pages/quan-ly-tich-diem/quan-ly-rule-tich-diem/danh-sach'));
const HistoryAccumulatePoints = React.lazy(() => import('src/pages/quan-ly-tich-diem/lich-su-tich-diem'));
const HistoryExchange = React.lazy(() => import('src/pages/quan-ly-tich-diem/lich-su-doi-qua'));
const NotificationPage = React.lazy(() => import('src/pages/quan-ly-thong-bao/danh-sach'));
const NotificationCreatePage = React.lazy(() => import('src/pages/quan-ly-thong-bao/tao-moi'));
const NotificationDetailPage = React.lazy(() => import('src/pages/quan-ly-thong-bao/chi-tiet'));
const NotificationHistoryPage = React.lazy(() => import('src/pages/quan-ly-thong-bao/lich-su'));
//danh sách câu hỏi
const AnswerPage = React.lazy(() => import('src/pages/bo-cau-hoi-khao-sat-suc-khoe-nghe-nghiep/dap-an/danh-sach'));
const ReplyPage = React.lazy(() => import('src/pages/bo-cau-hoi-khao-sat-suc-khoe-nghe-nghiep/cau-tra-loi/danh-sach'));
const HeaderPage = React.lazy(() => import('src/pages/bo-cau-hoi-khao-sat-suc-khoe-nghe-nghiep/tieu-de/danh-sach'));

//danh sách câu hỏi SKNN
const QuesListSKNNPage = React.lazy(
  () => import('src/pages/bo-cau-hoi-khao-sat-suc-khoe-nghe-nghiep/danh-sach-cau-hoi/danh-sach')
);
const QuesListSKNNCreatePage = React.lazy(
  () => import('src/pages/bo-cau-hoi-khao-sat-suc-khoe-nghe-nghiep/danh-sach-cau-hoi/tao-moi')
);
const QuesListSKNNDetailPage = React.lazy(
  () => import('src/pages/bo-cau-hoi-khao-sat-suc-khoe-nghe-nghiep/danh-sach-cau-hoi/chi-tiet')
);

//quản lý heading SKNN
const HeadingListSKNNPage = React.lazy(
  () => import('src/pages/bo-cau-hoi-khao-sat-suc-khoe-nghe-nghiep/quan-ly-heading/danh-sach')
);
const HeadingListSKNNCreatePage = React.lazy(
  () => import('src/pages/bo-cau-hoi-khao-sat-suc-khoe-nghe-nghiep/quan-ly-heading/tao-moi')
);
const HeadingListSKNNDetailPage = React.lazy(
  () => import('src/pages/bo-cau-hoi-khao-sat-suc-khoe-nghe-nghiep/quan-ly-heading/chi-tiet')
);

//quản lý phiếu SKNN
const SKNNListSKNNPage = React.lazy(
  () => import('src/pages/bo-cau-hoi-khao-sat-suc-khoe-nghe-nghiep/quan-ly-SKNN/danh-sach')
);
const SKNNListSKNNCreatePage = React.lazy(
  () => import('src/pages/bo-cau-hoi-khao-sat-suc-khoe-nghe-nghiep/quan-ly-SKNN/tao-moi')
);
const SKNNListSKNNDetailPage = React.lazy(
  () => import('src/pages/bo-cau-hoi-khao-sat-suc-khoe-nghe-nghiep/quan-ly-SKNN/chi-tiet')
);

//quản lý danh sách kết luận SKNN
const ConclusionListSKNNPage = React.lazy(
  () => import('src/pages/bo-cau-hoi-khao-sat-suc-khoe-nghe-nghiep/danh-sach-ket-luan/danh-sach')
);
const ConclusionListCreatePage = React.lazy(
  () => import('src/pages/bo-cau-hoi-khao-sat-suc-khoe-nghe-nghiep/danh-sach-ket-luan/tao-moi')
);
const ConclusionListDetailPage = React.lazy(
  () => import('src/pages/bo-cau-hoi-khao-sat-suc-khoe-nghe-nghiep/danh-sach-ket-luan/chi-tiet')
);

//quản lý phân loại SKNN
const ClassifyListSKNNPage = React.lazy(
  () => import('src/pages/bo-cau-hoi-khao-sat-suc-khoe-nghe-nghiep/quan-ly-phan-loai/danh-sach')
);
const ClassifyListCreatePage = React.lazy(
  () => import('src/pages/bo-cau-hoi-khao-sat-suc-khoe-nghe-nghiep/quan-ly-phan-loai/tao-moi')
);
const ClassifyListDetailPage = React.lazy(
  () => import('src/pages/bo-cau-hoi-khao-sat-suc-khoe-nghe-nghiep/quan-ly-phan-loai/chi-tiet')
);

//tư vấn và khiếu nại
const RequestClassificationPage = React.lazy(() => import('src/pages/tu-van-khieu-nai/phan-loai-yeu-cau/danh-sach'));
const RequestClassificationDetailPage = React.lazy(
  () => import('src/pages/tu-van-khieu-nai/phan-loai-yeu-cau/chi-tiet')
);
const RequestCounsellingPage = React.lazy(() => import('src/pages/tu-van-khieu-nai/yeu-cau-tu-van/danh-sach'));
// const RequestCounsellingDetailPage = React.lazy(() => import('src/pages/tu-van-khieu-nai/yeu-cau-tu-van/chi-tiet'));
const SatisfactionRatingPage = React.lazy(
  () => import('src/pages/tu-van-khieu-nai/danh-gia-muc-do-hai-long/danh-sach')
);
const FormManagementListPage = React.lazy(() => import('src/pages/tu-van-khieu-nai/quan-ly-bieu-mau/danh-sach'));
//Điều kiện làm việc
const OriginalQuestionPage = React.lazy(() => import('src/pages/dieu-kien-lam-viec/bo-cau-hoi-goc/danh-sach'));
const OriginalQuestionDetailPage = React.lazy(() => import('src/pages/dieu-kien-lam-viec/bo-cau-hoi-goc/chi-tiet'));
const OriginalQuestionCreatePage = React.lazy(() => import('src/pages/dieu-kien-lam-viec/bo-cau-hoi-goc/tao-moi'));
const EvaluateSheetPage = React.lazy(() => import('src/pages/dieu-kien-lam-viec/phieu-danh-gia/danh-sach'));
const EvaluateSheetDetailPage = React.lazy(() => import('src/pages/dieu-kien-lam-viec/phieu-danh-gia/chi-tiet'));
const EvaluateSheetCreatePage = React.lazy(() => import('src/pages/dieu-kien-lam-viec/phieu-danh-gia/tao-moi'));

const AppFeedbackListPage = React.lazy(() => import('src/pages/tu-van-khieu-nai/phan-hoi-ung-dung/danh-sach'));
const AppFeedbackListDetailPage = React.lazy(() => import('src/pages/tu-van-khieu-nai/phan-hoi-ung-dung/chi-tiet'));

//Lịch sử khảo sát
const HistoryOccupationalHealthPage = React.lazy(
  () => import('src/pages/lich-su-khao-sat/suc-khoe-nghe-nghiep/danh-sach')
);
const HistoryOccupationalHealthDetailPage = React.lazy(
  () => import('src/pages/lich-su-khao-sat/suc-khoe-nghe-nghiep/chi-tiet')
);
const HistoryWorkingConditionPage = React.lazy(() => import('src/pages/lich-su-khao-sat/dieu-kien-lam-viec/danh-sach'));
const HistoryWorkingConditionDetailPage = React.lazy(
  () => import('src/pages/lich-su-khao-sat/dieu-kien-lam-viec/chi-tiet')
);

const CompanyRatingPage = React.lazy(
  () => import('src/pages/dieu-kien-lam-viec/xep-hang-cong-ty')
);

// Khiếu nại
const ComplaintListPage = React.lazy(() => import('src/pages/tu-van-khieu-nai/yeu-cau-khieu-nai/danh-sach'));
const ComplaintCreatePage = React.lazy(() => import('src/pages/tu-van-khieu-nai/yeu-cau-khieu-nai/tao-moi'));
const ComplaintDetailPage = React.lazy(() => import('src/pages/tu-van-khieu-nai/yeu-cau-khieu-nai/chi-tiet'));

const CreateAdviceRequest = React.lazy(() => import('src/pages/tu-van-khieu-nai/yeu-cau-tu-van/tao-moi'));

// Cài dặt thời gian chuyển trạng thái
const TimeTransfer = React.lazy(() => import('src/pages/quan-ly-thong-tin-he-thong/cai-dat-thoi-gian'));

export const Permission = {
  viewList: 'view_list',
  viewDetail: 'view_detail',
  editDetail: 'edit_detail',
  searchFilter: 'search/filter',
  export: 'export',
  create: 'create',
};

export const routes = [
  // {
  //   sidebar: SideBarRoute.DASHBOARD,
  //   path: AppRoutes.home,
  //   element: <HomePage />
  // },
  {
    sidebar: SideBarRoute.ROLE,
    path: RoleRoutes.roleList,
    permission: Permission.viewList,
    element: <RolePage />,
  },
  {
    sidebar: SideBarRoute.ROLE,
    path: RoleRoutes.roleCreate,
    permission: Permission.create,
    element: <RoleCreatePage />,
  },
  {
    sidebar: SideBarRoute.ROLE,
    path: RoleRoutes.roleDetail,
    permission: Permission.viewDetail,
    element: <RoleDetailPage />,
  },
  {
    sidebar: SideBarRoute.COMPANY,
    path: CompanyRoutes.companyManagement,
    permission: Permission.viewList,
    element: <CompanyPage />,
  },
  {
    sidebar: SideBarRoute.COMPANY,
    path: CompanyRoutes.companyDetail,
    permission: Permission.viewDetail,
    element: <CompanyDetailPage />,
  },
  {
    sidebar: SideBarRoute.REQUEST_COMPANY,
    path: CompanyRoutes.companyRequestManagement,
    permission: Permission.viewList,
    element: <RequestCompanyPage />,
  },
  {
    sidebar: SideBarRoute.REQUEST_COMPANY,
    path: CompanyRoutes.companyRequestDetail,
    permission: Permission.viewDetail,
    element: <RequestCompanyDetailPage />,
  },
  // khao sat nhanh
  {
    sidebar: SideBarRoute.FAST_SURVEY,
    path: QuizzRoutes.quizzList,
    permission: Permission.viewList,
    element: <QuizzPage />,
  },
  {
    sidebar: SideBarRoute.FAST_SURVEY,
    path: QuizzRoutes.quizzCreate,
    permission: Permission.create,
    element: <QuizzCreatePage />,
  },
  {
    sidebar: SideBarRoute.FAST_SURVEY,
    path: QuizzRoutes.quizzDetail,
    permission: Permission.viewDetail,
    element: <QuizzDetailPage />,
  },
  // lich su ksn
  {
    sidebar: SideBarRoute.FAST_SURVEY_HISTORY,
    path: QuizzRoutes.quizzHistory,
    permission: Permission.viewList,
    element: <QuizzHistoryPage />,
  },
  //ket-qua-khao-sat-nhanh
  {
    sidebar: SideBarRoute.FAST_SURVEY_RESULT,
    path: QuizzRoutes.quizzResultList,
    permission: Permission.viewList,
    element: <QuizzResultPage />,
  },
  {
    sidebar: SideBarRoute.FAST_SURVEY_RESULT,
    path: QuizzRoutes.quizzResultCreate,
    permission: Permission.create,
    element: <QuizzResultCreatePage />,
  },
  {
    sidebar: SideBarRoute.FAST_SURVEY_RESULT,
    path: QuizzRoutes.quizzResultDetail,
    permission: Permission.viewDetail,
    element: <QuizzResultDetailPage />,
  },
  // tai khoan he thong
  {
    sidebar: SideBarRoute.ACCOUNT_SYSTEM,
    path: SystemRoutes.accountSystem,
    permission: Permission.viewList,
    element: <AccountSystemPage />,
  },
  {
    sidebar: SideBarRoute.ACCOUNT_SYSTEM,
    path: SystemRoutes.accountSystemDetail,
    permission: Permission.viewDetail,
    element: <DetailAccountSystemPage />,
  },
  {
    sidebar: SideBarRoute.ACCOUNT_SYSTEM,
    path: SystemRoutes.createAccountSystem,
    permission: Permission.create,
    element: <CreateAccountSystemPage />,
  },
  // quan ly nguoi dung app
  {
    sidebar: SideBarRoute.APP_USERS,
    path: UserRoutes.userList,
    permission: Permission.viewList,
    element: <UserPage />,
  },
  {
    sidebar: SideBarRoute.APP_USERS,
    path: UserRoutes.userDetail,
    permission: Permission.viewDetail,
    element: <DetailUserPage />,
  },
  // huong dan tap luyen
  {
    sidebar: SideBarRoute.TRAINNING_GUIDE,
    path: GuideRoutes.guideList,
    permission: Permission.viewList,
    element: <GuidePage />,
  },
  {
    sidebar: SideBarRoute.TRAINNING_GUIDE,
    path: GuideRoutes.guideCreate,
    permission: Permission.create,
    element: <GuideCreatePage />,
  },
  {
    sidebar: SideBarRoute.TRAINNING_GUIDE,
    path: GuideRoutes.guideDetail,
    permission: Permission.viewDetail,
    element: <GuideDetailPage />,
  },
  // cau hoi thuong gap
  {
    sidebar: SideBarRoute.COMMON_QUESTION,
    path: InformationSystemRoutes.usualQuestionList,
    permission: Permission.viewList,
    element: <UsualQuestionPage />,
  },
  {
    sidebar: SideBarRoute.COMMON_QUESTION,
    path: InformationSystemRoutes.usualQuestionCreate,
    permission: Permission.create,
    element: <UsualQuestionCreatePage />,
  },
  {
    sidebar: SideBarRoute.COMMON_QUESTION,
    path: InformationSystemRoutes.usualQuestionDetail,
    permission: Permission.viewDetail,
    element: <UsualQuestionDetailPage />,
  },
  // dieu khoan su dung
  {
    sidebar: SideBarRoute.TERM,
    path: InformationSystemRoutes.termService,
    permission: Permission.viewList,
    element: <TermServicePage />,
  },
  // chinh sach quyen rieng tu
  {
    sidebar: SideBarRoute.PRIVACY_POLICY,
    path: InformationSystemRoutes.PrivacyPolicy,
    permission: Permission.viewList,
    element: <PolicyPage />,
  },
  // gioi thieu ung dung
  {
    sidebar: SideBarRoute.APP_INTRODUCTION,
    path: InformationSystemRoutes.IntroApp,
    permission: Permission.viewList,
    element: <IntroAppPage />,
  },
  {
    sidebar: SideBarRoute.DASHBOARD_ADMIN,
    path: InformationSystemRoutes.DashBroadAdmin,
    permission: Permission.viewList,
    element: <DashboardAdminPage />,
  },
  // quan ly hang muc
  {
    sidebar: SideBarRoute.CATEGORY,
    path: InformationSystemRoutes.ItemManager,
    permission: Permission.viewList,
    element: <ItemManagerPage />,
  },
  {
    sidebar: SideBarRoute.CATEGORY,
    path: InformationSystemRoutes.ItemManagerDetail,
    permission: Permission.viewDetail,
    element: <ItemManagerDetail />,
  },
  // video huong dan
  {
    sidebar: SideBarRoute.INTRODUCTION_VIDEO,
    path: InformationSystemRoutes.IntroVideo,
    permission: Permission.viewList,
    element: <IntroVideoPage />,
  },
  {
    sidebar: SideBarRoute.INTRODUCTION_VIDEO,
    path: InformationSystemRoutes.IntroVideoDetail,
    permission: Permission.viewDetail,
    element: <IntroVideoDetailPage />,
  },
  // quan ly banner
  {
    sidebar: SideBarRoute.BANNER_MANAGEMENT,
    path: InformationSystemRoutes.bannerManagement,
    permission: Permission.viewList,
    element: <BannerPage />,
  },
  {
    sidebar: SideBarRoute.BANNER_MANAGEMENT,
    path: InformationSystemRoutes.bannerDetail,
    permission: Permission.viewDetail,
    element: <BannerDetailPage />,
  },
  // cài đặt thời gian chuyển trạng thái 
  {
    sidebar: SideBarRoute.TIME_TRANSFER,
    path: InformationSystemRoutes.TimeTransfer,
    permission: Permission.viewList,
    element: <TimeTransfer />,
  },

  //  quan ly tich diem
  {
    sidebar: SideBarRoute.ACCUMULATE_POINT_HISTORY,
    path: AccumulatePointRoutes.historyAccumulatePoint,
    permission: Permission.viewList,
    element: <HistoryAccumulatePoints />,
  },
  {
    sidebar: SideBarRoute.ACCUMULATE_POINT,
    path: AccumulatePointRoutes.ruleAccumulatePointList,
    permission: Permission.viewList,
    element: <RulePointsManagement />,
  },
  {
    sidebar: SideBarRoute.GIFT_VOUCHER_HISTORY,
    path: AccumulatePointRoutes.historyExchange,
    permission: Permission.viewList,
    element: <HistoryExchange />,
  },
  // quan ly thong bao
  {
    sidebar: SideBarRoute.NOTIFICATION,
    path: NotificationRoutes.notificationList,
    permission: Permission.viewList,
    element: <NotificationPage />,
  },
  {
    sidebar: SideBarRoute.NOTIFICATION,
    path: NotificationRoutes.notificationCreate,
    permission: Permission.create,
    element: <NotificationCreatePage />,
  },
  {
    sidebar: SideBarRoute.NOTIFICATION,
    path: NotificationRoutes.notificationDetail,
    permission: Permission.viewDetail,
    element: <NotificationDetailPage />,
  },
  {
    sidebar: SideBarRoute.NOTIFICATION_HISTORY,
    path: NotificationRoutes.notificationHistory,
    permission: Permission.viewList,
    element: <NotificationHistoryPage />,
  },
  // SKNN
  {
    sidebar: SideBarRoute.TITLE_MANAGEMENT,
    path: HealthQuizRoutes.headerList,
    permission: Permission.viewList,
    element: <HeaderPage />,
  },
  {
    sidebar: SideBarRoute.CHOICE_MANAGEMENT,
    path: HealthQuizRoutes.answerList,
    permission: Permission.viewList,
    element: <AnswerPage />,
  },
  {
    sidebar: SideBarRoute.ANSWER_MANAGEMENT,
    path: HealthQuizRoutes.replyList,
    permission: Permission.viewList,
    element: <ReplyPage />,
  },
  // danh sách câu hỏi SKNN
  {
    sidebar: SideBarRoute.QUESTION_MANAGEMENT,
    path: QuesListSKNNRoutes.questionList,
    permission: Permission.viewList,
    element: <QuesListSKNNPage />,
  },
  {
    sidebar: SideBarRoute.QUESTION_MANAGEMENT,
    path: QuesListSKNNRoutes.questionListDetail,
    permission: Permission.viewDetail,
    element: <QuesListSKNNDetailPage />,
  },
  {
    sidebar: SideBarRoute.QUESTION_MANAGEMENT,
    path: QuesListSKNNRoutes.questionListCreate,
    permission: Permission.create,
    element: <QuesListSKNNCreatePage />,
  },
  // quản lý heading SKNN
  {
    sidebar: SideBarRoute.HEADING_MANAGEMENT,
    path: HeadingListSKNNRoutes.headingList,
    permission: Permission.viewList,
    element: <HeadingListSKNNPage />,
  },
  {
    sidebar: SideBarRoute.HEADING_MANAGEMENT,
    path: HeadingListSKNNRoutes.headingListDetail,
    permission: Permission.viewDetail,
    element: <HeadingListSKNNDetailPage />,
  },
  {
    sidebar: SideBarRoute.HEADING_MANAGEMENT,
    path: HeadingListSKNNRoutes.headingListCreate,
    permission: Permission.create,
    element: <HeadingListSKNNCreatePage />,
  },
  // quản lý phiếu SKNN
  {
    sidebar: SideBarRoute.SKNN_SHEET_MANAGEMRNT,
    path: SKNNListSKNNRoutes.SKNNList,
    permission: Permission.viewList,
    element: <SKNNListSKNNPage />,
  },
  {
    sidebar: SideBarRoute.SKNN_SHEET_MANAGEMRNT,
    path: SKNNListSKNNRoutes.SKNNListDetail,
    permission: Permission.viewDetail,
    element: <SKNNListSKNNDetailPage />,
  },
  {
    sidebar: SideBarRoute.SKNN_SHEET_MANAGEMRNT,
    path: SKNNListSKNNRoutes.SKNNListCreate,
    permission: Permission.create,
    element: <SKNNListSKNNCreatePage />,
  },
  // quản lý danh sách kết luận SKNN
  {
    sidebar: SideBarRoute.CONCLUSION_MANAGEMENT,
    path: ConclusionListSKNNRoutes.conclusionList,
    permission: Permission.viewList,
    element: <ConclusionListSKNNPage />,
  },
  {
    sidebar: SideBarRoute.CONCLUSION_MANAGEMENT,
    path: ConclusionListSKNNRoutes.conclusionListDetail,
    permission: Permission.viewDetail,
    element: <ConclusionListDetailPage />,
  },
  {
    sidebar: SideBarRoute.CONCLUSION_MANAGEMENT,
    path: ConclusionListSKNNRoutes.conclusionListCreate,
    permission: Permission.create,
    element: <ConclusionListCreatePage />,
  },
  // quản lý danh sách phân loại SKNN
  {
    sidebar: SideBarRoute.CLASSIFY_MANAGEMENT,
    path: ClassifyListSKNNRoutes.classifyList,
    permission: Permission.viewList,
    element: <ClassifyListSKNNPage />,
  },
  {
    sidebar: SideBarRoute.CLASSIFY_MANAGEMENT,
    path: ClassifyListSKNNRoutes.classifyListDetail,
    permission: Permission.viewDetail,
    element: <ClassifyListDetailPage />,
  },
  {
    sidebar: SideBarRoute.CLASSIFY_MANAGEMENT,
    path: ClassifyListSKNNRoutes.classifyListCreate,
    permission: Permission.create,
    element: <ClassifyListCreatePage />,
  },
  //Điều kiện làm việc
  {
    sidebar: SideBarRoute.ORIGINAL_QUESTION,
    path: WorkConditionRoutes.originalQuestionList,
    permission: Permission.viewList,
    element: <OriginalQuestionPage />,
  },
  {
    sidebar: SideBarRoute.ORIGINAL_QUESTION,
    path: WorkConditionRoutes.originalQuestionDetail,
    permission: Permission.viewDetail,
    element: <OriginalQuestionDetailPage />,
  },
  {
    sidebar: SideBarRoute.ORIGINAL_QUESTION,
    path: WorkConditionRoutes.originalQuestionCreate,
    permission: Permission.create,
    element: <OriginalQuestionCreatePage />,
  },
  {
    sidebar: SideBarRoute.EVALUATE_FORM,
    path: WorkConditionRoutes.evaluateSheetList,
    permission: Permission.viewList,
    element: <EvaluateSheetPage />,
  },
  {
    sidebar: SideBarRoute.EVALUATE_FORM,
    path: WorkConditionRoutes.evaluateSheetDetail,
    permission: Permission.viewDetail,
    element: <EvaluateSheetDetailPage />,
  },
  {
    sidebar: SideBarRoute.EVALUATE_FORM,
    path: WorkConditionRoutes.evaluateSheetCreate,
    permission: Permission.create,
    element: <EvaluateSheetCreatePage />,
  },
  {
    sidebar: SideBarRoute.COMPANY_RANKING,
    path: WorkConditionRoutes.ratingCompany,
    permission: Permission.viewList,
    element: <CompanyRatingPage />,
  },
  //Tư vấn và khiếu nại
  {
    sidebar: SideBarRoute.COMPLAIN_REQUEST,
    path: ConsultationsRoutes.complaintList,
    permission: Permission.viewList,
    element: <ComplaintListPage />,
  },
  {
    sidebar: SideBarRoute.COMPLAIN_REQUEST,
    path: ConsultationsRoutes.complaintDetail,
    permission: Permission.viewDetail,
    element: <ComplaintDetailPage />,
  },
  {
    sidebar: SideBarRoute.COMPLAIN_REQUEST,
    path: ConsultationsRoutes.complaintCreate,
    permission: Permission.create,
    element: <ComplaintCreatePage />,
  },
  {
    sidebar: SideBarRoute.CONSULTANCY_REQUEST,
    path: ConsultationsRoutes.requestCounsellingList,
    permission: Permission.viewList,
    element: <RequestCounsellingPage />,
  },

  {
    sidebar: SideBarRoute.CONSULTANCY_REQUEST,
    path: ConsultationsRoutes.createAdviceRequest,
    permission: Permission.viewList,
    element: <CreateAdviceRequest />,
  },
  {
    sidebar: SideBarRoute.CONSULTANCY_REQUEST,
    path: ConsultationsRoutes.detailAdviceRequest,
    permission: Permission.viewList,
    element: <DetailAdviceRequest />,
  },
  {
    sidebar: SideBarRoute.REQUEST_CLASSIFICATION,
    path: ConsultationsRoutes.requestClassificationList,
    permission: Permission.viewList,
    element: <RequestClassificationPage />,
  },
  {
    sidebar: SideBarRoute.REQUEST_CLASSIFICATION,
    path: ConsultationsRoutes.requestClassificationDetail,
    permission: Permission.viewDetail,
    element: <RequestClassificationDetailPage />,
  },
  {
    sidebar: SideBarRoute.SATISFACTION_REVIEW,
    path: ConsultationsRoutes.satisfactionRatingList,
    permission: Permission.viewList,
    element: <SatisfactionRatingPage />,
  },
  {
    sidebar: SideBarRoute.FORM_MANAGEMENT,
    path: ConsultationsRoutes.formManagementList,
    permission: Permission.viewList,
    element: <FormManagementListPage />,
  },
  {
    sidebar: SideBarRoute.APP_FEEDBACK,
    path: ConsultationsRoutes.appFeedbackList,
    permission: Permission.viewList,
    element: <AppFeedbackListPage />,
  },
  {
    sidebar: SideBarRoute.APP_FEEDBACK,
    path: ConsultationsRoutes.appFeedbackDetail,
    permission: Permission.viewList,
    element: <AppFeedbackListDetailPage />,
  },
  // Lịch sử khảo sát
  {
    sidebar: SideBarRoute.HEALTH_SURVEY_HISTORY,
    path: HistoryRoutes.occupationalHealthList,
    permission: Permission.viewList,
    element: <HistoryOccupationalHealthPage />,
  },
  {
    sidebar: SideBarRoute.HEALTH_SURVEY_HISTORY,
    path: HistoryRoutes.occupationalHealthDetail,
    permission: Permission.viewDetail,
    element: <HistoryOccupationalHealthDetailPage />,
  },
  {
    sidebar: SideBarRoute.WORKING_CONDITION_HISTORY,
    path: HistoryRoutes.workingConditionList,
    permission: Permission.viewList,
    element: <HistoryWorkingConditionPage />,
  },
  {
    sidebar: SideBarRoute.WORKING_CONDITION_HISTORY,
    path: HistoryRoutes.workingConditionDetail,
    permission: Permission.viewDetail,
    element: <HistoryWorkingConditionDetailPage />,
  },
];
